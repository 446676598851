// breakpoints
$SXXX:    0px;
$SXX:    300px;
$SX:    400px;
$S:     600px;
$M:     830px;
$L:     882px;
$ML:    1360px;
$XL:    1440px;

// media queries
@mixin MQ($canvas) {
  @if $canvas == SXX {
    @media only screen and (min-device-width: $SXX) and (min-width: $SXX) { @content; }
  }
  @if $canvas == SX {
    @media only screen and (min-device-width: $SX) and (min-width: $SX) { @content; }
  }
  @if $canvas == S {
    @media only screen and (min-device-width: $S) and (min-width: $S) { @content; }
  }
  @if $canvas == M {
    @media only screen and (min-device-width: $M) and (min-width: $M) { @content; }
  }
  @if $canvas == L {
    @media only screen and (min-device-width: $L) and (min-width: $L) { @content; }
  }
  @if $canvas == ML {
    @media only screen and (min-device-width: $ML) and (min-width: $ML) { @content; }
  }
  @if $canvas == XL {
    @media only screen and (min-device-width: $XL) and (min-width: $XL) { @content; }
  }

  //  for max height limit

  @if $canvas == mx-SXX {
    @media only screen and (min-device-width: $SXXX + 1) and (max-device-width: $SX - 1)
    , (min-width: $SXXX + 1) and (max-width: $SX - 1) { @content; }

  }
  @if $canvas == mx-SX {
    @media only screen and (min-device-width: $SXXX + 1) and (max-device-width: $S - 1)
    , (min-width: $SXXX + 1) and (max-width: $S - 1){ @content; }
  }
  @if $canvas == mx-S {
    @media only screen and (min-device-width: $S + 1) and (max-device-width: $M - 1)
    , (min-width: $S + 1) and (max-width: $M - 1) { @content; }
  }
  @if $canvas == mx-M {
    @media only screen and (min-device-width: $M + 1) and (max-device-width: $L - 1)
    , (min-width: $M + 1) and (max-width: $L - 1) { @content; }
  }
  @if $canvas == mx-SXX-C {
    @media only screen and (min-device-width: $SXXX + 1) and (max-device-width: $SX - 1)
    , (min-width: $SXXX + 1) and (max-width: $SX - 1){ @content; }
  }
  @if $canvas == mx-SX-C {
    @media only screen and (min-device-width: $SXXX + 1) and (max-device-width: $S - 1)
    , (min-width: $SXXX + 1) and (max-width: $S - 1){ @content; }
  }
  @if $canvas == mx-S-C {
    @media only screen and (min-device-width: $SXXX + 1) and (max-device-width: $M - 1)
    , (min-width: $SXXX + 1) and (max-width: $M - 1) { @content; }
  }
  @if $canvas == mx-M-C {
    @media only screen and (min-device-width: $SXXX + 1) and (max-device-width: $L - 1)
    , (min-width: $SXXX + 1) and (max-width: $L - 1) { @content; }
  }
  @if $canvas == mx-L {
    @media only screen and (min-device-width: $L + 1) and (max-device-width: $ML - 1)
    , (min-width: $L + 1) and (max-width: $ML - 1) { @content; }
  }
  @if $canvas == mx-ML {
    @media only screen and (min-device-width: $ML + 1) and (max-device-width: $XL - 1)
    , (min-width: $ML + 1) and (max-width: $XL - 1) { @content; }
  }
  @if $canvas == mx-XL {
    @media only screen and (min-device-width: $XL) and (min-device-width: $XL){ @content; }
  }
}

.hide {
  display: none !important;
  visibility: hidden; }

.invisible {
  visibility: hidden; }

.show-l{
  display: none;
  @media only screen and (min-device-width: $L) and (min-width: $L){
    display: block;
  }

  .show-m{
    display: none;
    @media only screen and (min-device-width: $M) and (min-width: $M){
      display: block;
    }
  }
}


.hide-xs{
  @include MQ(mx-SXX-C){
    display: none;
  }
}
.hide-sm{
  @include MQ(mx-SX-C){
    display: none;
  }
}
.hide-md{
  @include MQ(mx-S-C){
    display: none !important;
  }
}
.hide-lg{
  @include MQ(mx-M-C){
    display: none !important;
  }
}
.show-xs{
  display: none;
  @include MQ(mx-SXX-C){
    display: block;
  }
}
.show-sm{
  display: none;
  @include MQ(mx-SX-C){
    display: block;
  }
}
.show-md{
  display: none;
  @include MQ(mx-S-C){
    display: block !important;
  }
}
.show-mdf{
  display: none;
  @include MQ(mx-S-C){
    display: flex !important;
  }
}
.show-lg{
  display: none;
  @include MQ(mx-M-C){
    display: block !important;
  }
}

.xs-width-100{

  @include MQ(mx-SXX-C){
    width: 100% !important;
  }
}
.sm-width-100{

  @include MQ(mx-SX-C){
    width: 100% !important;
  }
}
.md-width-100{

  @include MQ(mx-S-C){
    width: 100% !important;
  }
}
.sm-hgt-auto{

  @include MQ(mx-SX-C){
    padding: 15px 0;
    height: auto !important;
  }
}

.pg10a-md{
  @include MQ(mx-S-C){
    padding: 10px !important;
  }
}

.pg20a-md{
  @include MQ(mx-S-C){
    padding: 20px !important;
  }
}

$direction:column;
$direction-2:column-reverse;
$direction-row:row;
$direction-row-2:row-reverse;

// flex responsive layouts
.flex-sm-column{

  @include MQ(mx-SX-C){
    -webkit-flex-direction: $direction !important;
    -ms-flex-direction: $direction !important;
    flex-direction: $direction !important;
    box-sizing: border-box;
  }
}

.hgta-md{

  @include MQ(mx-S-C){
    height: auto !important;
  }
}
.width-auto-md{

  @include MQ(mx-S-C){
    width: auto !important;
  }

}
.width-auto-lg{

  @include MQ(mx-M-C){
    width: auto !important;
  }

}
.home-cover-img{

  @include MQ(mx-S-C){
    margin-top: 0 !important;
    width:auto !important;
    height: 100%;
  }

}
.flex-md{
    @include MQ(mx-S-C){
        display: flex !important;
    }
}
.flex-auto-md{

  @include MQ(mx-S-C){
    -webkit-flex: 0 1 auto !important;
    -ms-flex: 0 1 auto !important;
    flex: 0 1 auto !important;
    box-sizing: border-box;
  }

}
.flex-column-md{

  @include MQ(mx-S-C){
    -webkit-flex-direction: $direction !important;
    -ms-flex-direction: $direction !important;
    flex-direction: $direction !important;
    box-sizing: border-box;
  }
}
.flex-column-r-md{

  @include MQ(mx-S-C){
    -webkit-flex-direction: $direction-2 !important;
    -ms-flex-direction: $direction-2 !important;
    flex-direction: $direction-2 !important;
    box-sizing: border-box;
  }
}
.flex-row-l{
  @include MQ(mx-L){
    -webkit-flex-direction: $direction-row !important;
    -ms-flex-direction: $direction-row !important;
    flex-direction: $direction-row !important;
    box-sizing: border-box;
  }
}
.flex-row-md{

  @include MQ(mx-S-C){
    -webkit-flex-direction: $direction-row !important;
    -ms-flex-direction: $direction-row !important;
    flex-direction: $direction-row !important;
    box-sizing: border-box;
  }
}
.flex-row-r-md{

  @include MQ(mx-S-C){
    -webkit-flex-direction: $direction-row-2 !important;
    -ms-flex-direction: $direction-row-2 !important;
    flex-direction: $direction-row-2 !important;
    box-sizing: border-box;
  }
}

.flex-vertical-start-md{

  @include MQ(mx-S-C) {
    -webkit-align-items: flex-start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -webkit-align-content: flex-start !important;
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

}

.flex-align-center-md{

  @include MQ(mx-S-C) {
    -webkit-justify-content: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

}

.flex-nogrow-md{
    @include MQ(mx-S-C){
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        box-sizing: border-box;
    }
}

//textc md
.text-c-md{
  @include MQ(mx-S-C) {
    text-align: center !important;
  }
}

