//flex
.md\:flex-column{
    @include MQ(mx-S-C){
        -webkit-flex-direction: $direction !important;
        -ms-flex-direction: $direction !important;
        flex-direction: $direction !important;
        box-sizing: border-box;
    }
}
.md\:flex-column-reverse{
    @include MQ(mx-S-C){
        -webkit-flex-direction: $direction-2 !important;
        -ms-flex-direction: $direction-2 !important;
        flex-direction: $direction-2 !important;
        box-sizing: border-box;
    }
}

//width
.md\:width-f{
    @include MQ(mx-S-C) {
      width: 100% !important;
    }
  }
  
  // small padding 0-9
  $columns1: 9;
  $columns2: 20;
  $columns10:10;
  @for $i from 0 through $columns1 {
  
    //margin
    .md\:mg#{$i}lr {
      @include MQ(mx-S-C){
        margin-right: ($i) * 1px !important;
        margin-left: ($i) * 1px !important;
      }
    }
  
    //padding
    .md\:pg#{$i}lr {
      @include MQ(mx-S-C){
        padding-right: ($i) * 1px !important;
        padding-left: ($i) * 1px !important;
      }
    }
  }
  @for $i from 2 through $columns2 {
    .md\:mg#{(($i) * 5)}lr {
      @include MQ(mx-S-C){
        margin-right: (($i) * 5) * 1px !important;
        margin-left: (($i) * 5) * 1px !important;
      }
    }
  
  
    .md\:pg#{(($i) * 5)}lr {
      @include MQ(mx-S-C){
        padding-right: (($i) * 5) * 1px !important;
        padding-left: (($i) * 5) * 1px !important;
      }
    }
  }
  