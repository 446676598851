@font-face {
    font-family: 'icomoon';
    src:  url('/fonts/icomoon.eot?ukic1x');
    src:  url('/fonts/icomoon.eot?ukic1x#iefix') format('embedded-opentype'),
    url('/fonts/icomoon.ttf?ukic1x') format('truetype'),
    url('/fonts/icomoon.woff?ukic1x') format('woff'),
    url('/fonts/icomoon.svg?ukic1x#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-book:before {
    content: "\e904";
}
.icon-search:before {
    content: "\e901";
}
.icon-shopping-cart:before {
    content: "\e902";
}
.icon-user:before {
    content: "\e900";
}
.icon-Menu:before {
    content: "\e903";
}
.icon-angle-left:before {
    content: "\ea50";
}
.icon-angle-right:before {
    content: "\ea51";
}
.icon-buoy:before {
    content: "\e905";
}
.icon-home:before {
    content: "\e906";
}
.icon-Icon-Publication:before {
    content: "\e907";
}
.icon-magazine:before {
    content: "\e908";
    color: #5a6268;
}
.icon-manage:before {
    content: "\e909";
}
.icon-newspaper:before {
    content: "\e90a";
    color: #5a6268;
}
.icon-Outline:before {
    content: "\e90b";
    color: #5a6268;
}
.icon-permissions:before {
    content: "\e90c";
}

.fa-25x{
    font-size: 2.5em;
}
