$primaryDarkColor: #0D3349;
$primary-color: #1070b7;
$section-background-color : #0e7ebe;
$section-background-color-transparent : #0e7ebef2;
$section-background-color-light: #6da8d2;
$header-height-laptop: 75px;
$header-height-mobile: 50px;
$border-color: #e5e5e5;
$primary-light-background: #fff;
$fa-primary-color: #141414;

$breakpoint-laptop : 1024px;
$breakpoint-tablet : 768px;

.justify-center{
    justify-content: center;
}

.justify-around{
    justify-content: space-around;
}

.align-center{
    align-items: center;
}

.bg-primary{
    background-color: $section-background-color !important;
}

.bg-primary-gradient{
    background: linear-gradient(90deg, $section-background-color, $section-background-color-light);
}

.bg-primary-light{
    background-color: #d6e7f1;
}

.fa-color{
    color: $fa-primary-color;
}

.bd1{
    border: solid 1px $border-color;
}
.bd1t{
    border-top: solid 1px $border-color;
}

.bd2{
    border: solid 2px $border-color;
}
.bd2t{
    border-top: solid 2px $border-color;
}

.bd1b{
    border-bottom: solid 1px $border-color;
}

.bd1a-primary{
    border: solid 1px $primary-color !important;
}

//Custom Padding/Margin with EM/REM
.pgemtb{
    padding-top: 1rem !important;
    padding-bottom: 1em !important;
}

.pg2emtb{
    padding-top: 1rem !important;
    padding-bottom: 1em !important;
}
.pgemlr{
    padding-left: 1rem !important;
    padding-right: 1em !important;
}

.pg2emlr{
    padding-left: 1rem !important;
    padding-right: 1em !important;
}

.self-center{
    align-self: center;
}

.text-primary{
    color: #0e7ebe;
}

.text-h{
    color: #343A40;
}

.bg-fa{
    background-color: #FAFCFC;
}

.bgdf{
    background-color: #DFDFDF;
}

.bgb0{
    background-color: #B0B6BA;
}

.p-banner{
    background-image: linear-gradient(90deg, #343A40 80%, transparent);
}

.ob-con{
    object-fit: contain;
}

.ob-cov{
    object-fit: cover;
}

.font1r{
    font-size: 1rem;
}

.font18r{
    font-size: 1.125rem;
}

.cg1{
    column-gap: 1rem;
}

.wbk-3{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.wbk-4{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.csr-p{
    cursor: pointer;
}

.card-title-shadow{
    padding-top: 1rem;
    box-shadow: 0 0 4px 2px #f0f0f0;
}

.fw300{
    font-weight: 300;
}

.gap-1{
    gap: 1em;
}

.card-title{
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 2rem;
    height: 2rem;
    line-height: 1rem;
    margin-bottom: 1rem;
    color: $primary-color;
}

.card-subtitle{
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 1rem;
    line-height: 1rem;
    font-size: 14px;
}

//Onboarding Section
$subheader-text-color: #007AFF;

.ob-head-background{
    background: linear-gradient(90deg,$section-background-color, $section-background-color-light);
}

.ob-form-header{
    @include form-header($section-background-color, $section-background-color-light,white);
}

.ob-form-subheader{
    @include form-header(#F7F7F7, #F7F7F7, $subheader-text-color)
}

.ob-form-content-block{
    @include form-content-block($breakpoint-tablet)
}

.ob-d-form-c{
    @include row-column-sm
}

.ob-d-form-c{
    label{
        @extend .mg10t
    }
}

.ob-bord-1-c{
    border: 1px solid #ccc
}

.ob-input{
    transition: all 200ms ease;
    border: 1px #ccc solid;
}

.ob-input:focus{
    outline: none;
    border: 1px $section-background-color-light solid;
}

*{
    letter-spacing: -0.2px;
}

.logo-img{
    width: auto;
    height: 50px;

    @include MQ(mx-S-C){
        height: 40px;
    }
}

$icon-img : 20px;
.icon-img{
    width: $icon-img;
    height: $icon-img;
}
.icon-img-2{
    width: $icon-img - 3px ;
    height: $icon-img - 3px;
}

.icon-img,
.icon-img-2{
    path {
        color: $main3;
        fill: $main3;
    }
}
// Theme overrides
html,
body{
    //height: 100%;
    //margin-top: 58px;
    //min-height: calc(100vh - 58px);
    //margin-bottom: 92px;
}

.fa-15x {
    font-size: 1.5em;
}
.menu-list{
    list-style: none;

    &.bord {
        li {
            border-left: 1px solid $border-color;
        }
    }
}
.divider-vertical{
    border-right: 1px solid $border-color;
    //height: 75px;
    height: 100%;

    //@include MQ(mx-S-C){
    //    height: 50px;
    //}
}
.strike-down{
    @extend .posr;

    &::before{
        content: '';
        position: absolute;
        bottom: -10px;
        border-bottom: 3px solid #ff5252;
        width: 75px;
    }
}

a{
    &:hover{
        text-decoration: none;
    }
}

.publisher-header{

    .header-menu{
        @extend .flex-auto,.textc,.pg6tb;

        &.active{
            @extend .posr;
            background: #fff;
            &:before{
                @extend %before-content;
                border-top: 18px solid transparent;
                border-bottom: 18px solid transparent;
                border-left-color: #eee;
                border-left-style: solid;
                border-left-width: 10px;
                left:0;
            }
            &:after{
                @extend %before-content;
                border-top: 18px solid transparent;
                border-bottom: 18px solid transparent;
                border-left-color: #fff;
                border-left-style: solid;
                border-left-width: 18px;
                right:0;
                margin-right: -10px;
            }

        }
        &.checkout{
            @extend .posr;
            &:after{
                @extend %before-content;
                border-top: 18px solid transparent;
                border-bottom: 18px solid transparent;
                border-left-color: #fff;
                border-left-style: solid;
                border-left-width: 18px;
                right:0;
                margin-right: -23px;
            }
            &:before{
                @extend %before-content;
                border-top: 18px solid transparent;
                border-bottom: 18px solid transparent;
                border-left-color: #ccc;
                border-left-style: solid;
                border-left-width: 18px;
                right:0;
                margin-right: -25px;
            }

            &:last-child{
                &:after,
                &:before{
                    border-width: 0px;
                }

            }

            &.active{
                @extend .posr,.crff;
                background: $main3;

                &:first-child{
                    &:before{
                        border-left-width: 12px;
                        border-left-color: #eee;
                    }
                }

                &:before{
                    border-left-width: 12px;
                    border-left-color: #fff;
                }
                &:after{
                    border-left-color: $main3;
                    border-left-width: 15px;
                    margin-right: -15px;
                }

                &:last-child{
                    &:before{
                        border-width: 18px;
                    }
                }

                &.payments{
                    //margin-right: 15px;
                }
            }
        }

        @include MQ(mx-S-C){
            //min-width: 60%;
        }

    }
}

// fancy checkboxes
ul.ks-cboxtags {
    list-style: none;
    padding: 20px;
}
ul.ks-cboxtags li{
    display: inline;
}
ul.ks-cboxtags li label{
    display: inline-block;
    background-color: lighten($main1, 20%);
    border: 2px solid rgba(139, 139, 139, .3);
    color: #fff;
    border-radius: 2px;
    white-space: nowrap;
    margin: 3px 0px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: all .2s;
}

ul.ks-cboxtags li label {
    padding: 8px 12px;
    cursor: pointer;
}

ul.ks-cboxtags li label::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    padding: 2px 6px 2px 2px;
    content: "\f1ce";
    transition: transform .3s ease-in-out;
}

ul.ks-cboxtags li input[type="radio"]:checked + label::before {
    content: "\f00c";
    transform: rotate(-360deg);
    transition: transform .3s ease-in-out;
}

ul.ks-cboxtags li input[type="radio"]:checked + label {
    border: 2px solid #1bdbf8;
    background-color: #12bbd4;
    color: #fff;
    transition: all .2s;
}

ul.ks-cboxtags li input[type="radio"] {
    display: absolute;
}
ul.ks-cboxtags li input[type="radio"] {
    position: absolute;
    opacity: 0;
}
ul.ks-cboxtags li input[type="radio"]:focus + label {
    //border: 2px solid #e9a1ff;
}

.min-width-250p{
    min-width: 250px;
}

.scrolling-wrapper {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none;
    }

    .content-card {
        flex: 0 0 auto;
    }

}
.custom-file{
    input{
        z-index: 100;
        //text-indent: 100%;
    }
}

// modal lg
.modal-dialog{
    .modal-lg{
        max-width: 800px;
        width: 100%;
    }
}

.d-block,
.progress-true{
    display: block !important;
}
.d-none,
.progress-false{
    display: none !important;
}

.modal{
    .modal-header{
        padding: 20px !important;
        //border-bottom: 1px solid #ccc !important;
    }
}

.progress-parent {
    height: 15px;  /* Can be anything */
    position: relative;
    background: #555;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    border-radius: 25px;
    -webkit-box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
    -moz-box-shadow   : inset 0 -1px 1px rgba(255,255,255,0.3);
    box-shadow        : inset 0 -1px 1px rgba(255,255,255,0.3);
}
.progress-parent > .progress-bar {
    display: block;
    height: 100%;
    -webkit-border-top-right-radius: 8px;
    -webkit-border-bottom-right-radius: 8px;
    -moz-border-radius-topright: 8px;
    -moz-border-radius-bottomright: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-bottom-left-radius: 20px;
    -moz-border-radius-topleft: 20px;
    -moz-border-radius-bottomleft: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: rgb(43,194,83);
    background-image: -webkit-gradient(
            linear,
            left bottom,
            left top,
            color-stop(0, rgb(43,194,83)),
            color-stop(1, rgb(84,240,84))
    );
    background-image: -moz-linear-gradient(center bottom, rgb(43,194,83) 37%, rgb(84,240,84) 69%);
    -webkit-box-shadow:
        inset 0 2px 9px  rgba(255,255,255,0.3),
        inset 0 -2px 6px rgba(0,0,0,0.4);
    -moz-box-shadow:
        inset 0 2px 9px  rgba(255,255,255,0.3),
        inset 0 -2px 6px rgba(0,0,0,0.4);
    box-shadow:
        inset 0 2px 9px  rgba(255,255,255,0.3),
        inset 0 -2px 6px rgba(0,0,0,0.4);
    position: relative;
    overflow: hidden;
}
.progress-parent > .progress-bar:after, .animate > span > span {
    content: "";
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    background-image:
        -webkit-gradient(linear, 0 0, 100% 100%,
            color-stop(.25, rgba(255, 255, 255, .2)),
            color-stop(.25, transparent), color-stop(.5, transparent),
            color-stop(.5, rgba(255, 255, 255, .2)),
            color-stop(.75, rgba(255, 255, 255, .2)),
            color-stop(.75, transparent), to(transparent)
        );
    background-image:
        -moz-linear-gradient(
                -45deg,
                rgba(255, 255, 255, .2) 25%,
                transparent 25%,
                transparent 50%,
                rgba(255, 255, 255, .2) 50%,
                rgba(255, 255, 255, .2) 75%,
                transparent 75%,
                transparent
        );
    z-index: 1;
    -webkit-background-size: 50px 50px;
    -moz-background-size: 50px 50px;
    -webkit-animation: move 2s linear infinite;
    -webkit-border-top-right-radius: 8px;
    -webkit-border-bottom-right-radius: 8px;
    -moz-border-radius-topright: 8px;
    -moz-border-radius-bottomright: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-bottom-left-radius: 20px;
    -moz-border-radius-topleft: 20px;
    -moz-border-radius-bottomleft: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
}

.animate > span:after {
    display: none;
}

@-webkit-keyframes move {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 50px 50px;
    }
}

.progress-parent-orange > .progress-bar {
    background-color: #f1a165;
    background-image: -moz-linear-gradient(top, #f1a165, #f36d0a);
    background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #f1a165),color-stop(1, #f36d0a));
    background-image: -webkit-linear-gradient(#f1a165, #f36d0a);
}

.progress-parent-red > .progress-bar {
    background-color: #f0a3a3;
    background-image: -moz-linear-gradient(top, #f0a3a3, #f42323);
    background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #f0a3a3),color-stop(1, #f42323));
    background-image: -webkit-linear-gradient(#f0a3a3, #f42323);
}

.nostripes > span > span, .nostripes > span:after {
    -webkit-animation: none;
    background-image: none;
}


.select2-width-f .select2-container{
    @extend %width-custom1;
}

ul,ol{
    margin: 0px;
}

// category on hover dropdown

$width : 600px;


.category-dropdown {
    position: relative;
    transition: all 1s ease;

    &:hover{
        .category-dropdown-content{
            display: block;
        }
    }
}

.category-dropdown-content {
    display: none;
    position: absolute;
    left: -$width/2;
    min-width: $width;
    z-index: 1;
    transition-duration: 0.5s;
    @extend .z-depth-1,.bgff,.pg10a,.mg10t;

}

a.category-link {
    @extend .bor1, .bora, .pl;
    width: 100%;
    border-top-color: #fff;
    border-left-color: #fff;
    border-right-color: #ddd;
    border-bottom-color: #ddd;

    &:hover {
        @extend .bgdd, .borcaa;
    }

    &.active {
        @extend .bgcc, .borcaa;
    }

    .fas {
        width: 15px;
        text-align: center;
    }
}

.color-primary-dark{
    color: $primaryDarkColor;
}

.border-color-primary-dark{
    border-color: $primaryDarkColor;
}
.widthf{
    width: 100%;
}
.bgff-md{
    @include MQ(mx-S-C){
        background: #fff;
    }
}
.t-val-m{
    vertical-align: middle !important;
}
.firebaseui-title{
    display: none;
}
.select2-container--open{
    z-index: 10000;
}
.select2-dropdown {
    &.select2-container--default {

        &.select2-container--open{
            z-index: 10000;
        }

    }
}

.pg0l-md{
    @include MQ(mx-S-C){
        padding-left: 0px !important;
    }
}
.pg0r-md{
    @include MQ(mx-S-C){
        padding-right: 0px !important;
    }
}
.width-f-md{
    @include MQ(mx-S-C){
        width: 100% !important;
    }
}
.nobg-md{
    @include MQ(mx-S-C){
        background: none !important;
    }
}
.flex-wrap-md{
    @include MQ(mx-S-C){
        flex-wrap: wrap;
    }
}
.font30p,
.font30px
{
    font-size: 30px;
}
.font20p,
.font20px
{
    font-size: 20px;
}
.font16p,
.font16px
{
    font-size: 16px;
}
.font15p,
.font15px
{
    font-size: 15px;
}
.font14p,
.font14px
{
    font-size: 14px;
}
.font13p,
.font13px
{
    font-size: 13px;
}
.font12p,
.font12px
{
    font-size: 12px;
}
.font11p,
.font11px
{
    font-size: 11px;
}
.font10p,
.font10px
{
    font-size: 10px;
}
.font8p,
.font8px
{
    font-size: 8px;
}

// search all
.search-all{
    @extend .pg20l,.pg0tb,.pg0r,.bor1,.borcdd,.bora,.br20;

    input{
        @extend .bgee;
        &:visited,&:focus{
            border-width: 0px !important;
        }
    }
}

.search-all:focus{
    outline: none;
}

.container{

    @include MQ(mx-M-C){
        max-width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}


.item-width{
    width: 205px;
    //width: calc(33.33% - 20px);
    max-width: 300px;
    // @extend .mg10tb;
    &.medium{
        width : 200px;

        @include MQ(mx-S-C){
            width: 140px;
        }
    }

    @include MQ(mx-S-C){
        width: 140px;
    }
    // @include MQ(mx-M-C){
    //     margin-bottom: 10px !important;
    // }

    // &.large{
    //     width : 270px;
    // }
}
.item-height{
    height: 270px;

    &.large{
        height : 350px;

        @include MQ(mx-S-C){
            height: 190px;
        }
    }
    &.medium{
        height : 270px;

        @include MQ(mx-S-C){
            height: 190px;
        }
    }

    @include MQ(mx-S-C){
        height: 190px;
    }
}

.mg10a{
    @include MQ(mx-S-C){
        //margin: 5px !important;
    }
}


$category-width: 200px;
.category-page-column{
    width: $category-width;
}
.category-page-list{
    width: calc(100% - #{$category-width});
}
//Header
.header-link-text, .header-link-mob{
    // border-right: 1px solid $border-color;
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // height: 75px;
}

.header-link-mob{
    // height: 50px;
    display: flex;
    align-items: center;

    @include MQ(mx-SX-C){
        padding: 5px;
    }
}

// hambuger menu
.navbar-nav-right{
    display: none;
    width: 230px;
    height: calc(100% - 45px);
    position: fixed;
    right:0;
    top:45px;
    z-index: 999;
    background: #fff;
    border-left: #d3d3d3 solid 1px;
    overflow-y: auto;
    //@extend .pg15a;

    // *{
    //     font-size: 16px;
    //     color: #404040;
    // }

    &.mds-active{
        display: block;
    }
    transition: all ease-in;
    animation-name: sideenter;
    animation-duration: 250ms;
}

@keyframes sideenter{
    from {
        opacity: 0;
        transform: translateX(20px);
    }
    to{
        opacity: 1;
        transform: translateX(0);
    }
}

.nav-item a{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidemenu{

    .cat-count{
        display: none;
    }

    .category-link{
        @extend .pg8tb;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-bottom: 0px;
    }
}
.posf{
    position: fixed;
    z-index: 1000;
}
.topheader-nav-h{
    height: 70px;

    &>.container-fluid{
        height: 100%;
    }
}
.topheader-nav a{
    color : $main2;
}
.home-load-div-mg{
    margin-top: 70px;
}
.home-load-div{
    min-height: calc(100vh - 70px);
    @include MQ(mx-M-C){
        //min-height: calc(100vh - 80px);
        //margin-top: 20px;
    }

    &.fixed-h{
        height: calc(100vh - 70px);
        @include MQ(mx-M-C){
            //height: calc(100vh - 80px);
            //margin-top: 20px;
        }
    }
}
.menu-list > li  > a,
.menu-list > li > div > a{

    font-size: 16px;
    // @extend .semi-bold;

    @include MQ(mx-M-C){
        font-size: 13px;
    }


}
.slider-bg{
    background-image: linear-gradient(90deg, rgba($main1, 0.9) 40%, rgba($main1, 0.2));
}

.ts-main-1{
    text-shadow: 0 1px 2px rgba($main1, 0.3);
}


//
$scrollable-item : 20px;
.scrollable-item{

    @include MQ(mx-M-C){

        // a{
        //     &:first-child{
        //         padding-left: 20px;
        //     }
        //     &:last-child{
        //         padding-right: 20px;
        //     }
        // }

        margin-right: -$scrollable-item !important;
        margin-left: -$scrollable-item !important;
        width: calc(100% + #{$scrollable-item*2}) !important;
        padding-left: 20px;
    }
}

.issue-img{

    @include MQ(mx-M-C){
        max-width: 60%;
    }
}

.search-filter{
    //width: 33%;

    @include MQ(mx-M-C){
        //width: 100%;
    }
}



// gradient colors

.gr-cr1{
    @include background-gradient(rgba($main1, 0), rgba($main1, 1), vertical)
}
.gr-cr2{
    @include background-gradient(rgba($main2, 0), rgba($main2, 1), vertical)
}
.gr-cr3{
    @include background-gradient(rgba(#000, 0), rgba(#000, 1), vertical)
}
.gr-cr4{
    @include background-gradient(rgba($main4, 0), rgba($main4, 1), vertical)
}
.gr-cr5{
    @include background-gradient(rgba($main3, 0), rgba($main3, 1), vertical)
}

.gr-cra{
    @include background-gradient(rgba($alert, 0), rgba($alert, 1), vertical)
}

// #0076af -- main 2

.cap_all,
.select2-container{

    *{
        text-transform: capitalize !important;
    }
}


.home-carousel-height{
    height: 300px;

    @include MQ(mx-M-C){
        height: 200px;
    }
}

.fa-3x{

    @include MQ(mx-M-C){
        font-size: 1rem;
    }
}


.textl-md,
.textl-md *
{

    @include MQ(mx-M-C){
        text-align: left;
    }
}
.select2-container{
    @extend %width-custom1;
}


.mg0b-md{
    @include MQ(mx-M-C){
        margin-bottom: 0px !important;
    }
}
.pg20tb{
    @include MQ(mx-M-C){
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
}
.pg20t{
    @include MQ(mx-M-C){
        padding-top: 10px !important;
    }
}
.pg20b{
    @include MQ(mx-M-C){
        padding-bottom: 10px !important;
    }
}
.mg20tb{
    @include MQ(mx-M-C){
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }
}
.mg20t{
    @include MQ(mx-M-C){
        margin-top: 10px !important;
    }
}
.mg20b{
    @include MQ(mx-M-C){
        margin-bottom: 10px !important;
    }
}

.bor0lr{
    border-left: 0px solid transparent !important;
    border-right: 0px solid transparent !important;
}
.bor0l{
    border-left: 0px solid transparent !important;
}
.bor0r{
    border-right: 0px solid transparent !important;
}
.bor0tb{
    border-top: 0px solid transparent !important;
    border-bottom: 0px solid transparent !important;
}
.bor0t{
    border-top: 0px solid transparent !important;
}
.bor0b{
    border-bottom: 0px solid transparent !important;
}



.nowrap{
    white-space: nowrap;
}
.min-w-a {
    min-width: auto !important;
}
.toast{
    background-color: $main1;
    font-size: 1.2rem;
    width: auto !important;
}
.toast-message{
    white-space: nowrap;
    @extend .pg20lr;
}

.nav-link{
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 0 !important;
    width: auto;
    color: #000;
    @include MQ(L){
        width: 20rem !important;
    }
}
.nav-link.active {
    color: #ffffff!important;
    background-color: $primaryDarkColor!important;
}
.nav-link:hover{
    background-color: aliceblue;
}
#toast-container>.toast-info {
    background-image: url("/images/favicon_io_2/favicon-32x32.png")!important;
    background-repeat: no-repeat!important;
}
.profile-nav{
    flex-flow: nowrap;
    flex-basis: auto !important;
    overflow-x: hidden;
    max-width: 98vw;
    @include MQ(mx-S-C){
        overflow-x: scroll;
    }
}

.card-md{
    @include MQ(mx-S-C){
        display: flex;
        height: 250px;
        flex-basis: auto;
        justify-content: space-around;
        padding: 0 12px;
    }
}
.mg12t-md{
    @include MQ(mx-S-C){
        margin-top: 12px !important;
    }
}
.pg20l-md{
    padding-left: 20px !important;
    @include MQ(mx-S-C){
        padding-left: 0px !important;
    }
}
.text-center-md{
    @include MQ(mx-S-C){
        text-align: center !important;
    }
}

.flex-items-center{
    display: flex;
    align-items: center;
}

.text-nowrap{
    white-space: nowrap;
}

.badge-complete{
    background-color: #f7b657;
}

@-webkit-keyframes spin {
    from {-webkit-transform:rotate(0deg);}
    to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

.spinner-icon::after {
    content:' ';
    display:block;
    position:absolute;
    left:48%;top:10%;
    width:100px;height:100px;
    border-style:solid;
    border-left-color:$main4;
    border-right-color:$main5;
    border-bottom-color:$main1;
    border-top-color:transparent;
    border-width: 6px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}


.strike-through{
    @extend .posr;


    &::after{
        @extend %before-content;
        border-top: 1px solid #999;
        width:10%;
        margin-top: 10px;
        right: 0;
    }
    &::before{
        @extend %before-content;
        border-top: 1px solid #999;
        width:10%;
        margin-top: 10px;
        left:0;
    }

}

.iti{
    @extend %width-custom1;
}

.show.flex {
    display: flex !important;
}

.show2{
    display: inline-block;
}

.social-or{
    width: 50px;
    height: 50px;
    @extend .bor1,.bora,.borccc,.br50,.posr;

    &::after,
    &::before
    {
        @extend %before-content;
    }

    &::before{
        border-left: 1px solid #ccc;
        height: 80px;
        top: -80px;
        z-index: 0;
    }
    &::after{
        border-left: 1px solid #ccc;
        height: 100px;
        bottom: 0;
        top: 50px;
        z-index: 0;
    }

    @include MQ(mx-S-C){
        &::after,
        &::before{
            display: none;
        }
    }
}

.register-link-loaded{
    .hide{
        display: inline-block !important;
        visibility: visible;
    }
    .show2{
        display: none;
    }
}


.hgt100vh{
    height: 100vh;
}

.hgt98vh{
    height: 98vh;
}


.most_popular{
    button{
        @extend .bor0;
    }
}

.file_upload_input{
    -moz-opacity:0 ;
    //filter:alpha(opacity: 0);
    opacity: 0;
    z-index: 2;
}

.mirror {
    transition: transform 0.8s;
    transform-style: preserve-3d;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.body-m{
    margin-top: 75px;
    min-height: calc(100vh - 75px);

    @include MQ(mx-S-C){
        margin-top: 50px;
        min-height: calc(100vh - 50px);
      }
    //margin-top: 110px;
    //min-height: calc(100vh - 110px);
    //
    //@include MQ(mx-S-C){
    //    margin-top: 75px;
    //    min-height: calc(100vh - 75px);
    //  }
}

.brn{
    border: none;
}

.flex-grow{
    flex-grow: 1;
}

.home-navigator{
    display: flex;
    align-items: baseline;
    background-color: transparent;
    border: none;
}

.view-more-i{
    display: none;
}

.home-navigator img{
    height: 24px;
    margin-right: 0.5rem;
}

.home-navigator h4{
    margin: 0;
    font-size: 1.6rem;
    color: #01425f;
}

.dropdown-item.news{
    padding: 0.5rem 0;
}

.user-icon-text{
    font-size: 1.5rem;
    margin-left: 0.5rem !important;
}

//Home-Page
.home-news{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.home-news-title-container{
    background-color: black;
    padding: 1rem;
    width: 100%;
}

.home-news-title{
    color: white;
}

.home-art-row{
    display: flex;
    flex-direction: row;
    overflow-y: visible;
    overflow-x: auto;
    gap: 1rem;
    padding: 30px 0 30px 20px;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 6px;
        width: 10px;
    }
      
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(0,0,0,.5);
    }
}

.home-row-container{
    display: flex;
    flex-direction: column;
    transition: all ease .2s;
    cursor: pointer;
    box-shadow: 1px 3px 10px 0 rgb(0 0 0 / 10%)
}

.home-rounded-container{
    position: relative;
    height: 32vw;
    min-height: 200px;
    border-radius: 5px;
    overflow: hidden;
    background-color: rgb(random(100), random(100), random(100));
}

.home-more-container{
    display: flex;
    align-items: center;
}

.home-more-holder{
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-right: 50px;
    padding: 1rem;
    border-radius: 10px;
    background: none;
}

.home-more-holder.invert{
    background-color: black;
    color: white;
}

.home-more-holder span {
    text-align: center;
}

.home-more-news-i{
    padding: 1em;
    border: gray solid 1px;
    border-radius: 50px;
    width: 50px;
}

.home-art-size{
    min-width: 70%;
}

.home-source-pl{
    align-items: center;
}

.home-logo-stroke{
    padding: 1px;
    border-radius: 100%;
    background-color: #fafafa;
}

.home-art-logo-container{
    height: 30px;
    width: 30px;
    border-radius: 100%;
    overflow: hidden;
    background-color: #fafafa;
    -moz-background-clip: content-box;
    -webkit-background-clip: content-box;
}

.home-art-logo{
    height: 100%;
    width: 100%;
    object-fit: scale-down;
}

.medium-hide{
    display: block;
    margin-top: 20px;
}

.small-hide{
    display: none;
}

.small-hidef{
    display: none;
}

.rem-bord{
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #e5e5e5;
}

.rem-round-bord{
    border: 1px solid #e5e5e5;
}

.ov-bord{
    border-radius: 5px;
    overflow: hidden;
}

.whitesmoke{
    background-color: #f5f5f5;
}

.news-subheader{
    position: sticky;
    top: 50px;
    z-index: 30;
    transition: all 300ms linear;
}

.news-title{
    background-color: $section-background-color;
    border-bottom: $primary-light-background solid 1px;
}

.news-title h2{
    color: white;
    margin: 0;
}

.selector-container{
    position: relative;
}

button.show-previous, button.show-next{
    position: absolute;
    top: 0;
    border: none;
    color: white;
    height: 100%;
    transition: all 300ms ease;
    opacity: 1;
    visibility: visible;

    img{
        height: 20px;
    }

    img.visitor{
        position: absolute;
        top: 36%;
        z-index: 10;
    }
}

.selector-container button.show-previous{
    padding: 0.5rem 1rem;
    left: 0;
    background: linear-gradient(270deg, transparent , #0e7ebed9 40%, #0e7ebee6 80%);

    img{
        transform: rotate(180deg);
    }

    img.visitor{
        right: 46%;
    }
}

.selector-container button.show-next{
    padding: 0.5rem 1rem;
    right: 0;
    background: linear-gradient(90deg, transparent , #0e7ebed9 40%, #0e7ebee6 80%);

    img.visitor{
        left: 46%;
    }
}

.selector-container button.show-previous.hide-more, .selector-container button.show-next.hide-more{
    visibility: hidden;
    opacity: 0;
}

.more-pulse-foreground{
    background-color: #000000;
    height: 16px;
    width: 16px;
    position: absolute;
    top: 40%;
    left: 48%;
    border-radius: 10px;
    opacity: 1;
    transform: scale(1.6);
}

.more-pulse{
    background-color: white;
    height: 16px;
    width: 16px;
    position: absolute;
    top: 40%;
    left: 48%;
    border-radius: 10px;
    opacity: 1;
    transform: scale(1.5);
    animation: pulse 1000ms ease-out;
    animation-iteration-count: infinite;
    animation-direction: normal;
}

@keyframes pulse{
    0% {
        transform: scale(1.5);
    }

    50% {
        opacity: 0.3;
    }

    80% {
        opacity: 0;
    }

    90%{
        opacity: 0;
    }

    100% {
        transform: scale(2.2);
        opacity: 0;
    }
}

.section-selector{
    display: flex;
    background-color: $section-background-color;
    overflow: auto;
    white-space: nowrap;
}

.news-subheader.is-hidden{
    transform: translateY(-110%);
}

.section-selector button{
    border: none;
    background-color: $section-background-color;
    color: white;
    border-right: .5px solid white;
    padding: 0.5rem 1rem;
    flex-grow: 1;
}

.section-selector button::after{
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #FFFFFF;
    transition: width .3s;
}

.section-selector button:hover:after{
    width: 100%;
}

.active-link{
    font-weight: 600;
    color: #0e7ebe !important;
    background-color: white !important;
}

.section-selector button.active-link:hover:after{
    width: 0%;
}

.separator{
    width: 1px;
    height: 20px;
    background-color: #666666;
    margin: 0 0.5rem;
}

.news-section-title{
    margin: 1rem;
    margin-bottom: 0;
    font-weight: 700 !important;
}

.article-holder{
    display: inline-block;
}

.article-card{
    width: 45vw !important;
    max-width: 45vw;
    height: 186px !important;
}

.article-div{
    width: 45vw !important;
    max-width: 45vw !important;
    height: 186px !important;
}

.article-row-div{
    width: 100%;
}

.card-image-container{
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
    position: relative;
}

.article-image{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
}

.article-row-image{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
}

.article-title-font{
    font: normal normal bold Overpass;
    letter-spacing: 0.4px;
}

.article-title-home, .article-title{
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    font-weight: 600 !important;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    line-height:1.3em;
    float:left;
}

.article-title{
    //height: 7.2ex;
}

.article-title-home{
    min-height: 2em;
}

.article-row-title{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    float:left;
}

.article-body p{
    font-size: 1.15rem;
    font-family: "Source Serif Pro", sans-serif;
    white-space: pre-wrap;
}

.text-black{
    color: #000000 !important;
}

.linear-background{
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, #00000000 00%, #070707BF 100%);
}

.article-card-container{
    display: grid;
    width: 100%;
    grid-template-columns: 48% 48%;
    grid-row-gap: 1em;
    grid-column-gap: 1em
}

.l-grid{
    position: relative;
    display: block;
    overflow: hidden;
    transition: all ease 200ms;
    background-color: #4d0000;
    animation: shift-background 300s infinite;
}

.l-img{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 0;
}

.md-grid{
    grid-column: 1/3;
    transition: all ease 200ms ;
    position: relative;
    display: block;
    background-color: #4d0000;
    animation: shift-background 300s infinite;
    height: 450px;
    cursor: pointer;
}

.md-container{
    min-height: 230px;
    background-color: rgb(random(100), random(100), random(100));
}

.md-only-text-p{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    font-size: 1.125rem;
    overflow: hidden;
}

.ad-space{
    grid-column: 3 / 5;
}

.c-grid{
    margin: 0 0.2em;
    transition: all ease .3s;
    animation-name: article-loaded;
    animation-duration: 1s;
    cursor: pointer;
    border: solid #e5e5e5 1px;
}

.go-wide{
    grid-column: 1 /3;
    padding: 0;
}

.text-go-wide{
    padding: 0 0.5rem;
}

.article-row-container{
    display: grid;
    width: 100%;
    row-gap: 1rem;
}

.row-container{
    display: grid;
    grid-template-columns: 30% 70%;
    border: solid #e5e5e5 0.5px;
    margin: 0 1rem;
    transition: all ease .2s;
    cursor: pointer;
}

.row-container.opened{
    display: flex;
    flex-direction: column;
    margin: 0;
}

.row-image-container{
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
    position: relative;
}

.home-r-i-c{
    background-color: rgb(random(100), random(100), random(100));
    animation: shift-background 300s infinite;
}

.row-category{
    display: none;
}

.md-img{
    object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
}

.vh{
    height: 50vh;
    min-height: 400px;
}

.space-between{
    justify-content: space-between;
}

span.card-source{
    color: #666666;
    height: 6ex;
    overflow: hidden;
}

.l-title{
    font-size: 1.5rem !important;
    margin-bottom: 1rem !important;
}

.md-title{
    font-family: 'Source Serif Pro' !important;
    font-weight: 600 !important;
}

.fallback::before{
    content: '';
    height: 100%;
    width: 100%;
    background-color: #12bbd4;
}

.synopsis-large{
    display: none;
}

.synopsis-small {
    display: flex;
    flex-direction: column;
    grid-column: 1 / 3;
    padding: 0 2rem;
    max-height: 500px;
    overflow: hidden;
    transition: all ease 0.5s;
}

.synopsis-small p{
    font-family: 'Overpass', sans-serif;
    font-size: 1rem;
    text-align: start;
    line-height: 1.8rem;
}

.synopsis-small button{
    border: none;
    margin: auto;
}

.synopsis-small .more{
    background-color: white;
    margin-bottom: 1em;
}

.synopsis-small .close{
    font-weight: 300 !important;
    padding: 0.5em 1em;
    color: black;
    font-family: "Source Serif Pro" !important;
    text-shadow: none;
    text-transform: uppercase;
    border: solid 0.5px;
    border-radius: 20px;
    font-size: 1rem;
}

.synopsis-mid { /*changed */
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    max-height: 500px;
    overflow: hidden;
    transition: all ease 0.5s;
}

.synopsis-mid p{ /*changed */
    font-family: 'Overpass', sans-serif;
    font-size: 1rem;
    text-align: start;
    line-height: 1.8rem;
}

.synopsis-mid button{
border: none;
margin: auto;
}

.synopsis-mid .more{
    background-color: white;
    margin-bottom: 1em;
}

.synopsis-mid .close{
    font-weight: 300 !important;
    padding: 0.5em 1em;
    color: black;
    font-family: "Source Serif Pro" !important;
    text-shadow: none;
    text-transform: uppercase;
    border: solid 0.5px;
    border-radius: 20px;
    font-size: 1rem;
}
.strim{
    max-height: 0 !important;
}

.advert, .mel-ad{
    display: flex;
    justify-content: center;
    background-color: #ccc;
    padding: 1rem 0;
}

.mel-ad{
    width:100%;
}

.overlay{
    position: fixed;
    top: $header-height-mobile;
    left: 0;
    z-index: 100;
    height: calc(100vh - #{$header-height-mobile});
    background-color: rgba(5,5,5,0.7);
    width: 100%;
    overflow: scroll;
    transition: all ease 300ms;
}

.pop-article{
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 0;
    animation-name: pop-loaded;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.26, 0.66, 0.47, 1);
}

.pop-img-container{
    width: 100%;
    padding-top: 60%;
    overflow: hidden;
    position: relative;
    background-color: rgb(random(100), random(100), random(100));
}

.pop-img{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
}

.pop-text-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1.5rem 2.5rem;
    border-bottom: solid #9e9e9e 0.5px;
}

.pop-text-container div{
    margin-top: 0.8rem;
}

.pop-title{
    font-size: 1.6rem;
}

.pop-synopsis{
    padding: 1.5rem 2.5rem;
    max-width: 600px;
    font-size: 1.15rem;
    font-family: 'Source Serif Pro', sans-serif;
    white-space: pre-wrap;
}

.pop-article button{
    padding: 0.5em 2em;
    color: black;
    text-shadow: none;
    background-color: white;
    border: solid 0.5px;
    border-radius: 18% / 54%;
    font-size: 1rem;
    text-decoration: none;
}

.x-close, .x-close-home{
    position: fixed;
    top: 70px;
    right: 4%;
    border: none;
    border-radius: 5px;
    background-color: rgba($color: #000000, $alpha: 0.6);
    padding: 0.8rem 1rem;
    animation-name: pop-close;
    animation-duration: 400ms;
    animation-timing-function: ease-in;
    z-index: 999;
}

.x-close img, .x-close-home img{
    height: 20px;
}

.x-close-home{
    position: inherit;
    margin-bottom: 1em;
}

.pop-iframe-container, .radio-iframe-container{
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
}

.pop-iframe-container iframe, .radio-iframe-container iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.radio-container{
    position: fixed;
    width: 100%;
    bottom: 0;
    transition: all ease 150ms;
}

.radio-iframe-container{
    padding-bottom: 130px;
}

.r-down{
    transform: translateY(85px);
}

.pop-ad-segment{
    @extend .bgfa, .width-f, .pg10a, .flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 400px){
        flex-direction: row;
        column-gap: 1em;
    }
}

.pop-ad-img-container{
    height: 150px;
}

.pop-ad-container{
    padding: 1rem 0;
    max-width: 60%;
}

.pop-ad-container h4{
    text-align: center;
}

.pop-package-container{
    border: $section-background-color solid 1px;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    align-items: center;
    justify-content: space-between;
}

.pop-package-title{
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 12px;
    color: $section-background-color;
}

.pop-ad-link{
    display: block;
    margin-top: 10px;
    background-color: $section-background-color;
    text-align: center;
    border-radius: 50px;
    padding: 0.5rem;
    color: white;
    transition: all ease 250ms;
}

.pop-ad-link:hover{
    color: white;
    transform: scale(1.05);
}

.pop-package-i{
    background-color: $section-background-color;
    padding: 5px;
    border-radius: 16px;
    color: white;
}

.showingPop{
    z-index: 100;
    transform: translateY(0);
    opacity: 1;
}

.share-container{
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    background-color: #e2e2e2;
    margin-top: 1rem;
}

.share-container p {
    align-self: center;
    font-size: 1.2rem;
}

.social-container{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 0.5rem;
}

.social-link{
    padding: 0.5rem 1rem;
    color: #525252;
    display: flex;
    justify-content: center;
}

.social-link:hover{
    color: white;
    text-decoration: none;
}

//Floating Share Buttons
.float-share-container{
    right: 5%;
    bottom: 12%;
    display: flex;
    z-index: 99999;
    position: fixed;
    transition: all 250ms ease-in-out 0s;
    /* visibility: hidden; */
    flex-direction: column-reverse;
}

.float-share-main-button, .float-share-button{
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: block;
    z-index: 9999;
    position: relative;
    background: center center / cover no-repeat $section-background-color;
    box-shadow: rgb(0 0 0 / 16%) 0px 2px 5px 0px, rgb(0 0 0 / 12%) 0px 2px 10px 0px;
    text-align: center;
    transition: all 250ms ease-in-out 0s;
    visibility: visible;
    user-select: none;
    border-radius: 50%;
    text-decoration: none;
    outline: none !important;
    max-width: none !important;
}

.float-share-main-button{
    width: 56px;
    height: 56px;
    margin: 0px auto;
}

.float-share-button{
    width: 42px;
    height: 42px;
    margin: 0px auto 10px;
    opacity: 1;
}

.float-share-main-button:hover{
    background-color: rgb(0, 0, 0);
}

.float-share-main-button i, .float-share-button i {
    top: 50%;
    left: 50%;
    color: rgb(255, 255, 255);
    position: absolute;
    font-size: 20px;
    transform: translate(-50%, -50%);
    text-align: center;
    transition: all 250ms ease-in-out 0s;
}

.share-icons-open{
    transform: translateY(40px);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
}

.dismiss-pop{
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: -5;
    position: fixed;
    top: 0;
    left: 0;
}

.text-input{
    transition: all ease 300ms;

    input:focus{
        outline: none;
    }

    input::placeholder{
        color: #868E96;
    }

    textarea:focus{
        outline: none;
    }

    textarea::placeholder{
        color: #868E96;
    }

    select:focus{
        outline: none;
    }
}

.text-input:focus-within{
    border: solid $section-background-color-light 1px ;
}

.article-fade-enter-active{
    transition: all 400ms cubic-bezier(0.26, 0.66, 0.47, 1);
    opacity: 1;
}

.article-fade-enter{
    transform: translateY(100px);
    opacity:0
}

.article-fade-leave-to{
    opacity: 0;
    transform: scale(0.9);
}

@keyframes pop-loaded {
    from{transform: translateY(100px);}
    to{transform: translateY(0);}
}

@keyframes articles-loaded {
    from{
        transform: translateY(20px);
        opacity: 0;
}
    to{
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes pop-close {
    from{opacity: 0;}
    to{opacity: 1;}
}

@keyframes shift-background {
    0%   {background-color: #4d0000;}
    10%  {background-color: #4d2600;}
    20%  {background-color: #4d4d00;}
    30%  {background-color: #264d00;}
    40%  {background-color: #004d00;}
    50%  {background-color: #004d26;}
    60%  {background-color: #00264d;}
    70%  {background-color: #00004d;}
    80%  {background-color: #26004d;}
    90%  {background-color: #4d004d}
    100% {background-color: #4d0000}
  }

@media (min-width: 760px){
    .news-subheader{
        top: 75px
    }
}

.alert-text{
    color: #ce3c49;
}

@media (min-width: $breakpoint-tablet){
    .overlay{
        top: $header-height-laptop;
        height: calc(100vh - #{$header-height-laptop});
    }
    .pop-article{
        width: 60%;
    }
    .home-art-size{
        min-width: 300px;
    }
    .news-section-title{
        margin: 1rem auto;
        font-weight: 700 !important;
    }

    .pop-ad-img-container{
        height: 200px;
    }

    .radio-container{
        position: fixed;
        width: 400px;
        bottom: 2%;
        right: 2%;
        transition: all ease 150ms;
    }

    .radio-iframe-container{
        padding-bottom: 31.25%;
    }

    .r-down{
        bottom: 0;
    }
}

@media (min-width: $breakpoint-laptop){

    .home-art-row{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        overflow: visible;
        padding: 1rem 0;
    }

    .home-art-size{
        min-width: 37%;
    }

    .home-row-container:hover{
        transform: scale(1.05);
    }

    .home-more-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-row: 4;
        grid-column: -4/-1;
        align-items: center;
        justify-content: center;
    }
    
    .home-more-container hr{
        position: absolute;
        border: 0.2px solid #ccc;
        width: 100%;
    }

    .home-more-holder{
        width: auto;
        border: solid 1px;
        border-radius: 20px;
        padding: 5px 10px;
        z-index: 1;
        background-color: white;
    }

    .home-more-news-i{
        display: none;
    }

    .view-more-i{
        display: inline-block;
    }

    .home-navigator img{
        height: 20px;
    }

    .home-rounded-container{
        height: 15vw;
    }

    .medium-hide{
        display: none;
    }

    .small-hide{
        display: block;
    }

    .small-hidef{
        display: flex;
    }

    .article-card-container{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 1rem;
    }

    .md-grid{
        grid-row: 1/3;
        aspect-ratio: 0;
        height: 100%;
    }

    .md-grid:hover{
        transform: scale(1.05);
    }

    .c-grid:hover{
        transform: scale(1.05);
    }

    .md-img{
        height: 100%;
    }

    .h-f{
        height: 100%;
        background-color: rgb(random(100), random(100), random(100));
    }

    .card-image-container{
        border-radius: 0;
    }

    .article-image{
        border-radius: 0;
    }

    .article-row-container{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 1rem;
        row-gap: 2rem;
        width: 100%;
    }

    .l-grid{
        grid-column: 1/3;
        grid-row: 1/2;
        cursor: pointer;
    }

    .l-grid:hover{
        transform: scale(1.05);
    }

    .row-container{
        grid-template-columns: 1fr;
        margin: 0;
    }

    .row-container:hover{
        transform: scale(1.05);
    }

    .vh{
        height: 100%;
        min-height: 300px;
    }

    .row-span{
        font-size: .8rem;
    }

    .row-category{
        display: block;
        font-family: 'Overpass', sans-serif;
        font-size: .75rem;
        font-weight: 400;
        margin-top: .5rem;
        color: #F3201F;
    }

    .row-no-image{
        font-size: 1.3rem;
        -webkit-line-clamp: 4;
        height: 9.6ex;
    }
    
    .synopsis-large { /* Changed */
        overflow: hidden;
        grid-column: 1/5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transform: translateY(-40px);
        background-image: linear-gradient(180deg, #FFF0 0%, white 10%);
        padding: 2rem 1rem;
        transition: all ease .4s;
        max-height: 800px;
    }

    .synopsis-large p { /* Changed */
    align-self: center;
    max-width: 500px;
    font-family: "Source Serif Pro", sans-serif;
    font-size: 1rem;
    }

    .advert{
    grid-column: 3/5;
    align-items: center;
    }

    .trim{ /* Changed */
    padding: 0;
    max-height: 0;
    transform: translateY(0) !important;
    }

    .synopsis-large button{/* Changed */
    border: none;
    }

    .synopsis-large button.more{/* Changed */
    background-color: white;
    }

    .synopsis-large button.close{/* Changed */
    font-weight: 300 !important;
    padding: 0.5em 1em;
    color: black;
    font-family: "Source Serif Pro" !important;
    text-shadow: none;
    text-transform: uppercase;
    border: solid 0.5px;
    border-radius: 20px;
    font-size: 1rem;
    }

    .faded{ /* changed */
    opacity: 0.4;
    transform: scale(0.95);
    }

    .faded:hover{/* changed */
    opacity: 1;
    }

    .higher{/* changed */
    transform: translateY(-300px);
    }

    .synopsis-small{/* changed */
    display: none;
    }

    .synopsis-mid{
    display: none;
    }
    .text-go-wide{
    padding: 0;
    }

    .go-wide{
    grid-column: auto;
    }

    .social-container{
        grid-template-columns: repeat(8,1fr);
    }
}

@media (max-width: ($breakpoint-laptop - 1)){
    .home-art-title{
        margin-left: 20px;
    }
}

@media (min-width: 1440px){
    .pop-article{
        width: 45%;
    }
}
.hide-im{
    display: none !important;
}

//custom radioboxes
.custom_radioboxes{
    cursor: pointer;
    span {
        padding: 15px 10px;
        background: #f7f9fc;
        //border: 1px solid rgba($main4, .08);
        border-radius: 3px;
        text-align: center;
        width: 100%;
        display: block;
    }

    input[type="radio"]:checked + span {
        @extend .crff,.bg-cr2;
    }

    input {
        display: none;
    }
}

//ToolTip

.tooltipz {
    position: relative;
    display: inline-block;
}

.tooltipz .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -100px;
}

.tooltipz .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.tooltipz:hover .tooltiptext {
    visibility: visible;
}


.w-full{
    & > div{
        width: 100%;
    }

    .modal-dialog{
        max-width: 100%;
    }
}

progress {
    display:block;
    width:200px;
    height:20px;
    margin-bottom:1em;
}

.reset {
    progress {
        -webkit-appearance: none;
        appearance: none;
    }

    progress:not([value]) {
        // Style indeterminate progress bars
    }

    progress[value] {
        // Style determinate progress bars
    }
}

//Dashboard
.dash-nav{
    height: calc(100vh - 75px);
    gap: 5rem;
    overflow: hidden;
    width: 200px;
    transition: all ease 300ms;
    z-index: 10;

    @media (min-width: $ML){
        width: 260px;
    }
}

.dash-link{
    transition: all ease-in-out 100ms;
    white-space: nowrap;
    align-items: center;
    &:hover{
        border-left: solid 3px $section-background-color;
        transform: translateX(10px);
    }
}

.dash-active{
    border-radius: 0 30px 30px 30px;
}

.dash-active span,.dash-link span{
    opacity: 0;
    transition: all ease-in 150ms;
    height: 0;
    overflow: hidden;

    @media (min-width: $ML) {
        opacity: 1;
        height: auto;
    }
}

.dash-open{
    width: 260px;
    transition: all ease 300ms;

    .dash-active span, .dash-link span{
        transition: all ease-in 150ms;
        opacity: 1;
        height: auto;
    }
}

.dash-view{
    background-color:#F1F3F5;
    margin-left: 200px;
    transition: all ease 300ms;

    @media(min-width: $ML){
        margin-left: 260px;
    }
}

#drawer-toggler{
    display: block;
    transition: all ease 300ms;

    @media (min-width: $ML) {
        display: none;
    }

    i{
        cursor: pointer;
    }
}

/* line 1, ../scss/core.scss */
.select2-selection--multiple{
    overflow: hidden !important;
    height: auto !important;
}
.select2-container {
box-sizing: border-box;
display: inline-block;
margin: 0;
position: relative;
vertical-align: middle;
}
/* line 1, ../scss/_single.scss */
.select2-container .select2-selection--single {
box-sizing: border-box;
cursor: pointer;
display: block;
height: 38px;
user-select: none;
-webkit-user-select: none;
}
/* line 12, ../scss/_single.scss */
.select2-container .select2-selection--single .select2-selection__rendered {
display: block;
padding-left: 8px;
padding-right: 20px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}
/* line 25, ../scss/_single.scss */
.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
padding-right: 8px;
padding-left: 20px;
}
/* line 1, ../scss/_multiple.scss */

.select2-container .select2-selection--multiple {
box-sizing: border-box;
cursor: pointer;
display: block;
user-select: none;
-webkit-user-select: none;
}
/* line 12, ../scss/_multiple.scss */
.select2-container .select2-selection--multiple .select2-selection__rendered {
display: inline-block;
overflow: hidden;
padding-left: 8px;
text-overflow: ellipsis;
white-space: nowrap;
}
/* line 21, ../scss/_multiple.scss */
.select2-container .select2-search--inline {
float: left;
}
/* line 24, ../scss/_multiple.scss */
.select2-container .select2-search--inline .select2-search__field {
box-sizing: border-box;
border: none;
font-size: 100%;
margin-top: 3px;
margin-left: 3px;
}
/* line 31, ../scss/_multiple.scss */
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
-webkit-appearance: none;
}

/* line 1, ../scss/_dropdown.scss */
.select2-dropdown {
background-color: white;
border: 1px solid #DDD;
border-radius: 4px;
box-sizing: border-box;
display: block;
position: absolute;
left: -100000px;
width: 100%;
z-index: 1051;
}

/* line 18, ../scss/_dropdown.scss */
.select2-results {
display: block;
}

/* line 22, ../scss/_dropdown.scss */
.select2-results__options {
list-style: none;
list-style-type: none !important;
margin: 0;
padding: 0;
}

/* line 28, ../scss/_dropdown.scss */
.select2-results__option {
padding: 6px;
user-select: none;
-webkit-user-select: none;
}
/* line 34, ../scss/_dropdown.scss */
.select2-results__option[aria-selected] {
cursor: pointer;
}

/* line 39, ../scss/_dropdown.scss */
.select2-container--open .select2-dropdown {
left: 0;
}

/* line 43, ../scss/_dropdown.scss */
.select2-container--open .select2-dropdown--above {
border-bottom: none;
border-bottom-left-radius: 0;
border-bottom-right-radius: 0;
}

/* line 49, ../scss/_dropdown.scss */
.select2-container--open .select2-dropdown--below {
border-top: none;
border-top-left-radius: 0;
border-top-right-radius: 0;
}

/* line 55, ../scss/_dropdown.scss */
.select2-search--dropdown {
display: block;
padding: 7px;
}
/* line 59, ../scss/_dropdown.scss */
.select2-search--dropdown .select2-search__field {
padding: 4px;
width: 100%;
box-sizing: border-box;
}
/* line 64, ../scss/_dropdown.scss */
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
-webkit-appearance: none;
}
/* line 69, ../scss/_dropdown.scss */
.select2-search--dropdown.select2-search--hide {
display: none;
}

/* line 15, ../scss/core.scss */
.select2-close-mask {
border: 0;
margin: 0;
padding: 0;
display: block;
position: fixed;
left: 0;
top: 0;
min-height: 100%;
min-width: 100%;
height: auto;
width: auto;
opacity: 0;
z-index: 99;
background-color: #fff;
filter: alpha(opacity=0);
}

/* line 1, ../scss/theme/default/_single.scss */
.select2-container--default .select2-selection--single {
background-color: #f0f0f0;
border: 1px solid rgba(0, 0, 0, 0.1);
border-radius: 2px;
}
/* line 6, ../scss/theme/default/_single.scss */
.select2-container--default .select2-selection--single:focus {
outline: 0;
}
/* line 10, ../scss/theme/default/_single.scss */
.select2-container--default .select2-selection--single .select2-selection__rendered {
color: #444;
line-height: 34px;
}
/* line 15, ../scss/theme/default/_single.scss */
.select2-container--default .select2-selection--single .select2-selection__clear {
cursor: pointer;
float: right;
font-weight: bold;
}
/* line 21, ../scss/theme/default/_single.scss */
.select2-container--default .select2-selection--single .select2-selection__placeholder {
color: #999;
}
/* line 25, ../scss/theme/default/_single.scss */
.select2-container--default .select2-selection--single .select2-selection__arrow {
height: 36px;
position: absolute;
top: 1px;
right: 1px;
width: 20px;
}
/* line 35, ../scss/theme/default/_single.scss */
.select2-container--default .select2-selection--single .select2-selection__arrow b {
border-color: #888 transparent transparent transparent;
border-style: solid;
border-width: 5px 4px 0 4px;
height: 0;
left: 50%;
margin-left: -4px;
margin-top: -2px;
position: absolute;
top: 50%;
width: 0;
}
/* line 56, ../scss/theme/default/_single.scss */
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
float: left;
}
/* line 60, ../scss/theme/default/_single.scss */
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
left: 1px;
right: auto;
}
/* line 68, ../scss/theme/default/_single.scss */
.select2-container--default.select2-container--disabled .select2-selection--single {
background-color: #eee;
cursor: default;
}
/* line 72, ../scss/theme/default/_single.scss */
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
display: none;
}
/* line 81, ../scss/theme/default/_single.scss */
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
border-color: transparent transparent #888 transparent;
border-width: 0 4px 5px 4px;
}
/* line 1, ../scss/theme/default/_multiple.scss */
.select2-container--default .select2-selection--multiple {
background-color: #ffffff;
border: 1px solid rgba(0, 0, 0, 0.1);
-webkit-border-radius: 2px;
border-radius: 2px;
cursor: text;
height: 22px;
}
/* line 7, ../scss/theme/default/_multiple.scss */
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
box-sizing: border-box;
list-style: none;
list-style-type: none !important;
padding: 0 0 0 4px !important;
margin: 0;
padding: 0 5px;
width: 100%;
}
/* line 15, ../scss/theme/default/_multiple.scss */
.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
color: #999;
margin-top: 5px;
float: left;
}
/* line 23, ../scss/theme/default/_multiple.scss */
.select2-container--default .select2-selection--multiple .select2-selection__clear {
cursor: pointer;
float: right;
font-weight: bold;
margin-top: px;
margin-right: 2px;
}
/* line 31, ../scss/theme/default/_multiple.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  color: #ffffff;
  background-color: #4a89dc;
// border: 1px solid #ddd;
border-radius: 2px;
cursor: default;
float: left;
margin-right: 5px;
margin-top: 1px;
padding: 1px 2px 2px !important;
}
/* line 46, ../scss/theme/default/_multiple.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
color: #fff;
cursor: pointer;
display: inline-block;
font-weight: bold;
margin-right: 2px;
}
/* line 55, ../scss/theme/default/_multiple.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
color: #333;
}
/* line 63, ../scss/theme/default/_multiple.scss */
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder {
float: right;
}
/* line 67, ../scss/theme/default/_multiple.scss */
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
margin-left: 5px;
margin-right: auto;
}
/* line 72, ../scss/theme/default/_multiple.scss */
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
margin-left: 2px;
margin-right: auto;
}
/* line 80, ../scss/theme/default/_multiple.scss */
.select2-container--default.select2-container--focus .select2-selection--multiple {
border: 1px solid #CCC;
outline: 0;
}
/* line 87, ../scss/theme/default/_multiple.scss */
.select2-container--default.select2-container--disabled .select2-selection--multiple {
background-color: #eee;
cursor: default;
}
/* line 92, ../scss/theme/default/_multiple.scss */
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
display: none;
}
/* line 6, ../scss/theme/default/layout.scss */
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
border-top-left-radius: 0;
border-top-right-radius: 0;
}
/* line 13, ../scss/theme/default/layout.scss */
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
border-bottom-left-radius: 0;
border-bottom-right-radius: 0;
}
/* line 20, ../scss/theme/default/layout.scss */
.select2-container--default .select2-search--dropdown .select2-search__field {
border: 1px solid #DDD;
}
/* line 22, ../scss/theme/default/layout.scss */
.select2-container--default .select2-search--dropdown .select2-search__field:focus {
outline: 0;
}
/* line 29, ../scss/theme/default/layout.scss */
.select2-container--default .select2-search--inline .select2-search__field {
background: transparent;
border: none;
outline: 0;
}
/* line 36, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results > .select2-results__options {
max-height: 200px;
overflow-y: auto;
padding: 2px !important;
}
/* line 42, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option[role=group] {
padding: 0;
}
/* line 46, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option[aria-disabled=true] {
color: #999;
}
/* line 50, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option[aria-selected=true] {
background-color: #EEE;
}
/* line 54, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option .select2-results__option {
padding-left: 1em;
}
/* line 57, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
padding-left: 0;
}
/* line 61, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
margin-left: -1em;
padding-left: 2em;
}
/* line 65, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
margin-left: -2em;
padding-left: 3em;
}
/* line 69, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
margin-left: -3em;
padding-left: 4em;
}
/* line 73, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
margin-left: -4em;
padding-left: 5em;
}
/* line 77, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
margin-left: -5em;
padding-left: 6em;
}
/* line 88, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option--highlighted[aria-selected] {
background-color: #4a89dc;
color: white;
}
/* line 93, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__group {
cursor: default;
display: block;
padding: 6px;
}

//Table
.c-table{

    tbody{

        tr:nth-child(even){
            background-color: #fbfbfb;
        }
        
        tr:nth-child(odd){
            background-color: #fff;
        }
        
        tr:hover{
            background-color: aliceblue;
        }
        button{
            div.boo{
                div{
                    opacity: 0;
                    transition: all ease 150ms;
                }
            }
            &:hover{
                div.boo{
                    div{
                        opacity: 1;
                    }
                }
            }
        }
    }
}



[hidden] {
    display: none !important;
}

.msa-wrapper {
    width: 400px;

    &:focus-within {
        .input-presentation {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    & > * {
        display: block;
        width: 100%;
    }

    .input-presentation {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
        align-items: center;
        min-height: 40px;
        padding: 6px 40px 6px 12px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        font-size: 1rem;
        border-radius: 10px;
        position: relative;
        cursor: pointer;

        .placeholder {
            font-weight: 400;
            color: rgba(0,0,0, .6);
        }

        &:after {
            content: '';
            border-top: 6px solid black;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            right: 14px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
        }

        &.active {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .tag-badge {
            background-color: blueviolet;
            padding-left: 14px;
            padding-right: 28px;
            color: white;
            border-radius: 14px;
            position: relative;

            span {
                font-size: 16px;
                line-height: 27px;
            }

            button {
                display: inline-block;
                padding: 0;
                -webkit-appearance: none;
                appearance: none;
                background: transparent;
                border: none;
                color: rgba(255,255,255, .8);
                font-size: 12px;
                position: absolute;
                right: 0px;
                padding-right: 10px;
                padding-left: 5px;
                cursor: pointer;
                line-height: 26px;
                height: 26px;
                font-weight: 600;

                &:hover {
                    background-color: rgba(255,255,255, .2);
                    color: white;
                }
            }
        }
    }

    ul {
        border: 1px solid rgba(0, 0, 0, 0.3);
        font-size: 1rem;
        margin: 0;
        padding: 0;
        border-top: none;
        list-style: none;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;

        li {
            padding: 6px 12px;
            text-transform: capitalize;
            cursor: pointer;

            &:hover {
                background: blueviolet;
                color: white;
            }
        }
    }
}


.custom-pagination div,
.custom-pagination div nav,
.custom-pagination div nav .pagination{
    @extend %width-custom1
}


.pagination .page-item {
    @extend .flex-auto;

    .page-link{
        @extend .width-f,.textc;
    }
}

.blur {
    @extend %before-content;
    background: rgba(255, 255, 255, 0.3); // Make sure this color has an opacity of less than 1
    backdrop-filter: blur(8px); // This be the blur
    /* for firefox */
    @-moz-document url-prefix() {
        -webkit-filter: blur(3px);
    }
    height: 98%;
    width: 100%;
    margin-top: 1%;
}

// category Menu
.category-btn{
    background: transparent;
    border: none;
    font-weight: 400 !important;

    &:focus{
        border: none;
        color: #000;
        background: transparent;
        box-shadow: none;
    }

    &:hover{
        border: none;
        color: #000;
        background: transparent;
    }

    &:active{
        border: none !important;
        color: #000 !important;
        background: transparent !important;
    }
}

.category-menu{
    width: 850px;
    max-width: 100%;
    padding: 0 2em;
    position: fixed;
    top: 58px;
    left: 50%;
    transform: translateX(-50%);


    @include MQ(mx-M-C){
        margin-top: -25px;
    }

    ul.category-list{
        margin: 0px;
        list-style: none;
        display: flex;
        box-sizing: border-box;
        height: auto;
        flex-flow: row wrap;
        overflow-y: 0;
        padding: 0;
    }
}

//category-links

li.category-horizontal{
    flex: 0 1 25%;
    break-inside: avoid;
    letter-spacing: 0px;
    padding: 0.8rem;
    cursor: pointer;
    transition: color 0.2s ease-in-out 0s;
    color: rgb(27, 27, 27);
    text-transform: none;
    text-decoration: none;
    font-style: normal;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    text-align: left;
    border-bottom: 1px solid #eee;

    &:hover{
        background: #ddd;
    }

    a.cat-h{
        font-size: 14px;
        width: 100%;
        text-decoration: none;
        color: inherit;
        display: flex;
        -moz-box-align: center;
        align-items: center;

        div.icon-holder{
            display: flex;
            -moz-box-pack: center;
            justify-content: center;
            -moz-box-align: center;
            align-items: center;
            cursor: pointer;
            pointer-events: auto;
            color: rgb(27, 27, 27);height: 30px;
            width: 30px;
            margin-right: 0.5em;
        }
    }
}

// why-mgazeti
.cRePYR {
    display: unset;
}
.bhwQda {
    display: flex;
    width: 100%;
    color: rgb(27, 27, 27);
    background-color: rgb(255, 255, 255);
    background-image: none;
}
.jwyqAU {
    width: 1140px;
    max-width: 1140px;
    margin: 0px auto;
    display: flex;
    -moz-box-pack: start;
    justify-content: start;
    -moz-box-align: center;
    align-items: center;
    text-align: left;
    padding-top: 2rem;
    padding-bottom: 2rem;
    overflow-y: visible;
}
.jwyqAU#why-zinio-row1 {
    flex-direction: column;
    -moz-box-align: start;
    align-items: start;
    z-index: 9;
    min-height: 330px;
}
.jhnIQP {
    transition: color 0.2s ease-in-out 0s;
    color: rgb(27, 27, 27);
    text-transform: none;
    text-decoration: none;
    text-align: inherit;
    font-style: normal;
    font-size: 36px;
}
.jwyqAU > p, .jwyqAU > h2, .jwyqAU > div {
    margin: 1rem 2rem;
      margin-top: 1rem;
      margin-left: 2rem;
    max-width: 640px;
}
.jwyqAU#why-zinio-row1 .H1Typography-id_kjd8qk {
    margin-left: 6.5rem;
    margin-top: 3rem;
    line-height: 1.33;
}
.clWpnh {
    display: inline-block;
    padding: 0.875rem 1rem;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none !important;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    pointer-events: auto;
    transition: all 0.2s ease-in-out 0s;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    white-space: nowrap;
    flex-shrink: 0;
    min-width: 110px;
    max-width: none;
    width: auto;
    color: rgb(255, 255, 255);
    background: #0F70B7 none repeat scroll 0% 0%;
}
.jwyqAU#why-zinio-row1 .StyledButton-id_136n8tf-a {
    margin-left: 18rem;
    margin-top: 1rem;
}
.jwyqAU#why-zinio-row2 {
    z-index: 8;
    text-align: center;
    align-items: flex-start;
    padding-bottom: 0px;
}
.jwyqAU#why-zinio-row2 picture:first-child {
    margin-top: -7.5rem;
}
.jwyqAU#why-zinio-row2 picture:nth-child(3) {
    margin-top: -15rem;
}
.jwyqAU img {
    width: 100%;
}
.jwyqAU > p, .jwyqAU > h2, .jwyqAU > div {
    margin: 1rem 2rem;
    max-width: 640px;
}
.jwyqAU > p, .jwyqAU > h2, .jwyqAU > div {
  margin: 1rem 2rem;
  max-width: 640px;
}
.jwyqAU#why-zinio-row2 div {
    margin: 0px;
}
.jwyqAU div.check-list {
    display: flex;
    flex-direction: column;
    -moz-box-align: start;
    align-items: start;
    text-align: left;
}
.jwyqAU#why-zinio-row5 .check-list > div, .jwyqAU#why-zinio-row6 .check-list > div {
    align-items: flex-start;
}
.jwyqAU#why-zinio-row5 .check-list p, .jwyqAU#why-zinio-row6 .check-list p {
    margin: 0.2rem 0px 1rem;
}
.QBpGt {
    transition: color 0.2s ease-in-out 0s;
    color: inherit;
    text-transform: none;
    text-decoration: none;
    text-align: inherit;
    font-style: normal;
    font-size: 36px;
}
.hFatkq {
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
    padding: 0px;
    cursor: default !important;
    pointer-events: auto;
    color: rgb(255, 255, 255);

    svg{
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        margin-right: 0.3rem;
        transition: fill 0.2s ease-in-out 0s;
        fill: rgb(255, 255, 255);
    }
}
.dqlIpj {
    transition: color 0.2s ease-in-out 0s;
    color: inherit;
    text-transform: none;
    text-decoration: none;
    text-align: inherit;
    font-style: normal;
    font-size: 22px;
}
.jwyqAU#why-zinio-row2 div > h2 {
    margin-top: 0px;
}
.jwyqAU#why-zinio-row3 picture {
    margin-bottom: -12rem;
}
.jwyqAU#why-zinio-row3, .jwyqAU#why-zinio-row7 {
    text-align: center;
    z-index: 7;
    align-items: flex-start;
    padding-top: 0px;
}
.jwyqAU#why-zinio-row7 {
    padding-top: 40px;
    height: auto;
}
.jwyqAU#why-zinio-row3 p {
    margin-top: 0px;
}
.jwyqAU#why-zinio-row4, .jwyqAU#why-zinio-row8 {
    flex-direction: column;
    text-align: center;
    z-index: 6;
}
.jwyqAU#why-zinio-row5 {
    z-index: 5;
}
.dqlIpj {
    transition: color 0.2s ease-in-out 0s;
    color: inherit;
    text-transform: none;
    text-decoration: none;
    text-align: inherit;
    font-style: normal;
    font-size: 22px;
}
.lyaza {
    display: flex;
    width: 100%;
    color: rgb(255, 255, 255);
    background-color: rgb(3, 169, 244);
    background-image: linear-gradient(to right, #0F70B7 0%, #06426F 100%);
}
.kEqdPW {
    display: none;
}
@media only screen and (min-width: 993px){
    .jwyqAU#why-zinio-row4 .H2Typography-id_kjd8qk {
        flex: 3 1 0%;
    }
    .jwyqAU#why-zinio-row4 {
        flex-direction: row;
        padding-top: 18rem;
    }
    .jwyqAU#why-zinio-row8 {
        flex-direction: row;
        padding-top: 13rem;
    }
    .jwyqAU#why-zinio-row4 picture {
        flex: 5 1 0%;
    }
    .jwyqAU#why-zinio-row5 {
        align-items: flex-start;
    }
    .jwyqAU#why-zinio-row5 picture {
        flex: 5 1 0%;
    }
    .jErAQb {
        display: none;
    }
    .jwyqAU#why-zinio-row7 {
        padding-top: 100px;
        font-size: 36px;
    }
}
@media only screen and (min-width: 993px) and (max-width: 1140px){
    .jwyqAU {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
@media only screen and (min-width: 577px) and (max-width: 992px){
    .jErAQb {
        display: unset;
    }
    .jwyqAU {
        min-height: 220px;
    }
}
@media only screen and (max-width: 992px){
    .cRePYR {
        display: none;
    }
    .jwyqAU {
        width: 100%;
    }
    .jwyqAU {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .jwyqAU#why-zinio-row1 {
        min-height: 500px;
        -moz-box-align: center;
        align-items: center;
        text-align: center;
    }
    .jwyqAU#why-zinio-row1 .title {
        padding-bottom: 2.5rem;
        padding-top: 1.5rem;
        margin: 2rem 0px 0px;
        line-height: inherit;
    }
    .jwyqAU#why-zinio-row1 .StyledButton-id_136n8tf-a {
        margin: inherit;
    }
    .jwyqAU#why-zinio-row2 div {
        align-self: center;
    }
    .jwyqAU#why-zinio-row2 picture {
        margin-top: -11rem;
    }
    .jwyqAU#why-zinio-row3 {
        -moz-box-align: center;
        align-items: center;
    }
    .jwyqAU#why-zinio-row3 picture {
        margin-bottom: unset;
        max-width: 40%;
    }
    .jwyqAU#why-zinio-row3 p {
        margin: 0px 0px 1rem;
    }
    .jwyqAU#why-zinio-row4 picture:first-of-type {
        max-width: 70%;
        align-self: flex-end;
        margin-top: -6rem;
    }
    .jwyqAU#why-zinio-row4 p {
        margin-top: 2rem;
    }
    .jwyqAU#why-zinio-row4 picture:last-of-type {
        max-width: 75%;
    }
    .jwyqAU#why-zinio-row5 {
        flex-direction: column-reverse;

        div{
            width: 100%;
        }

        .image-wrapper {
            margin: 1rem 0px;
            max-width: unset;
            display: flex;
        }
    }
    .jwyqAU#why-zinio-row5 .image-wrapper picture:last-of-type {
        margin-bottom: -4rem;
    }
    .jwyqAU#why-zinio-row5 .check-list > div > svg, .jwyqAU#why-zinio-row6 .check-list > div > svg {
        width: 24px;
        height: 24px;
    }
    .jwyqAU#why-zinio-row5 .check-list p, .jwyqAU#why-zinio-row6 .check-list p {
        margin: 0px 0px 1rem;
    }
    .lyaza:nth-of-type(5):not([type="WHITE"]) {
        margin-bottom: -3rem;
    }
}
@media only screen and (max-width: 576px){
    .jErAQb {
        display: none;
    }
    .kEqdPW {
    display: unset;
    }
    .jwyqAU#why-zinio-row1 {
        min-height: 380px;
    }
    .jwyqAU#why-zinio-row1 picture {
        align-self: center;
        margin-top: 3rem;
    }
    .jwyqAU#why-zinio-row2 {
        flex-direction: column-reverse;
        padding: 0px 1rem;
    }
    .jwyqAU#why-zinio-row2 > div {
        margin: 17rem 0px 2rem;
        width: 100%;
    }
    .jwyqAU#why-zinio-row3 {
        flex-direction: column-reverse;
    }
    .jwyqAU#why-zinio-row3 picture {
        padding-top: 1rem;
        margin-bottom: -14rem;
        max-width: 75%;
    }
    .bhwQda:nth-of-type(4):not([type="GREY"]) {
        margin-top: 8rem;
    }
    .jwyqAU#why-zinio-row4 {
        gap: 0.5rem;
    }
    .jwyqAU#why-zinio-row4 picture:first-of-type {
        padding: 0.5rem 0px;
        width: 100%;
        align-self: center;
        margin-top: 0px;
        max-width: unset;
    }
    .jwyqAU#why-zinio-row4 picture:last-of-type {
        max-width: unset;
    }
    .jwyqAU#why-zinio-row5 {
        text-align: center;
        padding-top: 0px;
    }
    .jwyqAU#why-zinio-row5 picture {
        width: 100%;
    }
    .jwyqAU#why-zinio-row5 > div {
        width: 90%;
    }
    .lyaza:nth-of-type(2) {
        margin-top: -20rem;
    }
    .lyaza:nth-of-type(5):not([type="WHITE"]) {
        padding-bottom: 4rem;
        margin-bottom: 0px;
    }
}

//in this issue zinio
.article-container{
    display: flex;
    -moz-box-pack: justify;
    //justify-content: space-between;
    justify-content: start;
    gap: 2em;
}

.article-in-card{
    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    padding: 0px;
    min-width: 400px;

    &.explore{
        min-width: auto;
        margin-bottom: 0;
    }

    .article-in-card-div{
        padding: 0 0.8em 0.8em 0.8em;
        box-shadow: 0px 0px 3px #c6c6c6;
        background: #fff;
        border-radius: 4px;
    }
    
    article-info{
    display: block;
    margin: 0.5rem 0px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    
        span{
            font-weight: 400;
            transition: color 0.2s ease-in-out 0s;
            color: rgb(27, 27, 27);
            text-decoration: none;
            text-align: inherit;
            font-style: normal;
            font-size: 14px;
        }

        .article-publication{
            color: #1475bc;
            border-right: solid 1px #000;
            padding-right: 0.5rem;
        }
    }
    
    .article-content{
        display: flex;
        flex-direction: column;
        height: 170px;
        
        article-title{
            flex-shrink: 0;
            margin: 0.35rem 0px 0px;
            overflow: hidden;
            max-height: 44px;
            height: 44px;
            text-overflow: ellipsis;
            word-break: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            transition: color 0.2s ease-in-out 0s;
            color: rgb(27, 27, 27);
            text-transform: uppercase;
            text-decoration: none;
            text-align: inherit;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            font-size: 18px;
        }
    
        .article-excerpt-container{
            display: flex;
            -moz-box-pack: justify;
            justify-content: space-between;
            -moz-box-flex: 0;
            flex-grow: 0;
            flex-shrink: 1;
            height: 144px;
            overflow: hidden;
            
            .article-excerpt{
                margin: 0.5rem 0.5rem 0.5rem 0px;
                text-overflow: ellipsis;
                word-break: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                font-weight: 400;
                transition: color 0.2s ease-in-out 0s;
                color: rgb(103, 104, 111);
                text-transform: none;
                text-decoration: none;
                text-align: inherit;
                font-style: normal;
                font-size: 14px;
                line-height: 22px;
            }
            
            .article-image-small{
                align-self: center;
                margin: 0.5rem 1px 1px 1rem;
                
                img{
                    display: block;
                    width: 90px;
                    height: 100px;
                    object-fit: cover;
                    object-position: center center;filter: none;
                    opacity: 1;
                    aspect-ratio: 977 / 1498;
                    border-radius: 4px;
                }
            }
        }
    }
    
    .article-time{
        display: flex;
        -moz-box-pack: start;
        justify-content: flex-start;
        margin: 0.5rem 0px 0px;
        -moz-box-align: center;
        align-items: center;
        padding: 0px;
        cursor: default !important;
        pointer-events: auto;
        color: rgb(27, 27, 27);
        
        svg{
            flex-shrink: 0;
            width: 16px;
            height: 16px;
            margin-right: 0.3rem;
            transition: fill 0.2s ease-in-out 0s;
            fill: rgb(27, 27, 27);
        }
    
        span{
            font-weight: 400;
            transition: color 0.2s ease-in-out 0s;
            color: rgb(27, 27, 27);
            text-transform: none;
            text-decoration: none;
            text-align: inherit;
            font-style: normal;
            font-size: 14px;
            line-height: 24px;
        }
    }
}

//Explore page and Articles
.explore-grid{
    display: grid;
    max-width: 1140px;
    margin: 2em auto;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
    padding: 0 1em;

    @media only screen and (max-width: 992px){
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 550px){
        grid-template-columns: repeat(1, 1fr);
    }
}

.width-container{
    max-width: 1140px;
    width: 1140px;
    display: flex;
    margin: 0 auto;

    @media only screen and (max-width: 1140px){
        width: 100%;
    }
}

.article-viewer-container{
    margin-top: 40px;
    display: flex;
    width: 100%;
    flex-direction: row;

    @media only screen and (max-width: 1140px){
        flex-direction: column;
    }

    #article-container{
        width: 100%;

        #article-preview{
            padding: 1em 1.2em;
            display: flex;
            flex-direction: column;

            .article-section-title{
                color: gray;
                font-weight: lighter;
                border-bottom: 1px solid #ddd;
                margin-bottom: 1em;
                font-size: 14px;
            }

            .article-header{
                font-size: 24px;
                line-height: 1.8rem;
                margin-bottom: 0.5em;
            }

            .article-author{
                font-style: italic;
                color: #808080;
                font-weight: lighter;
            }

            img{
                margin: 2em auto;
                max-width: 100%;
                max-height: 400px;
            }

            .article-excerpt::after{
                content: "";
                position: relative;
                display: block;
                height: 6rem;
                margin-top: -6rem;
                background: rgba(0, 0, 0, 0) linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 95%) repeat scroll 0% 0%;
            }

            .article-lock-container{
                text-align: center;

                .article-lock-icon{
                    display: flex;
                    -moz-box-pack: center;
                    justify-content: center;
                    -moz-box-align: center;
                    align-items: center;
                    padding: 0px 1rem;
                    cursor: default !important;
                    pointer-events: auto;
                    color: rgb(27, 27, 27);

                    svg{
                        flex-shrink: 0;
                        width: 24px;
                        height: 24px;
                        margin-right: 0px;
                        transition: fill 0.2s ease-in-out 0s;
                        fill: rgb(27, 27, 27);
                    }
                }
            }
        }
    }

    #article-cover-container{
        max-width: 240px;
        padding-bottom: 2em;
        margin-top: 5em;

        @media only screen and (max-width: 1140px){
            margin: 2rem 0px;
            align-self: center;
        }

        .article-cover{
            display: flex;
            flex-direction: column;
            -moz-box-align: center;
            align-items: center;
            position: sticky;
            top: 20%;

            a{
                text-decoration: none;

                img{
                    width: 100%;
                    height: auto;
                    flex-shrink: 0;
                    box-shadow: rgba(27, 27, 27, 0.055) 0px 4px 8px, rgba(27, 27, 27, 0.06) 0px 3px 8px, rgba(27, 27, 27, 0.04) 0px 0px 4px;
                    margin-bottom: 1rem;
                }
            }

            #article-cover-button{
                margin-top: 1rem;
                display: inline-block;
                padding: 0.875rem 1rem;
                font-size: 16px;
                text-transform: uppercase;
                text-align: center;
                text-decoration: none;
                border: 1px solid transparent;
                border-radius: 4px;
                cursor: pointer;
                pointer-events: auto;
                transition: all 0.2s ease-in-out 0s;
                -moz-box-align: center;
                align-items: center;
                -moz-box-pack: center;
                justify-content: center;
                white-space: nowrap;
                flex-shrink: 0;
                min-width: 200px;
                width: auto;
                color: rgb(255, 255, 255);
                background: $primary-color none repeat scroll 0% 0%;
            }
        }
    }
}

//Homepage International Call to Action
.international-highlight{
    overflow-x: scroll;
    display: flex;
    scroll-snap-type: x mandatory;

    .highlight-container{
        min-width: 100%;
        display:flex;
        gap:1em;
        justify-content: center;
        scroll-snap-align: start;
        padding: 1rem 0;

        i{
            color: $primary-color;
        }

        span{
            font-size: 1rem;
            line-height: 1rem;
            letter-spacing: 1px;
            font-weight: 300;
            color: #000;
        }
    }

    @media screen and (min-width: 920px) {
        justify-content: space-between;

        .highlight-container{
            min-width: auto;
        }
    }
}

.bgff {
    &.bgee{
        background-color: #eee !important;
    }
}


.sm:my-8{
    width: 100% * 8/12;
}
.sm:align-middle{
    justify-items: center;
}
.sm:w-full{
    width: 100%;
}
.sm:max-w-md{
    max-width: 800px;
}

.dashboard-stats{
    .dashboard-stats-section-1{
        width: 100%;
    }
    .dashboard-stats-section-2{
        width: 50%;

        &:first-child{
            padding-right: 10px;
        }
        &:last-child{
            padding-right: 10px;
        }
    }
    .dashboard-stats-section-3{
        width: 33.3333%;
        &:first-child{
            padding-right: 10px;
        }
        &:last-child{
            padding-right: 10px;
        }
    }
    .dashboard-stats-section-4{
        width: 25%;
        &:first-child{
            padding-right: 10px;
        }
        &:last-child{
            padding-right: 10px;
        }
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.international-payment-card-text *{
    font-size: 12px !important;
    padding-left: 10px;
    @include MQ(mx-M-C) {
        font-size: 11px !important;
    }
}

.international-package-header {
    font-size: 25px;
    text-shadow: 2px 2px #2b2b2b8c;

    .international-pub-name{
        font-size: 25px;
    }

    @include MQ(mx-M-C) {
        font-size: 12px ;
        span{
            font-size: 12px;
        }
        span.international-pub-name{
            font-size: 20px !important;
        }
    }
}
.issue-img {
    @include MQ(mx-M-C) {
        max-width: 35% !important;
    }
}
.international-package-header-image{
    aspect-ratio: 1/1;
    height:100px;

    @include MQ(mx-M-C){
        width: 40px;
        height:60px;
    }
}
.international-package-table{

    .table td, .table th {
        border: none;
    }
    //@extend .pg10lr;

    @include MQ(mx-M-C) {

        margin-bottom: 0!important;
        padding-left: 3px !important;
        padding-right: 3px !important;

        //*{
        //    font-size: 12px;
        //}

        .custom-control {
            position: relative;
            display: block;
            min-height: 1.5rem;
            //padding-left: 5px;
            margin-left: 7px;
        }
        .table td, .table th {
            padding: 0.3rem;
            font-size: 14px;
        }
        .table th {
            padding: 0.3rem 7px;
            font-size: 11px;
        }
        .upp {
            text-transform: uppercase;
            font-size: 11px;
        }
        .table .mg0b{
            margin-bottom: 0!important;
            font-size: 12px;
        }
    }
}

.custom-control-label{

    @include MQ(mx-M-C) {
        &::before, &::after {
            top: 1px;
        }
    }
}
.width-f-max{
    max-width: 100%;
}

.issue-img {
    @include MQ(mx-M-C) {
        max-width: 35% !important;
    }
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
    left: -20px;
}
.custom-radio .custom-control-input:checked~.custom-control-label::after {
    left: -20px;
}
.custom-radio .custom-control-label::before {
    left: -20px;
}


//preston carousel
.hero{
    //background-image: url(https://img.freepik.com/free-psd/papercut-white-world-map_125540-2390.jpg?w=1800&t=st=1686747532~exp=1686748132~hmac=5e5fa702c713703447ef18c09f10a92ced88e985af82b9a5f9a6b47a7a6818c3);
    //background-size: cover;
    //background-position: center;

}
.owl-carousel, .pods .card, .pods .card img, .hero, .bg-primary-1{
    height: 320px;
    object-fit: cover;
}

.bg-primary-1 {
    --bs-bg-opacity: 1;
    background-color: #148bd0dd!important;
}
.image-row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 900px;
}

.pods .card{
    background-image: url('images/blob.png');
    background-size: cover;
}

.image-row a {
    flex: 0 0 auto;
    width: 120px !important; /* Changed width to max-width */
    height: auto;
    box-shadow: 2px 6px 14px rgb(0, 0, 0);
    aspect-ratio: 5/7;

    &.square {
        aspect-ratio: 1/1;
        
    }
}

.image-row a:first-child,
.image-row a:last-child {
    flex: 0 0 15%;
}

.image-row a:nth-child(2),
.image-row a:nth-child(4) {
    flex: 0 0 20%;
}

.image-row a:nth-child(3) {
    flex: 0 0 23%;
}
.play{
    border-radius: 50%;
    font-size: 15px;
    padding: 7px 8px 7px 10px;
    margin-top: -12px;
}
.owl-carousel .pod-img{
    object-fit: scale-down;
    aspect-ratio: 1/1;
    align-items: self-start
}
.scroll{
    width: 100%;
    overflow-x: scroll;
    display: flex;
}
.scroll-item img{
    width: 168px !important;
}
.hero img{
    aspect-ratio: 5/7;
}
.scroll-item{
    margin-right: 10px;
}
.rounded-full {
    border-radius: 9999px;
    position: absolute;
    right: 5px;
    height: 25px;
    width: 25px;
    text-align: center;
}
.rounded-full svg
{
    margin-top: -1px;
}
.rad{
    border-radius: 0 0 0 5px;
    right: 0;
}
.radios .scroll-item img {

    height: 168px;
    object-fit: cover;
}
.rounded-full .icon{
    width: 15px !important;
    height: 15px !important;
}
.mg24b{
    margin-bottom: 25px;
}
@media (max-width: 768px) {
    .image-row a,
    .image-row a:nth-child(4) {
        //display: none;
    }
    .image-row a:nth-child(1),
    .image-row a:nth-child(2),
    .image-row a:nth-child(4),
    .image-row a:nth-child(5) {
        display: none;
    }
    .image-row a:nth-child(3) {
        flex: 0 0 60%;
    }
    .image-row a{
        height: 140px;
    }
    h1{
        font-size: 1.5rem;
    }
    .mg24b{
        margin-bottom: 12px;
    }
    .col-4,
    .col-8{
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
    .owl-carousel, .pods .card, .pods .card img, .hero, .bg-primary-1{
        height: 260px;
    }
    .rounded-full .icon{
        width: 20px !important;
        height: 20px !important;
    }

}

.hero.item {
    .card {
        flex-direction: row;
    }
}

.menu-small-scroll{
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
      }

    ul {
        min-width: 0;
    }
}

.dropdown-menu-right {
    left : -171px;
    &.is-on{
        left: -148px;

        @media screen and (max-width: 768px) {
            left : -171px;
        }
    }
}

.profile-dropdown {
    a {
        padding: .5rem 1.5rem;
        font-size: 0.875rem !important;
        font-weight: bold;

        &:hover{
            background-color: #3b83f6;
            color: #fff;
            border-radius: 0.5rem;
        }
    }

    .divide{
        padding: 0;
        border: $border-color solid .5px;
    }
}

/*! ========================================================================
 * Bootstrap Toggle: bootstrap-toggle.css v2.2.0
 * http://www.bootstraptoggle.com
 * ========================================================================
 * Copyright 2014 Min Hur, The New York Times Company
 * Licensed under MIT
 * ======================================================================== */

.checkbox label .toggle,
.checkbox-inline .toggle {
    margin-left: -20px;
    margin-right: 5px
}

.toggle {
    position: relative;
    overflow: hidden
}

.toggle input[type=checkbox] {
    display: none
}

.toggle-group {
    position: absolute;
    width: 200%;
    top: 0;
    bottom: 0;
    left: 0;
    transition: left .35s;
    -webkit-transition: left .35s;
    -moz-user-select: none;
    -webkit-user-select: none
}

.toggle.off .toggle-group {
    left: -100%
}

.toggle-on {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
    margin: 0;
    border: 0;
    border-radius: 0
}

.toggle-off {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    background: #ddd;
}

.toggle-handle {
    position: relative;
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;
    width: 0;
    border-width: 0 1px
}

.toggle.btn {
    width: 100% !important;
    min-width: 59px;
    min-height: 34px
}

.toggle-on.btn {
    padding-right: 24px
}

.toggle-off.btn {
    padding-left: 24px
}

.toggle.btn-lg {
    min-width: 79px;
    min-height: 45px
}

.toggle-on.btn-lg {
    padding-right: 31px
}

.toggle-off.btn-lg {
    padding-left: 31px
}

.toggle-handle.btn-lg {
    width: 40px
}

.toggle.btn-sm {
    min-width: 50px;
    min-height: 30px
}

.toggle-on.btn-sm {
    padding-right: 20px
}

.toggle-off.btn-sm {
    padding-left: 20px
}

.toggle.btn-xs {
    min-width: 35px;
    min-height: 22px
}

.toggle-on.btn-xs {
    padding-right: 12px
}

.toggle-off.btn-xs {
    padding-left: 12px
}


.publications_load_all{
    width: 100%;

    &>a{
        width: 20%;
        height: 130px;
        float: left;
        padding: 0px;
        overflow: hidden;
        border: 2px solid transparent;
        max-width: 87px;

        @include MQ(mx-M-C) {
            width: 33%;
            max-width: none;
        }

        &:hover{
            background: $main1;
            border: 2px solid $main1;
        }
    }
}




// for books only
.inner-book {
    width: 100%;
}

.book {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateZ(0);
}

.inner-book {
    display: flex;
    align-items: center;
    transform-style: preserve-3d;
    perspective: 2000px;
}

.inner-book {
    display: flex;
    align-items: center;
    transform-style: preserve-3d;
    perspective: 2000px;
}

.inner-book:before {
    content: "";
    width: 100%;
    position: absolute;
    height: 0;
    bottom: 14px;
    transform: rotateY(-20deg) translateZ(-20px);
    z-index: 0;
    box-shadow: 3px 5px 20px 9px rgba(0, 0, 0, 0.3);
}

.inner-book .img {
    transform: rotateY(-20deg);
    width: 100%;
    z-index: 5;
    clear: both;
    height: 100%;
    background: #ddd;
    margin-left: -8px;
}

.inner-book img {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
}

.inner-book .img:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-left: 4px solid rgba(0, 0, 0, 0.3);
    z-index: 2;
}

.page {
    width: calc(100% - 6px);
    height: calc(100% - 2px);
    position: absolute;
    box-shadow: inset 0px -1px 2px rgba(50, 50, 50, 0.2),
    inset -1px 0px 1px rgba(150, 150, 150, 0.1);
    border-radius: 0px 3px 3px 0px;
    transform: rotateY(-20deg) translateZ(-5px);
    //right: -3px;
    left: 0px;
    z-index: 4;
    background: white;
}

.page-2 {
    width: calc(100% - 3px);
    height: calc(100% - 4px);
    //right: -6px;
    z-index: 3;
}

.page-3 {
    width: calc(100% + 0px);
    height: calc(100% - 6px);
    //right: -9px;
    z-index: 2;
}

//.page-4 {
//    width: calc(100% + 8px);
//    height: calc(100% - 8px);
//    //right: -12px;
//    z-index: 1;
//}
//
//.page-5 {
//    width: calc(100% + 10px);
//    height: calc(100% - 10px);
//    //right: -15px;
//    z-index: 0;
//}

.img.final-page {
    position: absolute;
    z-index: -1;
    //right: -11px;
    width: calc(100% + 10px);
    transform: rotateY(-19deg) translateZ(-10px) scale(0.984);
}
.mt-0.width-f.pl{
    border-top: 1px solid rgb(0 0 0 / 70%);
}
@media (max-width:456px) {
    .css-1s802wv, .css-162lbvj, .css-1ofj64s {
        font-size: 12px !important;
        font-weight: 500;
    }
    .btm-100{
        width: 100%;
    }
    .logo-img {
        height: 30px;
    }
    .css-1g8ewsb-Button {
        line-height: 1;
    }
    .css-11g480x-InputBox {
        height: 2.5rem !important;
    }
    .css-1g8ewsb-Button {
        line-height: 17px;
        font-size: 13px;
    }
    .container-fluid.width-f.pl.pg20tb.home-load-div.px-0,
    .bgff.body-m{
        margin-top: 20px;
    }
}
.text-small{
    font-size: 13px;
    font-weight: 500;
}
.international-package-table * {
    align-items: center;
}
.det .custom-control-label::before,
.det .custom-control-label::after {
    top: -.2rem !important;
}
//@media (min-width: 576px) {
//    .flex-xs-row {
//        -ms-flex-direction: row !important;
//        flex-direction: row !important;
//    }
//    .flex-xs-column {
//        -ms-flex-direction: column !important;
//        flex-direction: column !important;
//    }
//}
@media screen and (min-width: 576px){
    .c-40{
        width: 40% !important;
    }
    .c-60{
        width: 60% !important;
    }
    .d-m-flex{
        display: flex !important;
    }
    .issue-img {
        max-width: 91% !important;
    }
}
@media (min-width:400px) and (max-width: 576px){

    .c-40{
        margin: auto;
    }
    .c-60{
        max-width: 390px !important;
        margin: auto;
    }
}

@media (min-width: 576px) and (max-width: 768px){
    .flex-sm-column{
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }
}
@media (min-width: 502px) and (max-width: 992px){
    .btm-100{
        width: 100%
    }
    .text-small {
        font-size: 13px;
        font-weight: 500;
        text-align: left !important;
    }
}

@media (min-width:992px) {
    .text-small {
        font-size: 13px;
        font-weight: 500;
        text-align: left;
    }
}

.titling,
.titling b {
    font-size: 18px;
}

@media (min-width:992px) {
    .text-small {
        text-align: left !important;
    }
}


.max-w-700{
    max-width: 700px !important;
}


.new-carousel{
    .carousel {
        position:relative
    }
    .carousel.pointer-event {
        -ms-touch-action:pan-y;
        touch-action:pan-y
    }
    .carousel-inner {
        position:relative;
        width:100%;
        overflow:hidden
    }
    .carousel-inner:after {
        display:block;
        clear:both;
        content:""
    }
    .carousel-item {
        position:relative;
        display:none;
        float:left;
        width:100%;
        margin-right:-100%;
        -webkit-backface-visibility:hidden;
        backface-visibility:hidden;
        transition:-webkit-transform .6s ease-in-out;
        transition:transform .6s ease-in-out;
        transition:transform .6s ease-in-out,-webkit-transform .6s ease-in-out
    }
    @media screen and (prefers-reduced-motion:reduce) {
        .carousel-item {
            transition:none
        }
    }
    .carousel-item-next,
    .carousel-item-prev,
    .carousel-item.active {
        display:block
    }
    .active.carousel-item-right,
    .carousel-item-next:not(.carousel-item-left) {
        -webkit-transform:translateX(100%);
        transform:translateX(100%)
    }
    .active.carousel-item-left,
    .carousel-item-prev:not(.carousel-item-right) {
        -webkit-transform:translateX(-100%);
        transform:translateX(-100%)
    }
    .carousel-fade .carousel-item {
        opacity:0;
        transition-property:opacity;
        -webkit-transform:none;
        transform:none
    }
    .carousel-fade .carousel-item-next.carousel-item-left,
    .carousel-fade .carousel-item-prev.carousel-item-right,
    .carousel-fade .carousel-item.active {
        z-index:1;
        opacity:1
    }
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        z-index:0;
        opacity:0;
        transition:opacity 0s .6s
    }
    @media screen and (prefers-reduced-motion:reduce) {
        .carousel-fade .active.carousel-item-left,
        .carousel-fade .active.carousel-item-right {
            transition:none
        }
    }
    .carousel-control-next,
    .carousel-control-prev {
        position:absolute;
        top:0;
        bottom:0;
        z-index:1;
        display:-ms-flexbox;
        display:flex;
        -ms-flex-align:center;
        align-items:center;
        -ms-flex-pack:center;
        justify-content:center;
        width:15%;
        color:#fff;
        text-align:center;
        opacity:.5;
        transition:opacity .15s ease
    }
    @media screen and (prefers-reduced-motion:reduce) {
        .carousel-control-next,
        .carousel-control-prev {
            transition:none
        }
    }
    .carousel-control-next:focus,
    .carousel-control-next:hover,
    .carousel-control-prev:focus,
    .carousel-control-prev:hover {
        color:#fff;
        text-decoration:none;
        outline:0;
        opacity:.9
    }
    .carousel-control-prev {
        left:0
    }
    .carousel-control-next {
        right:0
    }
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        display:inline-block;
        width:20px;
        height:20px;
        background:transparent no-repeat 50%;
        background-size:100% 100%
    }
    .carousel-control-prev-icon {
        background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3E%3C/svg%3E")
    }
    .carousel-control-next-icon {
        background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3E%3C/svg%3E")
    }
    .carousel-indicators {
        position:absolute;
        right:0;
        bottom:0;
        left:0;
        z-index:15;
        display:-ms-flexbox;
        display:flex;
        -ms-flex-pack:center;
        justify-content:center;
        padding-left:0;
        margin-right:15%;
        margin-left:15%;
        list-style:none
    }
    .carousel-indicators li {
        box-sizing:content-box;
        -ms-flex:0 1 auto;
        flex:0 1 auto;
        width:30px;
        height:3px;
        margin-right:3px;
        margin-left:3px;
        text-indent:-999px;
        cursor:pointer;
        background-color:#fff;
        background-clip:padding-box;
        border-top:10px solid transparent;
        border-bottom:10px solid transparent;
        opacity:.5;
        transition:opacity .6s ease
    }

    .carousel-indicators button{
        opacity: 1;
        background: #d9d9d9;
        border: none;
        margin-right: 6px;
        width: 33px;
        padding: 3px;
        border-radius: 8px;
    }
    .carousel-indicators .active {
        background: #1475bc;
    }

    @media screen and (prefers-reduced-motion:reduce) {
        .carousel-indicators li {
            transition:none
        }
    }
    .carousel-indicators .active {
        opacity:1
    }
    .carousel-caption {
        position:absolute;
        right:15%;
        bottom:20px;
        left:15%;
        z-index:10;
        padding-top:20px;
        padding-bottom:20px;
        color:#fff;
        text-align:center
    }
    @-webkit-keyframes spinner-border {
        to {
            -webkit-transform:rotate(1turn);
            transform:rotate(1turn)
        }
    }
    @keyframes spinner-border {
        to {
            -webkit-transform:rotate(1turn);
            transform:rotate(1turn)
        }
    }
}



.simplebar-scroll-content {
    overflow-x:hidden!important;
    overflow-y:scroll;
    min-width:100%!important;
    max-height:inherit!important;
    box-sizing:content-box!important
}
.simplebar-content {
    overflow-y:hidden!important;
    overflow-x:scroll;
    box-sizing:border-box!important;
    min-height:100%!important
}
.simplebar-track {
    z-index:1;
    position:absolute;
    right:0;
    bottom:0;
    width:11px
}
.simplebar-scrollbar {
    position:absolute;
    right:2px;
    width:7px;
    min-height:10px
}
.simplebar-scrollbar:before {
    position:absolute;
    content:"";
    background:#000;
    border-radius:7px;
    left:0;
    right:0;
    opacity:0;
    transition:opacity .2s linear
}
.simplebar-track .simplebar-scrollbar.visible:before,
.simplebar-track:hover .simplebar-scrollbar:before {
    opacity:.5;
    transition:opacity 0 linear
}
.simplebar-track.vertical {
    top:0
}
.simplebar-track.vertical .simplebar-scrollbar:before {
    top:2px;
    bottom:2px
}
.simplebar-track.horizontal {
    left:0;
    width:auto;
    height:11px
}
.simplebar-track.horizontal .simplebar-scrollbar:before {
    height:100%;
    left:2px;
    right:2px
}
.horizontal.simplebar-track .simplebar-scrollbar {
    right:auto;
    top:2px;
    height:7px;
    min-height:0;
    min-width:10px;
    width:auto
}
.sidebar {
    min-width:255px;
    max-width:255px;
    border-right:1px solid #e5e9f2
}
.sidebar,
.sidebar-content {
    transition:all .4s ease-in-out;
    background:#fff
}
.sidebar-sticky .sidebar-content {
    border-right:1px solid #e5e9f2
}
.sidebar-sticky .sidebar-nav {
    padding-bottom:0
}
.sidebar-sticky .sidebar-content {
    height:100vh;
    position:fixed;
    top:0;
    left:0;
    width:255px
}
.sidebar-nav {
    padding-left:0;
    list-style:none
}
.sidebar-link .feather,
.sidebar-link svg {
    margin-right:.75rem;
    color:#adb5bd
}
.sidebar-link,
a.sidebar-link {
    display:block;
    padding:.75rem 1.25rem;
    color:#212529;
    background:#fff;
    transition:background .1s ease-in-out,color .1s ease-in-out;
    position:relative;
    text-decoration:none
}
.sidebar-link:focus {
    outline:0
}
.sidebar-item.active .sidebar-link:hover,
.sidebar-link:hover {
    color:#495057;
    background:#f5f5f5
}
.sidebar-dropdown .sidebar-link {
    padding-left:2.5rem;
    color:#495057;
    background:#fafafa
}
.sidebar-dropdown .sidebar-item.active .sidebar-link,
.sidebar-dropdown .sidebar-link:hover {
    color:#212529;
    background:#f5f5f5
}
.sidebar [data-toggle=collapse] {
    position:relative
}
.sidebar [data-toggle=collapse]:before {
    content:" ";
    border:solid;
    border-width:0 .1rem .1rem 0;
    display:inline-block;
    padding:2px;
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg);
    position:absolute;
    top:1.2rem;
    right:1.25rem;
    transition:all .2s ease-out
}
.sidebar [aria-expanded=true]:before,
.sidebar [data-toggle=collapse]:not(.collapsed):before {
    -webkit-transform:rotate(-135deg);
    transform:rotate(-135deg);
    top:1.4rem
}
.sidebar-brand {
    font-weight:500;
    font-size:1.15rem;
    padding:1.15rem 1.25rem;
    color:#343a40;
    display:block
}
.sidebar-brand:hover {
    text-decoration:none;
    color:#343a40
}
.sidebar-brand:focus {
    outline:0
}
.sidebar-brand .feather,
.sidebar-brand svg {
    color:#1475bc;
    height:24px;
    width:24px;
    margin-left:-.15rem;
    margin-right:.375rem
}
.sidebar-toggle {
    cursor:pointer
}
.sidebar-toggle,
.sidebar-toggle .feather {
    width:26px;
    height:26px
}
.sidebar-toggle .feather:hover {
    color:#1475bc
}
.sidebar.toggled {
    margin-left:-255px
}
.sidebar.toggled .sidebar-content {
    left:-255px
}
@media (min-width:1px) and (max-width:991.98px) {
    .sidebar {
        margin-left:-255px
    }
    .sidebar .sidebar-content {
        left:-255px
    }
    .sidebar.toggled {
        margin-left:0
    }
    .sidebar.toggled .sidebar-content {
        left:0
    }
    .sidebar-collapsed {
        margin-left:0
    }
    .sidebar-collapsed .sidebar-content {
        left:0
    }
    .sidebar-collapsed.toggled {
        margin-left:-255px
    }
    .sidebar-collapsed.toggled .sidebar-content {
        left:-255px
    }
}
.sidebar-header {
    background:transparent;
    color:#adb5bd;
    padding:.375rem 1.25rem;
    font-size:.75rem;
    text-transform:uppercase
}
.sidebar-badge {
    position:absolute;
    right:15px;
    top:14px
}
.sidebar-cta {
    text-align:center;
    margin:1rem;
    background:#fcfcfc;
    border:1px solid #e9ecef;
    position:relative
}
.sidebar-cta-close {
    position:absolute;
    top:6px;
    right:8px
}
.sidebar-cta-content {
    padding:1.5rem 1rem
}

a{
    color: #1070b7;
}
.btn-primary{
    background-color: #1070b7;
    border-color: #1070b7;
}
.category-list-h{
    max-height: calc(100vh - 50px);
    overflow-y: auto;
}
