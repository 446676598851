.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4,0,0.2,1);
    transition-duration: 150ms;
}
.transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}


.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.fixed {
    position: fixed;
}

.text-center {
    text-align: center;
}
.absolute {
    position: absolute;
}

.hidden {
    display: none;
}

.overflow-y-auto {
    overflow-y: auto;
}
.z-10 {
    z-index: 10;
}

.pb-10 {
    padding-bottom: 2.5rem;
}
.pt-4 {
    padding-top: 1rem;
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
// .flex-row {
//     flex-direction: row;
// }
.min-h-screen {
    min-height: 100vh;
}

.opacity-75 {
    opacity: .75;
}
.bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107,114,128,var(--tw-bg-opacity));
}

@media (min-width: 640px){
    .sm\:p-0 {
        padding: 0;
    }
    .sm\:align-middle {
        vertical-align: middle;
    }
    .sm\:h-screen {
        height: 100vh;
    }
    .sm\:inline-block {
        display: inline-block;
    }
    .sm\:max-w-7xl {
         max-width: 80rem;
     }
    .sm\:w-full {
          width: 100%;
      }
    .sm\:my-8 {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}
