/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 16, 2015, 4:59:26 PM
    Author     : TI
*/

// include fonts

@font-face {
    font-family: 'Gotham Rounded';
    src: url('/fonts/GothamRounded-Book.woff') format('woff'),
    url('/fonts/GothamRounded-Book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('/fonts/GothamRounded-Medium.woff') format('woff'),
    url('/fonts/GothamRounded-Medium.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('/fonts/GothamRounded-Light.woff') format('woff'),
    url('/fonts/GothamRounded-Light.woff2') format('woff2');
    font-weight: lighter;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'rounded_mplus_light';
    src: url('/fonts/RoundedMplus1c-Light.woff2') format('woff2'),
    url('/fonts/RoundedMplus1c-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'rounded_mplus_thin';
    src: url('/fonts/RoundedMplus1c-Thin.woff2') format('woff2'),
    url('/fonts/RoundedMplus1c-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}




//@font-face {
//    font-family: 'Montserrat';
//    src: local('sans-serif'),
//    url('../web-fonts/Montserrat-Black.ttf') format('truetype');
//    font-weight: 900;
//    font-style: normal;
//    font-display: swap;
//}
//@font-face {
//    font-family: 'Montserrat';
//    src: local('sans-serif'),
//    url('../web-fonts/Montserrat-BlackItalic.ttf') format('truetype');
//    font-weight: 900;
//    font-style: italic;
//    font-display: swap;
//}
//@font-face {
//    font-family: 'Montserrat';
//    src: local('sans-serif'),
//    url('../web-fonts/Montserrat-Bold.ttf') format('truetype');
//    font-weight: 700;
//    font-style: normal;
//    font-display: swap;
//}
//@font-face {
//    font-family: 'Montserrat';
//    src: local('sans-serif'),
//    url('../web-fonts/Montserrat-BoldItalic.ttf') format('truetype');
//    font-weight: 700;
//    font-style: italic;
//    font-display: swap;
//}
//@font-face {
//    font-family: 'Montserrat';
//    src: local('sans-serif'),
//    url('../web-fonts/Montserrat-ExtraBold.ttf') format('truetype');
//    font-weight: 800;
//    font-style: normal;
//    font-display: swap;
//}
//@font-face {
//    font-family: 'Montserrat';
//    src: local('sans-serif'),
//    url('../web-fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
//    font-weight: 800;
//    font-style: italic;
//    font-display: swap;
//}
//@font-face {
//    font-family: 'Montserrat';
//    src: local('sans-serif'),
//    url('../web-fonts/Montserrat-ExtraLight.ttf') format('truetype');
//    font-weight: 200;
//    font-style: normal;
//    font-display: swap;
//}
//@font-face {
//    font-family: 'Montserrat';
//    src: local('sans-serif'),
//    url('../web-fonts/Montserrat-ExtraLightItalic.ttf') format('truetype');
//    font-weight: 200;
//    font-style: italic;
//    font-display: swap;
//}
//@font-face {
//    font-family: 'Montserrat';
//    src: local('sans-serif'),
//    url('../web-fonts/Montserrat-Italic.ttf') format('truetype');
//    font-weight: 400;
//    font-style: italic;
//    font-display: swap;
//}
//@font-face {
//    font-family: 'Montserrat';
//    src: local('sans-serif'),
//    url('../web-fonts/Montserrat-Light.ttf') format('truetype');
//    font-weight: 300;
//    font-style: normal;
//    font-display: swap;
//}
//@font-face {
//    font-family: 'Montserrat';
//    src: local('sans-serif'),
//    url('../web-fonts/Montserrat-LightItalic.ttf') format('truetype');
//    font-weight: 300;
//    font-style: italic;
//    font-display: swap;
//}
//@font-face {
//    font-family: 'Montserrat';
//    src: local('sans-serif'),
//    url('../web-fonts/Montserrat-Medium.ttf') format('truetype');
//    font-weight: 500;
//    font-style: normal;
//    font-display: swap;
//}
//@font-face {
//    font-family: 'Montserrat';
//    src: local('sans-serif'),
//    url('../web-fonts/Montserrat-MediumItalic.ttf') format('truetype');
//    font-weight: 500;
//    font-style: italic;
//    font-display: swap;
//}
//@font-face {
//    font-family: 'Montserrat';
//    src: local('sans-serif'),
//    url('../web-fonts/Montserrat-Regular.ttf') format('truetype');
//    font-weight: 400;
//    font-style: normal;
//    font-display: swap;
//}
//@font-face {
//    font-family: 'Montserrat';
//    src: local('sans-serif'),
//    url('../web-fonts/Montserrat-SemiBold.ttf') format('truetype');
//    font-weight: 600;
//    font-style: normal;
//    font-display: swap;
//}
//@font-face {
//    font-family: 'Montserrat';
//    src: local('sans-serif'),
//    url('../web-fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
//    font-weight: 600;
//    font-style: italic;
//    font-display: swap;
//}
//@font-face {
//    font-family: 'Montserrat';
//    src: local('sans-serif'),
//    url('../web-fonts/Montserrat-Thin.ttf') format('truetype');
//    font-weight: 100;
//    font-style: normal;
//    font-display: swap;
//}
//@font-face {
//    font-family: 'Montserrat';
//    src: local('sans-serif'),
//    url('../web-fonts/Montserrat-ThinItalic.ttf') format('truetype');
//    font-weight: 100;
//    font-style: italic;
//    font-display: swap;
//}


@font-face {
    font-family: 'Montserrat';
    src: url('../web-fonts/Montserrat-BlackItalic.woff2') format('woff2'),
    url('../web-fonts/Montserrat-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../web-fonts/Montserrat-Black.woff2') format('woff2'),
    url('../web-fonts/Montserrat-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../web-fonts/Montserrat-Bold.woff2') format('woff2'),
    url('../web-fonts/Montserrat-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../web-fonts/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
    url('../web-fonts/Montserrat-ExtraBoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../web-fonts/Montserrat-BoldItalic.woff2') format('woff2'),
    url('../web-fonts/Montserrat-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../web-fonts/Montserrat-ExtraBold.woff2') format('woff2'),
    url('../web-fonts/Montserrat-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../web-fonts/Montserrat-ExtraLight.woff2') format('woff2'),
    url('../web-fonts/Montserrat-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../web-fonts/Montserrat-Italic.woff2') format('woff2'),
    url('../web-fonts/Montserrat-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../web-fonts/Montserrat-ExtraLightItalic.woff2') format('woff2'),
    url('../web-fonts/Montserrat-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../web-fonts/Montserrat-Medium.woff2') format('woff2'),
    url('../web-fonts/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../web-fonts/Montserrat-LightItalic.woff2') format('woff2'),
    url('../web-fonts/Montserrat-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../web-fonts/Montserrat-Light.woff2') format('woff2'),
    url('../web-fonts/Montserrat-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../web-fonts/Montserrat-MediumItalic.woff2') format('woff2'),
    url('../web-fonts/Montserrat-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../web-fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('../web-fonts/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../web-fonts/Montserrat-Regular.woff2') format('woff2'),
    url('../web-fonts/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../web-fonts/Montserrat-Thin.woff2') format('woff2'),
    url('../web-fonts/Montserrat-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../web-fonts/Montserrat-ThinItalic.woff2') format('woff2'),
    url('../web-fonts/Montserrat-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../web-fonts/Montserrat-SemiBoldItalic.woff2') format('woff2'),
    url('../web-fonts/Montserrat-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

html,
body{
    //font-family: 'Montserrat', sans-serif !important;
}

h1, h2, h3, h4, h5, h6, a, ul, ol, table, table * {
    font-weight: normal;
    font-style: normal;
    text-rendering: optimizeLegibility;
    //font-family: 'Gotham Rounded', sans-serif;
    //font-family: 'Montserrat', sans-serif;
}

.btn{
    //font-family: 'Gotham Rounded', sans-serif !important;
    //font-family: 'Montserrat', sans-serif !important;
    font-size: 13px;
}

$h1 : 2.0rem;
h1{
    font-size: $h1;
    @include MQ(mx-M-C){
        font-size: $h1*80/100;
    }
}

$h2 : 1.5rem;
h2{
    font-size: $h2;
    @include MQ(mx-M-C){
        font-size: $h2*80/100;
    }
}

$h3 : 1.25rem;
h3{
    font-size: $h3;
    @include MQ(mx-M-C){
        font-size: $h3*80/100;
    }
}

$h4 : 1.15rem;
h4{
    font-size: $h4;
    @include MQ(mx-M-C){
        font-size: $h4*80/100;
    }
}

$h5 : 1.05rem;
h5{
    font-size: $h5;
    @include MQ(mx-M-C){
        font-size: $h5*80/100;
    }
}

$h6 : 1rem;
h6{
    font-size: $h6;
    @include MQ(mx-M-C){
        font-size: $h6*80/100;
    }
}

p{

}
img{
    object-fit: cover;
}

.norm{
    font-weight: normal !important;
}
.semi-bold{
    font-weight: bold !important;
}
.pro-bold{
    font-weight: bold !important;
}
.light{
    font-weight: lighter !important;
}
.thin{
    font-weight: lighter !important;
}

a, ul, ol, span, p {
    //font-weight: lighter;
    font-size: 13px;
}

p {
    //font-family: 'Segoe UI", Arial, sans-serif';
}

%width-custom1{
  width:100% !important;
  float: left;
}

%width-custom2{
  width:auto;
  overflow: hidden;
}

// colors

//$main1:     rgba(darken(#4F86C6, 0%),1); // main site color 1
//$main2:     rgba(darken(#4FB0C6, 0%),1); // main site color 2
//$main3:     rgba(darken(#379392, 0%),1); // main site color 3
//$main4:     rgba(darken(#6C49B8, 0%),1); // main site color 4
//$main5:     rgba(darken(#bd1550, 0%),1); // main site color 5

$main1:     rgba(darken(#0F70B7, 0%),1); // main site color 1
$main2:     rgba(darken(#272727, 0%),1); // main site color 2
$main3:     rgba(darken(#0F70B7, 0%),1); // main site color 3
$main4:     rgba(darken(#D8EA5B, 0%),1); // main site color 4
$main5:     rgba(darken(#A1BB5C, 0%),1); // main site color 5



$main6:     rgba(darken(#0F70B7, 0%),1); // main site color 1
$main7:     rgba(darken(#466777, 0%),1); // main site color 3
$main8:     rgba(darken(#272727, 0%),1); // main site color 2
$main9:     rgba(darken(#D8EA5B, 0%),1); // main site color 4
$main10:     rgba(darken(#A1BB5C, 0%),1); // main site color 5

$teal:      darken(teal,0%);    // Teal default color blue - green
$link:      darken(#2ba6cb,0%); // Blue default color Link
$succes:    darken(#5da423,0%); // green default color success
$alert:     darken(#fc7171,10%); //red   default color alert


// angular rating star colors
$angular-rating-star-color1 :lighten(#233D7A, 10%);
$angular-rating-star-color2 :lighten(#0197A1, 10%);
$angular-rating-star-color3 :lighten(#D32F2F, 10%);
$angular-rating-star-color4 :lighten(#076779, 10%);
$angular-rating-star-color5 :lighten(#0B6D03, 10%);

// vertical rhythm

$base-font-size : 16px;
$base-line-height: 12px;

.bg-cr1{
  background: $main1 !important;
}
.cr-cr1{
  color:$main1 !important;
}
.bg-cr2
{
  background: $main2 !important;
}
.cr-cr2{
  color:$main2 !important;
}
.bg-cr3{
  background: $main3 !important;
}
.cr-cr3{
  color:$main3 !important;
}
.bg-cr4
{
  background: $main4 !important;
}
.cr-cr4{
  color:$main4 !important;
}


.bg-cr5
{
  background: $main5 !important;
}
.cr-cr5{
  color:$main5 !important;
}

.bg-crb
{
  background: #000000 !important;
}
.cr-crb{
  color:#000000 !important;
}

// common colors colors
.bg-crl{
  background: $link;
}
.cr-crl{
  color:$link;
}
.bg-crt{
  background: $teal;
}
.cr-crt{
  color:$teal;
}
.bg-crs{
  background: $succes;
}
.cr-crs{
  color:$succes;
}
.bg-cra{
  background: $alert;
}
.cr-cra{
  color:$alert;
}


//border-colors
.borc-cr1{
  border-color:$main1 !important;
}
.borc-cr2{
  border-color:$main2 !important;
}
.borc-cr3{
  border-color:$main3 !important;
}
.borc-cr4{
  border-color:$main4 !important;
}
.borc-cr5{
  border-color:$main5 !important;
}

// other shades of main color

.bg-main-1{
  background: darken($main1, 0%);
}
.bg-main-2{
  background: lighten($main1, 10%);
}
.bg-main-3{
  background: lighten($main1, 5%);
}

// opacity backgrounds for main colors
.bg-main-1-00v6{
  background: rgba(darken($main1, 0%), 0.6);
}
.bg-main-1-00v7{
  background: rgba(darken($main1, 0%), 0.7);
}
.bg-main-1-00v8{
  background: rgba(darken($main1, 0%), 0.8);
}
// Z-levels

.z-depth-1{
  @include box-shadow-1(1px 3px 10px 0 rgba(0, 0, 0, 0.1));
  //@include box-shadow-2(0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12));
}
.z-depth-1-half{
  @include box-shadow-2(0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15));
}
.z-depth-2{
  @include box-shadow-2(0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19));
}
.z-depth-3{
  @include box-shadow-2(0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19));
}
.z-depth-4{
  @include box-shadow-2(0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21));
}
.z-depth-5{
  @include box-shadow-2(0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22));
}

.bgfa{
    background: #fafafa !important;
}

// Dividers

.divider {
  height: 1px;
  overflow: hidden;
  background-color: #ccc;
}

@-webkit-keyframes cro-scale {
  0% { -webkit-transform: scale(1); }
  1% { -webkit-transform: scale(1.2); }
  3% { -webkit-transform: scale(0.8); }
  5% { -webkit-transform: scale(1.1); }
  7% { -webkit-transform: scale(0.9); }
  8% { -webkit-transform: scale(1.05); }
  10% { -webkit-transform: scale(1); }
  100% { -webkit-transform: scale(1); }
}
@-moz-keyframes cro-scale {
  0% { -moz-transform: scale(1); }
  1% { -moz-transform: scale(1.2); }
  3% { -moz-transform: scale(0.8); }
  5% { -moz-transform: scale(1.1); }
  7% { -moz-transform: scale(0.9); }
  8% { -moz-transform: scale(1.05); }
  10% { -moz-transform: scale(1); }
  100% { -moz-transform: scale(1); }
}
@keyframes cro-scale {
  0% { transform: scale(1); }
  1% { transform: scale(1.2); }
  3% { transform: scale(0.8); }
  5% { transform: scale(1.1); }
  7% { transform: scale(0.9); }
  8% { transform: scale(1.05); }
  10% { transform: scale(1); }
  100% { transform: scale(1); }
}
.cro-scale {
  -webkit-animation: cro-scale ease-in infinite;
  -moz-animation: cro-scale ease-in infinite;
  animation: cro-scale ease-in infinite;

  -webkit-animation-duration:10s;
  -moz-animation-duration:10s;
  animation-duration:10s;
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-square-loader,
.la-square-loader > div {
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.la-square-loader {
    display: block;
    font-size: 0;
    color: #fff;
}
.la-square-loader.la-dark {
    color: #333;
}
.la-square-loader > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
}
.la-square-loader {
    width: 32px;
    height: 32px;
}
.la-square-loader > div {
    width: 100%;
    height: 100%;
    background: transparent;
    border-width: 2px;
    border-radius: 0;
    -webkit-animation: square-loader 2s infinite ease;
    -moz-animation: square-loader 2s infinite ease;
    -o-animation: square-loader 2s infinite ease;
    animation: square-loader 2s infinite ease;
}
.la-square-loader > div:after {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    content: "";
    background-color: currentColor;
    -webkit-animation: square-loader-inner 2s infinite ease-in;
    -moz-animation: square-loader-inner 2s infinite ease-in;
    -o-animation: square-loader-inner 2s infinite ease-in;
    animation: square-loader-inner 2s infinite ease-in;
}
.la-square-loader.la-sm {
    width: 16px;
    height: 16px;
}
.la-square-loader.la-sm > div {
    border-width: 1px;
}
.la-square-loader.la-2x {
    width: 64px;
    height: 64px;
}
.la-square-loader.la-2x > div {
    border-width: 4px;
}
.la-square-loader.la-3x {
    width: 96px;
    height: 96px;
}
.la-square-loader.la-3x > div {
    border-width: 6px;
}
/*
 * Animations
 */
@-webkit-keyframes square-loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    25% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    75% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-moz-keyframes square-loader {
    0% {
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    25% {
        -moz-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    50% {
        -moz-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    75% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-o-keyframes square-loader {
    0% {
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    25% {
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    50% {
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    75% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes square-loader {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    25% {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    75% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes square-loader-inner {
    0% {
        height: 0;
    }
    25% {
        height: 0;
    }
    50% {
        height: 100%;
    }
    75% {
        height: 100%;
    }
    100% {
        height: 0;
    }
}
@-moz-keyframes square-loader-inner {
    0% {
        height: 0;
    }
    25% {
        height: 0;
    }
    50% {
        height: 100%;
    }
    75% {
        height: 100%;
    }
    100% {
        height: 0;
    }
}
@-o-keyframes square-loader-inner {
    0% {
        height: 0;
    }
    25% {
        height: 0;
    }
    50% {
        height: 100%;
    }
    75% {
        height: 100%;
    }
    100% {
        height: 0;
    }
}
@keyframes square-loader-inner {
    0% {
        height: 0;
    }
    25% {
        height: 0;
    }
    50% {
        height: 100%;
    }
    75% {
        height: 100%;
    }
    100% {
        height: 0;
    }
}

.recurrent_billing_font,
.recurrent_billing_font *{
    font-size: 10px;
    color: #bbb;
}
