// center vertically and/or horizontally an absolute positioned element

@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    @include transform(translateX(-50%) translateY(-50%));
  }
  @else if $xy == x {
    left: 50%;
    right: auto;
    @include transform(translateX(-50%));
  }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    @include transform(translateY(-50%));
  }
}
@mixin flex(){
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
  box-sizing: border-box;
  @include flex();
}

// horizontal display
@mixin layout-align-space-between(){
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@mixin layout-align-space-around(){
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
@mixin layout-align-start(){
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
@mixin layout-align-end(){
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
@mixin layout-align-center(){
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

//vertical align
@mixin layout-vertical-center(){
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  max-width: 100%
}
@mixin layout-vertical-start(){
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}
@mixin layout-vertical-end(){
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

// layout wrap

@mixin layout-wrap() {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@mixin layout-nowrap() {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@mixin word-wrap() {
  word-break:     break-word;
  -webkit-hyphens: auto;
  -moz-hyphens:    auto;
  hyphens:         auto;
}
@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis ellipsis;
  white-space: nowrap;
}

// FONT FACES

@mixin font-face($fontFamily, $eotFileSrc, $woffFileSrc, $ttfFileSrc, $svgFileSrc, $weight, $style) {
  font-family: $fontFamily;
  src: url($eotFileSrc)  format('eot'),
  url($woffFileSrc) format('woff'),
  url($ttfFileSrc)  format('truetype'),
  url($svgFileSrc)  format('svg');
  font-weight: $weight;
  font-style: $style;
}

// TRIANGLE

@mixin triangle($dir, $size, $color) {
  border: $size transparent solid;

  @if ($dir == "top" or $dir == "up" or $dir == "left" or $dir == "before") {
    &:before {
      @if ($dir == "top" or $dir == "up") {
        border-bottom-color: $color;
      }
      @else if ($dir == "right" or $dir == "after") {
        border-left-color: $color;
      }
    }
  }
  @else if ($dir == "bottom" or $dir == "down" or $dir == "left" or $dir == "before") {
    &:after {
      @if ($dir == "bottom" or $dir == "down") {
        border-top-color: $color;
      }
      @else if ($dir == "left" or $dir == "before") {
        border-right-color: $color;
      }
    }
  }
}
// skew
@mixin skew($value){
  -webkit-transform: skew(#{$value}deg);
  -moz-transform: skew(#{$value}deg);
  -o-transform: skew(#{$value}deg);
}

//border-shadow

@mixin box-shadow-2($args1, $args2) {
  -webkit-box-shadow: $args1, $args2;
  -moz-box-shadow: $args1, $args2;
  box-shadow: $args1, $args2;
}

@mixin box-shadow-1($args1) {
  -webkit-box-shadow: $args1;
  -moz-box-shadow: $args1;
  box-shadow: $args1;
}

// HOVER

// usage example: @include hover(.3s);

@mixin hover($time) {
  -webkit-transition: all $time ease-in-out;
  -o-transition: all $time ease-in-out;
  -moz-transition: all $time ease-in-out;
  transition: all $time ease-in-out;
}


// OPACITY

@mixin opacity($opacity) {
  opacity: $opacity;
  filter: alpha(opacity=($opacity * 100));
}


// BLACK / WHITE OPACITY

@function black($opacity) {
  @return rgba(0,0,0,$opacity)
}

@function white($opacity) {
  @return rgba(255,255,255,$opacity)
}


// SCALE

// usage example: @include scale(2);

@mixin scale($value) {
  -webkit-transform: scale($value) ;
  transform: scale($value);
}

// SHADOWS

@mixin shadow($color) {
  -moz-box-shadow: 10px 10px 30px 0px $color;
  -webkit-box-shadow: 10px 10px 30px 0px $color;
  -o-box-shadow: 10px 10px 30px 0px $color;
  box-shadow: 10px 10px 30px 0px $color;
}


// TRANSLATE X

@mixin translateX($value) {
  -webkit-transform: translateX($value);
  -ms-transform: translateX($value);
  -o-transform: translateX($value);
  transform: translateX($value);
}

// TRANSLATE Y

@mixin translateY($value) {
  -webkit-transform: translateY($value);
  -ms-transform: translateY($value);
  -o-transform: translateY($value);
  transform: translateY($value);
}

// TRANSITION SCALEDOWN

@mixin scaledown($time) {
  -webkit-animation: scaledown $time ease-out 1;
  animation: scaledown $time ease-out 1;
}

// TRANSITION SCALE UP HOVER

@mixin ScaleUp($time) {
  -webkit-animation: ScaleUp $time ease-in-out 1;
  animation: ScaleUp $time ease-in-out 1;
}

// TRANSITION FADEIN

@mixin fadeIn($time) {
  -webkit-animation: fadeIn $time ease-out 1;
  animation: fadeIn $time ease-out 1;
}

@mixin slideInDown($time) {
  animation: slideInDown $time ease-in-out;
  -webkit-animation: slideInDown $time ease-in-out;
}

// TRANSITION SLIDEINUP

@-webkit-keyframes slideInUp {
  0% { @include translateY(20px); opacity: 0; }
  75% { @include translateY(0); }
  100% { opacity: 1; }
}

@keyframes slideInUp {
  0% { @include translateY(20px); opacity: 0; }
  75% { @include translateY(0); }
  100% { opacity: 1; }
}

@mixin slideInUp($time) {
  animation: slideInUp $time ease-in-out;
  -webkit-animation: slideInUp $time ease-in-out;
}

// TRANSITION SLIDEINRIGHT

@-webkit-keyframes slideInRight {
  0% { @include translateX(100px); opacity: 0; }
  75% { @include translateX(0); }
  100% { opacity: 1; }
}

@keyframes slideInRight {
  0% { @include translateX(100px); opacity: 0; }
  75% { @include translateX(0); }
  100% { opacity: 1; }
}

@mixin slideInRight($time) {
  animation: slideInRight $time ease-out backwards;
  -webkit-animation: slideInRight $time ease-out backwards;
}

// TRANSITION SLIDEINLEFT

@-webkit-keyframes slideInLeft {
  0% { @include translateX(-100px); opacity: 0; }
  75% { @include translateX(0); }
  100% { opacity: 1; }
}

@keyframes slideInLeft {
  0% { @include translateX(-100px); opacity: 0; }
  75% { @include translateX(0); }
  100% { opacity: 1; }
}

@mixin slideInLeft($time) {
  animation: slideInLeft $time ease-out backwards;
  -webkit-animation: slideInLeft $time ease-out backwards;
}


// BACKGROUND GRADIENTS

// usage example: @include background-gradient(red, black, vertical)

@mixin background-gradient($startcolor, $endcolor, $orientation) {
  background: $startcolor;
  @if $orientation == vertical {
    // vertical
    background: -moz-linear-gradient(top,  $startcolor 0%, $endcolor 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$startcolor), color-stop(100%,$endcolor));
    background: -webkit-linear-gradient(top,  $startcolor 0%,$endcolor 100%);
    background: -o-linear-gradient(top,  $startcolor 0%,$endcolor 100%);
    background: -ms-linear-gradient(top,  $startcolor 0%,$endcolor 100%);
    background: linear-gradient(to bottom,  $startcolor 0%,$endcolor 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$startcolor', endColorstr='$endcolor',GradientType=0 );
  }
  @else if $orientation == horizontal
  {
    // horizontal
    background: -moz-linear-gradient(left,  $startcolor 0%, $endcolor 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,$startcolor), color-stop(100%,$endcolor));
    background: -webkit-linear-gradient(left,  $startcolor 0%,$endcolor 100%);
    background: -o-linear-gradient(left,  $startcolor 0%,$endcolor 100%);
    background: -ms-linear-gradient(left,  $startcolor 0%,$endcolor 100%);
    background: linear-gradient(to right,  $startcolor 0%,$endcolor 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$startcolor', endColorstr='$endcolor',GradientType=1 );
  }
  @else
  {
    // radial
    background: -moz-radial-gradient(center, ellipse cover,  $startcolor 0%, $endcolor 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,$startcolor), color-stop(100%,$endcolor));
    background: -webkit-radial-gradient(center, ellipse cover,  $startcolor 0%,$endcolor 100%);
    background: -o-radial-gradient(center, ellipse cover,  $startcolor 0%,$endcolor 100%);
    background: -ms-radial-gradient(center, ellipse cover,  $startcolor 0%,$endcolor 100%);
    background: radial-gradient(ellipse at center,  $startcolor 0%,$endcolor 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$startcolor', endColorstr='$endcolor',GradientType=1 );
  }
}


// column count
@mixin break-inside($content){
  break-inside:                   $content; /* ie */
  page-break-inside:              $content; /* Firefox */
  -webkit-column-break-inside:    $content; /* chrome */
}
@mixin columns($count, $gap){

  -webkit-column-count: $count;
  -moz-column-count:    $count;
  column-count:         $count;

  -webkit-column-gap:   $gap;
  -moz-column-gap:      $gap;
  column-gap:           $gap;

}
// flex align content
@mixin align-content($content){
  -webkit-align-items: $content;/* ie */
  -ms-flex-align: $content;/* Firefox */
  align-items: $content;/* chrome */
  max-width: 100%;
}

// Border radius
@mixin border-radius($content){
  -webkit-border-radius: $content;
  -moz-border-radius: $content;
  border-radius: $content;
}
