//this is a implementation of flex display
// tirasmuturi@gmail.com
// flex custom styles


// flex variables

%flex{
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}


@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
  box-sizing: border-box;
  @extend %flex;
}

// horizontal display

%layout-align-space-between{
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

%layout-align-space-around{
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

%layout-align-start{
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

%layout-align-end{
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

%layout-align-center{
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

//vertical align
%layout-vertical-center{
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  max-width: 100%
}
%layout-vertical-start{
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}
%layout-vertical-end{
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

// layout wrap

%layout-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
%layout-nowrap {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

// flex-usage
%flex-nogrow{
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  box-sizing: border-box;
}
%flex-auto{
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  box-sizing: border-box;
}



.flex{
  @extend %flex;
}
.flex-wrap{
  @extend %layout-wrap;
}
.flex-no-wrap{
  @extend %layout-nowrap;
}
.flex-row{
  @include flex-direction(row);
  @extend %flex;

  &.flex-row-reverse{
    @include flex-direction(row-reverse);
  }

  // flex space between
  &.flex-align-space-between{
    @extend %layout-align-space-between;

    //vertical start
    &.flex-vertical-start{
      @extend %layout-vertical-start;
    }

    //vertical center
    &.flex-vertical-center{
      @extend %layout-vertical-center;
    }

    //vertical end
    &.flex-vertical-end{
      @extend %layout-vertical-end;
    }
  }

  // flex space around
  &.flex-align-space-around{
    @extend %layout-align-space-around;

    //vertical start
    &.flex-vertical-start{
      @extend %layout-vertical-start;
    }

    //vertical center
    &.flex-vertical-center{
      @extend %layout-vertical-center;
    }

    //vertical end
    &.flex-vertical-end{
      @extend %layout-vertical-end;
    }
  }

  // flex center
  &.flex-align-center{
    @extend %layout-align-center;

    //vertical start
    &.flex-vertical-start{
      @extend %layout-vertical-start;
    }

    //vertical center
    &.flex-vertical-center{
      @extend %layout-vertical-center;
    }

    //vertical end
    &.flex-vertical-end{
      @extend %layout-vertical-end;
    }
  }

  // flex start
  &.flex-align-start{
    @extend %layout-align-start;

    //vertical start
    &.flex-vertical-start{
      @extend %layout-vertical-start;
    }

    //vertical center
    &.flex-vertical-center{
      @extend %layout-vertical-center;
    }

    //vertical end
    &.flex-vertical-end{
      @extend %layout-vertical-end;
    }
  }

  // flex end
  &.flex-align-end{
    @extend %layout-align-end;

    //vertical start
    &.flex-vertical-start{
      @extend %layout-vertical-start;
    }

    //vertical center
    &.flex-vertical-center{
      @extend %layout-vertical-center;
    }

    //vertical end
    &.flex-vertical-end{
      @extend %layout-vertical-end;
    }
  }
}

.flex-column{
  @include flex-direction(column);
  @extend %flex;

  &.flex-column-reverse{
    @include flex-direction(column-reverse);
  }

  // flex space between
  &.flex-align-space-between{
    @extend %layout-align-space-between;

    //vertical start
    &.flex-vertical-start{
      @extend %layout-vertical-start;
    }

    //vertical center
    &.flex-vertical-center{
      @extend %layout-vertical-center;
    }

    //vertical end
    &.flex-vertical-end{
      @extend %layout-vertical-end;
    }
  }

  // flex space around
  &.flex-align-space-around{
    @extend %layout-align-space-around;

    //vertical start
    &.flex-vertical-start{
      @extend %layout-vertical-start;
    }

    //vertical center
    &.flex-vertical-center{
      @extend %layout-vertical-center;
    }

    //vertical end
    &.flex-vertical-end{
      @extend %layout-vertical-end;
    }
  }

  // flex center
  &.flex-align-center{
    @extend %layout-align-center;

    //vertical start
    &.flex-vertical-start{
      @extend %layout-vertical-start;
    }

    //vertical center
    &.flex-vertical-center{
      @extend %layout-vertical-center;
    }

    //vertical end
    &.flex-vertical-end{
      @extend %layout-vertical-end;
    }
  }

  // flex start
  &.flex-align-start{
    @extend %layout-align-start;

    //vertical start
    &.flex-vertical-start{
      @extend %layout-vertical-start;
    }

    //vertical center
    &.flex-vertical-center{
      @extend %layout-vertical-center;
    }

    //vertical end
    &.flex-vertical-end{
      @extend %layout-vertical-end;
    }
  }

  // flex end
  &.flex-align-end{
    @extend %layout-align-end;

    //vertical start
    &.flex-vertical-start{
      @extend %layout-vertical-start;
    }

    //vertical center
    &.flex-vertical-center{
      @extend %layout-vertical-center;
    }

    //vertical end
    &.flex-vertical-end{
      @extend %layout-vertical-end;
    }
  }
}

// flex width
.flex-auto{
  @extend %flex-auto;
}

.flex-nogrow{
  @extend %flex-nogrow;
}
