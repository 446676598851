.flex-column.flex-column-reverse, .flex-column, .flex-row.flex-row-reverse, .flex-row, .flex, .pop-ad-segment {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.flex-column.flex-align-space-between, .flex-row.flex-align-space-between {
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex-column.flex-align-space-around, .flex-row.flex-align-space-around {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.flex-column.flex-align-start, .flex-row.flex-align-start {
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.flex-column.flex-align-end, .flex-row.flex-align-end {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.flex-column.flex-align-center, .flex-row.flex-align-center {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex-column.flex-align-end.flex-vertical-center, .flex-column.flex-align-start.flex-vertical-center, .flex-column.flex-align-center.flex-vertical-center, .flex-column.flex-align-space-around.flex-vertical-center, .flex-column.flex-align-space-between.flex-vertical-center, .flex-row.flex-align-end.flex-vertical-center, .flex-row.flex-align-start.flex-vertical-center, .flex-row.flex-align-center.flex-vertical-center, .flex-row.flex-align-space-around.flex-vertical-center, .flex-row.flex-align-space-between.flex-vertical-center {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  max-width: 100%;
}

.flex-column.flex-align-end.flex-vertical-start, .flex-column.flex-align-start.flex-vertical-start, .flex-column.flex-align-center.flex-vertical-start, .flex-column.flex-align-space-around.flex-vertical-start, .flex-column.flex-align-space-between.flex-vertical-start, .flex-row.flex-align-end.flex-vertical-start, .flex-row.flex-align-start.flex-vertical-start, .flex-row.flex-align-center.flex-vertical-start, .flex-row.flex-align-space-around.flex-vertical-start, .flex-row.flex-align-space-between.flex-vertical-start {
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.flex-column.flex-align-end.flex-vertical-end, .flex-column.flex-align-start.flex-vertical-end, .flex-column.flex-align-center.flex-vertical-end, .flex-column.flex-align-space-around.flex-vertical-end, .flex-column.flex-align-space-between.flex-vertical-end, .flex-row.flex-align-end.flex-vertical-end, .flex-row.flex-align-start.flex-vertical-end, .flex-row.flex-align-center.flex-vertical-end, .flex-row.flex-align-space-around.flex-vertical-end, .flex-row.flex-align-space-between.flex-vertical-end {
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

.flex-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-no-wrap {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.flex-nogrow {
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  box-sizing: border-box;
}

.flex-auto, .pagination .page-item, .publisher-header .header-menu {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  box-sizing: border-box;
}

.flex-row {
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  box-sizing: border-box;
}
.flex-row.flex-row-reverse {
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  box-sizing: border-box;
}
.flex-column {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  box-sizing: border-box;
}
.flex-column.flex-column-reverse {
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  box-sizing: border-box;
}
.hide {
  display: none !important;
  visibility: hidden;
}

.invisible {
  visibility: hidden;
}

.show-l {
  display: none;
}
@media only screen and (min-device-width: 882px) and (min-width: 882px) {
  .show-l {
    display: block;
  }
}
.show-l .show-m {
  display: none;
}
@media only screen and (min-device-width: 830px) and (min-width: 830px) {
  .show-l .show-m {
    display: block;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 399px), (min-width: 1px) and (max-width: 399px) {
  .hide-xs {
    display: none;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 599px), (min-width: 1px) and (max-width: 599px) {
  .hide-sm {
    display: none;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .hide-md {
    display: none !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .hide-lg {
    display: none !important;
  }
}

.show-xs {
  display: none;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 399px), (min-width: 1px) and (max-width: 399px) {
  .show-xs {
    display: block;
  }
}

.show-sm {
  display: none;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 599px), (min-width: 1px) and (max-width: 599px) {
  .show-sm {
    display: block;
  }
}

.show-md {
  display: none;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .show-md {
    display: block !important;
  }
}

.show-mdf {
  display: none;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .show-mdf {
    display: flex !important;
  }
}

.show-lg {
  display: none;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .show-lg {
    display: block !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 399px), (min-width: 1px) and (max-width: 399px) {
  .xs-width-100 {
    width: 100% !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 599px), (min-width: 1px) and (max-width: 599px) {
  .sm-width-100 {
    width: 100% !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md-width-100 {
    width: 100% !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 599px), (min-width: 1px) and (max-width: 599px) {
  .sm-hgt-auto {
    padding: 15px 0;
    height: auto !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .pg10a-md {
    padding: 10px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .pg20a-md {
    padding: 20px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 599px), (min-width: 1px) and (max-width: 599px) {
  .flex-sm-column {
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    box-sizing: border-box;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .hgta-md {
    height: auto !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .width-auto-md {
    width: auto !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .width-auto-lg {
    width: auto !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .home-cover-img {
    margin-top: 0 !important;
    width: auto !important;
    height: 100%;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .flex-md {
    display: flex !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .flex-auto-md {
    -webkit-flex: 0 1 auto !important;
    -ms-flex: 0 1 auto !important;
    flex: 0 1 auto !important;
    box-sizing: border-box;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .flex-column-md {
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    box-sizing: border-box;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .flex-column-r-md {
    -webkit-flex-direction: column-reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
    box-sizing: border-box;
  }
}

@media only screen and (min-device-width: 883px) and (max-device-width: 1359px), (min-width: 883px) and (max-width: 1359px) {
  .flex-row-l {
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    box-sizing: border-box;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .flex-row-md {
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    box-sizing: border-box;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .flex-row-r-md {
    -webkit-flex-direction: row-reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
    box-sizing: border-box;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .flex-vertical-start-md {
    -webkit-align-items: flex-start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -webkit-align-content: flex-start !important;
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .flex-align-center-md {
    -webkit-justify-content: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .flex-nogrow-md {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .text-c-md {
    text-align: center !important;
  }
}

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  75% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  75% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }
  75% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }
  75% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }
  75% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }
  75% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 16, 2015, 4:59:26 PM
    Author     : TI
*/
@font-face {
  font-family: "Gotham Rounded";
  src: url("/fonts/GothamRounded-Book.woff") format("woff"), url("/fonts/GothamRounded-Book.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("/fonts/GothamRounded-Medium.woff") format("woff"), url("/fonts/GothamRounded-Medium.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("/fonts/GothamRounded-Light.woff") format("woff"), url("/fonts/GothamRounded-Light.woff2") format("woff2");
  font-weight: lighter;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "rounded_mplus_light";
  src: url("/fonts/RoundedMplus1c-Light.woff2") format("woff2"), url("/fonts/RoundedMplus1c-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "rounded_mplus_thin";
  src: url("/fonts/RoundedMplus1c-Thin.woff2") format("woff2"), url("/fonts/RoundedMplus1c-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../web-fonts/Montserrat-BlackItalic.woff2") format("woff2"), url("../web-fonts/Montserrat-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../web-fonts/Montserrat-Black.woff2") format("woff2"), url("../web-fonts/Montserrat-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../web-fonts/Montserrat-Bold.woff2") format("woff2"), url("../web-fonts/Montserrat-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../web-fonts/Montserrat-ExtraBoldItalic.woff2") format("woff2"), url("../web-fonts/Montserrat-ExtraBoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../web-fonts/Montserrat-BoldItalic.woff2") format("woff2"), url("../web-fonts/Montserrat-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../web-fonts/Montserrat-ExtraBold.woff2") format("woff2"), url("../web-fonts/Montserrat-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../web-fonts/Montserrat-ExtraLight.woff2") format("woff2"), url("../web-fonts/Montserrat-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../web-fonts/Montserrat-Italic.woff2") format("woff2"), url("../web-fonts/Montserrat-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../web-fonts/Montserrat-ExtraLightItalic.woff2") format("woff2"), url("../web-fonts/Montserrat-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../web-fonts/Montserrat-Medium.woff2") format("woff2"), url("../web-fonts/Montserrat-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../web-fonts/Montserrat-LightItalic.woff2") format("woff2"), url("../web-fonts/Montserrat-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../web-fonts/Montserrat-Light.woff2") format("woff2"), url("../web-fonts/Montserrat-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../web-fonts/Montserrat-MediumItalic.woff2") format("woff2"), url("../web-fonts/Montserrat-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../web-fonts/Montserrat-SemiBold.woff2") format("woff2"), url("../web-fonts/Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../web-fonts/Montserrat-Regular.woff2") format("woff2"), url("../web-fonts/Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../web-fonts/Montserrat-Thin.woff2") format("woff2"), url("../web-fonts/Montserrat-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../web-fonts/Montserrat-ThinItalic.woff2") format("woff2"), url("../web-fonts/Montserrat-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../web-fonts/Montserrat-SemiBoldItalic.woff2") format("woff2"), url("../web-fonts/Montserrat-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
h1, h2, h3, h4, h5, h6, a, ul, ol, table, table * {
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

.btn {
  font-size: 13px;
}

h1 {
  font-size: 2rem;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  h1 {
    font-size: 1.6rem;
  }
}

h2 {
  font-size: 1.5rem;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  h2 {
    font-size: 1.2rem;
  }
}

h3 {
  font-size: 1.25rem;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  h3 {
    font-size: 1rem;
  }
}

h4 {
  font-size: 1.15rem;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  h4 {
    font-size: 0.92rem;
  }
}

h5 {
  font-size: 1.05rem;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  h5 {
    font-size: 0.84rem;
  }
}

h6 {
  font-size: 1rem;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  h6 {
    font-size: 0.8rem;
  }
}

img {
  object-fit: cover;
}

.norm {
  font-weight: normal !important;
}

.semi-bold {
  font-weight: bold !important;
}

.pro-bold {
  font-weight: bold !important;
}

.light {
  font-weight: lighter !important;
}

.thin {
  font-weight: lighter !important;
}

a, ul, ol, span, p {
  font-size: 13px;
}

.custom-pagination div,
.custom-pagination div nav,
.custom-pagination div nav .pagination, .iti, .select2-container, .select2-width-f .select2-container {
  width: 100% !important;
  float: left;
}

.bg-cr1 {
  background: #0f70b7 !important;
}

.cr-cr1 {
  color: #0f70b7 !important;
}

.bg-cr2, .custom_radioboxes input[type=radio]:checked + span {
  background: #272727 !important;
}

.cr-cr2 {
  color: #272727 !important;
}

.bg-cr3 {
  background: #0f70b7 !important;
}

.cr-cr3 {
  color: #0f70b7 !important;
}

.bg-cr4 {
  background: #d8ea5b !important;
}

.cr-cr4 {
  color: #d8ea5b !important;
}

.bg-cr5 {
  background: #a1bb5c !important;
}

.cr-cr5 {
  color: #a1bb5c !important;
}

.bg-crb {
  background: #000000 !important;
}

.cr-crb {
  color: #000000 !important;
}

.bg-crl {
  background: #2ba6cb;
}

.cr-crl {
  color: #2ba6cb;
}

.bg-crt {
  background: teal;
}

.cr-crt {
  color: teal;
}

.bg-crs {
  background: #5da423;
}

.cr-crs {
  color: #5da423;
}

.bg-cra {
  background: #fb3f3f;
}

.cr-cra {
  color: #fb3f3f;
}

.borc-cr1 {
  border-color: #0f70b7 !important;
}

.borc-cr2 {
  border-color: #272727 !important;
}

.borc-cr3 {
  border-color: #0f70b7 !important;
}

.borc-cr4 {
  border-color: #d8ea5b !important;
}

.borc-cr5 {
  border-color: #a1bb5c !important;
}

.bg-main-1 {
  background: #0f70b7;
}

.bg-main-2 {
  background: #138de6;
}

.bg-main-3 {
  background: #117ecf;
}

.bg-main-1-00v6 {
  background: rgba(15, 112, 183, 0.6);
}

.bg-main-1-00v7 {
  background: rgba(15, 112, 183, 0.7);
}

.bg-main-1-00v8 {
  background: rgba(15, 112, 183, 0.8);
}

.z-depth-1, .category-dropdown-content {
  -webkit-box-shadow: 1px 3px 10px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 3px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 1px 3px 10px 0 rgba(0, 0, 0, 0.1);
}

.z-depth-1-half {
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.z-depth-2 {
  -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-3 {
  -webkit-box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-4 {
  -webkit-box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

.z-depth-5 {
  -webkit-box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}

.bgfa, .pop-ad-segment {
  background: #fafafa !important;
}

.divider {
  height: 1px;
  overflow: hidden;
  background-color: #ccc;
}

@-webkit-keyframes cro-scale {
  0% {
    -webkit-transform: scale(1);
  }
  1% {
    -webkit-transform: scale(1.2);
  }
  3% {
    -webkit-transform: scale(0.8);
  }
  5% {
    -webkit-transform: scale(1.1);
  }
  7% {
    -webkit-transform: scale(0.9);
  }
  8% {
    -webkit-transform: scale(1.05);
  }
  10% {
    -webkit-transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes cro-scale {
  0% {
    -moz-transform: scale(1);
  }
  1% {
    -moz-transform: scale(1.2);
  }
  3% {
    -moz-transform: scale(0.8);
  }
  5% {
    -moz-transform: scale(1.1);
  }
  7% {
    -moz-transform: scale(0.9);
  }
  8% {
    -moz-transform: scale(1.05);
  }
  10% {
    -moz-transform: scale(1);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@keyframes cro-scale {
  0% {
    transform: scale(1);
  }
  1% {
    transform: scale(1.2);
  }
  3% {
    transform: scale(0.8);
  }
  5% {
    transform: scale(1.1);
  }
  7% {
    transform: scale(0.9);
  }
  8% {
    transform: scale(1.05);
  }
  10% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
.cro-scale {
  -webkit-animation: cro-scale ease-in infinite;
  -moz-animation: cro-scale ease-in infinite;
  animation: cro-scale ease-in infinite;
  -webkit-animation-duration: 10s;
  -moz-animation-duration: 10s;
  animation-duration: 10s;
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-square-loader,
.la-square-loader > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.la-square-loader {
  display: block;
  font-size: 0;
  color: #fff;
}

.la-square-loader.la-dark {
  color: #333;
}

.la-square-loader > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-square-loader {
  width: 32px;
  height: 32px;
}

.la-square-loader > div {
  width: 100%;
  height: 100%;
  background: transparent;
  border-width: 2px;
  border-radius: 0;
  -webkit-animation: square-loader 2s infinite ease;
  -moz-animation: square-loader 2s infinite ease;
  -o-animation: square-loader 2s infinite ease;
  animation: square-loader 2s infinite ease;
}

.la-square-loader > div:after {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  content: "";
  background-color: currentColor;
  -webkit-animation: square-loader-inner 2s infinite ease-in;
  -moz-animation: square-loader-inner 2s infinite ease-in;
  -o-animation: square-loader-inner 2s infinite ease-in;
  animation: square-loader-inner 2s infinite ease-in;
}

.la-square-loader.la-sm {
  width: 16px;
  height: 16px;
}

.la-square-loader.la-sm > div {
  border-width: 1px;
}

.la-square-loader.la-2x {
  width: 64px;
  height: 64px;
}

.la-square-loader.la-2x > div {
  border-width: 4px;
}

.la-square-loader.la-3x {
  width: 96px;
  height: 96px;
}

.la-square-loader.la-3x > div {
  border-width: 6px;
}

/*
 * Animations
 */
@-webkit-keyframes square-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes square-loader {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  25% {
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  50% {
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  75% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes square-loader {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  25% {
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  50% {
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  75% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes square-loader {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes square-loader-inner {
  0% {
    height: 0;
  }
  25% {
    height: 0;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
@-moz-keyframes square-loader-inner {
  0% {
    height: 0;
  }
  25% {
    height: 0;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
@-o-keyframes square-loader-inner {
  0% {
    height: 0;
  }
  25% {
    height: 0;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
@keyframes square-loader-inner {
  0% {
    height: 0;
  }
  25% {
    height: 0;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}
.recurrent_billing_font,
.recurrent_billing_font * {
  font-size: 10px;
  color: #bbb;
}

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Jan 20, 2015, 6:26:22 PM
    Author     : TI
*/
.div-vertical-custom {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.div-bottom-custom {
  position: relative;
  top: 100%;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

.pg0a {
  padding: 0px !important;
}

.pg0t {
  padding-top: 0px !important;
}

.pg0b {
  padding-bottom: 0px !important;
}

.pg0tb, .search-all {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pg0l {
  padding-left: 0px !important;
}

.pg0r, .search-all {
  padding-right: 0px !important;
}

.pg0lr {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.mg0a {
  margin: 0px !important;
}

.mgalr {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mg0a {
  margin: 0px !important;
}

.mg0t {
  margin-top: 0px !important;
}

.mg0tm {
  margin-top: 0px !important;
}

.mg0b {
  margin-bottom: 0px !important;
}

.mg0bm {
  margin-bottom: 0px !important;
}

.mg0tb {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mg0l {
  margin-left: 0px !important;
}

.mg0lm {
  margin-left: 0px !important;
}

.mg0r {
  margin-right: 0px !important;
}

.mg0rm {
  margin-right: 0px !important;
}

.mg0lr {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.pg1a {
  padding: 1px !important;
}

.pg1t {
  padding-top: 1px !important;
}

.pg1b {
  padding-bottom: 1px !important;
}

.pg1tb {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.pg1l {
  padding-left: 1px !important;
}

.pg1r {
  padding-right: 1px !important;
}

.pg1lr {
  padding-right: 1px !important;
  padding-left: 1px !important;
}

.mg1a {
  margin: 1px !important;
}

.mg1t {
  margin-top: 1px !important;
}

.mg1tm {
  margin-top: -1px !important;
}

.mg1b {
  margin-bottom: 1px !important;
}

.mg1bm {
  margin-bottom: -1px !important;
}

.mg1tb {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.mg1l {
  margin-left: 1px !important;
}

.mg1lm {
  margin-left: -1px !important;
}

.mg1r {
  margin-right: 1px !important;
}

.mg1rm {
  margin-right: -1px !important;
}

.mg1lr {
  margin-right: 1px !important;
  margin-left: 1px !important;
}

.pg2a {
  padding: 2px !important;
}

.pg2t {
  padding-top: 2px !important;
}

.pg2b {
  padding-bottom: 2px !important;
}

.pg2tb {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.pg2l {
  padding-left: 2px !important;
}

.pg2r {
  padding-right: 2px !important;
}

.pg2lr {
  padding-right: 2px !important;
  padding-left: 2px !important;
}

.mg2a {
  margin: 2px !important;
}

.mg2t {
  margin-top: 2px !important;
}

.mg2tm {
  margin-top: -2px !important;
}

.mg2b {
  margin-bottom: 2px !important;
}

.mg2bm {
  margin-bottom: -2px !important;
}

.mg2tb {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.mg2l {
  margin-left: 2px !important;
}

.mg2lm {
  margin-left: -2px !important;
}

.mg2r {
  margin-right: 2px !important;
}

.mg2rm {
  margin-right: -2px !important;
}

.mg2lr {
  margin-right: 2px !important;
  margin-left: 2px !important;
}

.pg3a {
  padding: 3px !important;
}

.pg3t {
  padding-top: 3px !important;
}

.pg3b {
  padding-bottom: 3px !important;
}

.pg3tb {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.pg3l {
  padding-left: 3px !important;
}

.pg3r {
  padding-right: 3px !important;
}

.pg3lr {
  padding-right: 3px !important;
  padding-left: 3px !important;
}

.mg3a {
  margin: 3px !important;
}

.mg3t {
  margin-top: 3px !important;
}

.mg3tm {
  margin-top: -3px !important;
}

.mg3b {
  margin-bottom: 3px !important;
}

.mg3bm {
  margin-bottom: -3px !important;
}

.mg3tb {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.mg3l {
  margin-left: 3px !important;
}

.mg3lm {
  margin-left: -3px !important;
}

.mg3r {
  margin-right: 3px !important;
}

.mg3rm {
  margin-right: -3px !important;
}

.mg3lr {
  margin-right: 3px !important;
  margin-left: 3px !important;
}

.pg4a {
  padding: 4px !important;
}

.pg4t {
  padding-top: 4px !important;
}

.pg4b {
  padding-bottom: 4px !important;
}

.pg4tb {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.pg4l {
  padding-left: 4px !important;
}

.pg4r {
  padding-right: 4px !important;
}

.pg4lr {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.mg4a {
  margin: 4px !important;
}

.mg4t {
  margin-top: 4px !important;
}

.mg4tm {
  margin-top: -4px !important;
}

.mg4b {
  margin-bottom: 4px !important;
}

.mg4bm {
  margin-bottom: -4px !important;
}

.mg4tb {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.mg4l {
  margin-left: 4px !important;
}

.mg4lm {
  margin-left: -4px !important;
}

.mg4r {
  margin-right: 4px !important;
}

.mg4rm {
  margin-right: -4px !important;
}

.mg4lr {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.pg5a {
  padding: 5px !important;
}

.pg5t {
  padding-top: 5px !important;
}

.pg5b {
  padding-bottom: 5px !important;
}

.pg5tb {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pg5l {
  padding-left: 5px !important;
}

.pg5r {
  padding-right: 5px !important;
}

.pg5lr {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.mg5a {
  margin: 5px !important;
}

.mg5t {
  margin-top: 5px !important;
}

.mg5tm {
  margin-top: -5px !important;
}

.mg5b {
  margin-bottom: 5px !important;
}

.mg5bm {
  margin-bottom: -5px !important;
}

.mg5tb {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mg5l {
  margin-left: 5px !important;
}

.mg5lm {
  margin-left: -5px !important;
}

.mg5r {
  margin-right: 5px !important;
}

.mg5rm {
  margin-right: -5px !important;
}

.mg5lr {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.pg6a {
  padding: 6px !important;
}

.pg6t {
  padding-top: 6px !important;
}

.pg6b {
  padding-bottom: 6px !important;
}

.pg6tb, .publisher-header .header-menu {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.pg6l {
  padding-left: 6px !important;
}

.pg6r {
  padding-right: 6px !important;
}

.pg6lr {
  padding-right: 6px !important;
  padding-left: 6px !important;
}

.mg6a {
  margin: 6px !important;
}

.mg6t {
  margin-top: 6px !important;
}

.mg6tm {
  margin-top: -6px !important;
}

.mg6b {
  margin-bottom: 6px !important;
}

.mg6bm {
  margin-bottom: -6px !important;
}

.mg6tb {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.mg6l {
  margin-left: 6px !important;
}

.mg6lm {
  margin-left: -6px !important;
}

.mg6r {
  margin-right: 6px !important;
}

.mg6rm {
  margin-right: -6px !important;
}

.mg6lr {
  margin-right: 6px !important;
  margin-left: 6px !important;
}

.pg7a {
  padding: 7px !important;
}

.pg7t {
  padding-top: 7px !important;
}

.pg7b {
  padding-bottom: 7px !important;
}

.pg7tb {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.pg7l {
  padding-left: 7px !important;
}

.pg7r {
  padding-right: 7px !important;
}

.pg7lr {
  padding-right: 7px !important;
  padding-left: 7px !important;
}

.mg7a {
  margin: 7px !important;
}

.mg7t {
  margin-top: 7px !important;
}

.mg7tm {
  margin-top: -7px !important;
}

.mg7b {
  margin-bottom: 7px !important;
}

.mg7bm {
  margin-bottom: -7px !important;
}

.mg7tb {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.mg7l {
  margin-left: 7px !important;
}

.mg7lm {
  margin-left: -7px !important;
}

.mg7r {
  margin-right: 7px !important;
}

.mg7rm {
  margin-right: -7px !important;
}

.mg7lr {
  margin-right: 7px !important;
  margin-left: 7px !important;
}

.pg8a {
  padding: 8px !important;
}

.pg8t {
  padding-top: 8px !important;
}

.pg8b {
  padding-bottom: 8px !important;
}

.pg8tb, .sidemenu .category-link {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.pg8l {
  padding-left: 8px !important;
}

.pg8r {
  padding-right: 8px !important;
}

.pg8lr {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.mg8a {
  margin: 8px !important;
}

.mg8t {
  margin-top: 8px !important;
}

.mg8tm {
  margin-top: -8px !important;
}

.mg8b {
  margin-bottom: 8px !important;
}

.mg8bm {
  margin-bottom: -8px !important;
}

.mg8tb {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.mg8l {
  margin-left: 8px !important;
}

.mg8lm {
  margin-left: -8px !important;
}

.mg8r {
  margin-right: 8px !important;
}

.mg8rm {
  margin-right: -8px !important;
}

.mg8lr {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.pg9a {
  padding: 9px !important;
}

.pg9t {
  padding-top: 9px !important;
}

.pg9b {
  padding-bottom: 9px !important;
}

.pg9tb {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.pg9l {
  padding-left: 9px !important;
}

.pg9r {
  padding-right: 9px !important;
}

.pg9lr {
  padding-right: 9px !important;
  padding-left: 9px !important;
}

.mg9a {
  margin: 9px !important;
}

.mg9t {
  margin-top: 9px !important;
}

.mg9tm {
  margin-top: -9px !important;
}

.mg9b {
  margin-bottom: 9px !important;
}

.mg9bm {
  margin-bottom: -9px !important;
}

.mg9tb {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}

.mg9l {
  margin-left: 9px !important;
}

.mg9lm {
  margin-left: -9px !important;
}

.mg9r {
  margin-right: 9px !important;
}

.mg9rm {
  margin-right: -9px !important;
}

.mg9lr {
  margin-right: 9px !important;
  margin-left: 9px !important;
}

.pg1pa {
  padding: 1% !important;
}

.pg1ptb {
  padding-top: 1% !important;
  padding-bottom: 1% !important;
}

.pg1plr {
  padding-right: 1% !important;
  padding-left: 1% !important;
}

.pg2pa {
  padding: 2% !important;
}

.pg2ptb {
  padding-top: 2% !important;
  padding-bottom: 2% !important;
}

.pg2plr {
  padding-right: 2% !important;
  padding-left: 2% !important;
}

.pg3pa {
  padding: 3% !important;
}

.pg3ptb {
  padding-top: 3% !important;
  padding-bottom: 3% !important;
}

.pg3plr {
  padding-right: 3% !important;
  padding-left: 3% !important;
}

.pg4pa {
  padding: 4% !important;
}

.pg4ptb {
  padding-top: 4% !important;
  padding-bottom: 4% !important;
}

.pg4plr {
  padding-right: 4% !important;
  padding-left: 4% !important;
}

.pg5pa {
  padding: 5% !important;
}

.pg5ptb {
  padding-top: 5% !important;
  padding-bottom: 5% !important;
}

.pg5plr {
  padding-right: 5% !important;
  padding-left: 5% !important;
}

.pg6pa {
  padding: 6% !important;
}

.pg6ptb {
  padding-top: 6% !important;
  padding-bottom: 6% !important;
}

.pg6plr {
  padding-right: 6% !important;
  padding-left: 6% !important;
}

.pg7pa {
  padding: 7% !important;
}

.pg7ptb {
  padding-top: 7% !important;
  padding-bottom: 7% !important;
}

.pg7plr {
  padding-right: 7% !important;
  padding-left: 7% !important;
}

.pg8pa {
  padding: 8% !important;
}

.pg8ptb {
  padding-top: 8% !important;
  padding-bottom: 8% !important;
}

.pg8plr {
  padding-right: 8% !important;
  padding-left: 8% !important;
}

.pg9pa {
  padding: 9% !important;
}

.pg9ptb {
  padding-top: 9% !important;
  padding-bottom: 9% !important;
}

.pg9plr {
  padding-right: 9% !important;
  padding-left: 9% !important;
}

.pg10pa {
  padding: 10% !important;
}

.pg10ptb {
  padding-top: 10% !important;
  padding-bottom: 10% !important;
}

.pg10plr {
  padding-right: 10% !important;
  padding-left: 10% !important;
}

.pg10a, .pop-ad-segment, .category-dropdown-content {
  padding: 10px !important;
}

.pg10t {
  padding-top: 10px !important;
}

.pg10b {
  padding-bottom: 10px !important;
}

.pg10tb {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pg10l {
  padding-left: 10px !important;
}

.pg10r {
  padding-right: 10px !important;
}

.pg10lr {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.mg10a {
  margin: 10px !important;
}

.mg10t, .category-dropdown-content, .ob-d-form-c label {
  margin-top: 10px !important;
}

.mg10tm {
  margin-top: -10px !important;
}

.mg10b {
  margin-bottom: 10px !important;
}

.mg10bm {
  margin-bottom: -10px !important;
}

.mg10tb {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mg10l {
  margin-left: 10px !important;
}

.mg10lm {
  margin-left: -10px !important;
}

.mg10r {
  margin-right: 10px !important;
}

.mg10rm {
  margin-right: -10px !important;
}

.mg10lr {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.pg15a {
  padding: 15px !important;
}

.pg15t {
  padding-top: 15px !important;
}

.pg15b {
  padding-bottom: 15px !important;
}

.pg15tb {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pg15l {
  padding-left: 15px !important;
}

.pg15r {
  padding-right: 15px !important;
}

.pg15lr {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.mg15a {
  margin: 15px !important;
}

.mg15t {
  margin-top: 15px !important;
}

.mg15tm {
  margin-top: -15px !important;
}

.mg15b {
  margin-bottom: 15px !important;
}

.mg15bm {
  margin-bottom: -15px !important;
}

.mg15tb {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mg15l {
  margin-left: 15px !important;
}

.mg15lm {
  margin-left: -15px !important;
}

.mg15r {
  margin-right: 15px !important;
}

.mg15rm {
  margin-right: -15px !important;
}

.mg15lr {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.pg20a {
  padding: 20px !important;
}

.pg20t {
  padding-top: 20px !important;
}

.pg20b {
  padding-bottom: 20px !important;
}

.pg20tb {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pg20l, .search-all {
  padding-left: 20px !important;
}

.pg20r {
  padding-right: 20px !important;
}

.pg20lr, .toast-message {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.mg20a {
  margin: 20px !important;
}

.mg20t {
  margin-top: 20px !important;
}

.mg20tm, .wsi {
  margin-top: -20px !important;
}

.mg20b {
  margin-bottom: 20px !important;
}

.mg20bm {
  margin-bottom: -20px !important;
}

.mg20tb {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mg20l {
  margin-left: 20px !important;
}

.mg20lm {
  margin-left: -20px !important;
}

.mg20r {
  margin-right: 20px !important;
}

.mg20rm {
  margin-right: -20px !important;
}

.mg20lr {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.pg25a {
  padding: 25px !important;
}

.pg25t {
  padding-top: 25px !important;
}

.pg25b {
  padding-bottom: 25px !important;
}

.pg25tb {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.pg25l {
  padding-left: 25px !important;
}

.pg25r {
  padding-right: 25px !important;
}

.pg25lr {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

.mg25a {
  margin: 25px !important;
}

.mg25t {
  margin-top: 25px !important;
}

.mg25tm {
  margin-top: -25px !important;
}

.mg25b {
  margin-bottom: 25px !important;
}

.mg25bm {
  margin-bottom: -25px !important;
}

.mg25tb {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.mg25l {
  margin-left: 25px !important;
}

.mg25lm {
  margin-left: -25px !important;
}

.mg25r {
  margin-right: 25px !important;
}

.mg25rm {
  margin-right: -25px !important;
}

.mg25lr {
  margin-right: 25px !important;
  margin-left: 25px !important;
}

.pg30a {
  padding: 30px !important;
}

.pg30t {
  padding-top: 30px !important;
}

.pg30b {
  padding-bottom: 30px !important;
}

.pg30tb {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.pg30l {
  padding-left: 30px !important;
}

.pg30r {
  padding-right: 30px !important;
}

.pg30lr {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.mg30a {
  margin: 30px !important;
}

.mg30t {
  margin-top: 30px !important;
}

.mg30tm {
  margin-top: -30px !important;
}

.mg30b {
  margin-bottom: 30px !important;
}

.mg30bm {
  margin-bottom: -30px !important;
}

.mg30tb {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mg30l {
  margin-left: 30px !important;
}

.mg30lm {
  margin-left: -30px !important;
}

.mg30r {
  margin-right: 30px !important;
}

.mg30rm {
  margin-right: -30px !important;
}

.mg30lr {
  margin-right: 30px !important;
  margin-left: 30px !important;
}

.pg35a {
  padding: 35px !important;
}

.pg35t {
  padding-top: 35px !important;
}

.pg35b {
  padding-bottom: 35px !important;
}

.pg35tb {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.pg35l {
  padding-left: 35px !important;
}

.pg35r {
  padding-right: 35px !important;
}

.pg35lr {
  padding-right: 35px !important;
  padding-left: 35px !important;
}

.mg35a {
  margin: 35px !important;
}

.mg35t {
  margin-top: 35px !important;
}

.mg35tm {
  margin-top: -35px !important;
}

.mg35b {
  margin-bottom: 35px !important;
}

.mg35bm {
  margin-bottom: -35px !important;
}

.mg35tb {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.mg35l {
  margin-left: 35px !important;
}

.mg35lm {
  margin-left: -35px !important;
}

.mg35r {
  margin-right: 35px !important;
}

.mg35rm {
  margin-right: -35px !important;
}

.mg35lr {
  margin-right: 35px !important;
  margin-left: 35px !important;
}

.pg40a {
  padding: 40px !important;
}

.pg40t {
  padding-top: 40px !important;
}

.pg40b {
  padding-bottom: 40px !important;
}

.pg40tb {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.pg40l {
  padding-left: 40px !important;
}

.pg40r {
  padding-right: 40px !important;
}

.pg40lr {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.mg40a {
  margin: 40px !important;
}

.mg40t {
  margin-top: 40px !important;
}

.mg40tm {
  margin-top: -40px !important;
}

.mg40b {
  margin-bottom: 40px !important;
}

.mg40bm {
  margin-bottom: -40px !important;
}

.mg40tb {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mg40l {
  margin-left: 40px !important;
}

.mg40lm {
  margin-left: -40px !important;
}

.mg40r {
  margin-right: 40px !important;
}

.mg40rm {
  margin-right: -40px !important;
}

.mg40lr {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.pg45a {
  padding: 45px !important;
}

.pg45t {
  padding-top: 45px !important;
}

.pg45b {
  padding-bottom: 45px !important;
}

.pg45tb {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.pg45l {
  padding-left: 45px !important;
}

.pg45r {
  padding-right: 45px !important;
}

.pg45lr {
  padding-right: 45px !important;
  padding-left: 45px !important;
}

.mg45a {
  margin: 45px !important;
}

.mg45t {
  margin-top: 45px !important;
}

.mg45tm {
  margin-top: -45px !important;
}

.mg45b {
  margin-bottom: 45px !important;
}

.mg45bm {
  margin-bottom: -45px !important;
}

.mg45tb {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.mg45l {
  margin-left: 45px !important;
}

.mg45lm {
  margin-left: -45px !important;
}

.mg45r {
  margin-right: 45px !important;
}

.mg45rm {
  margin-right: -45px !important;
}

.mg45lr {
  margin-right: 45px !important;
  margin-left: 45px !important;
}

.pg50a {
  padding: 50px !important;
}

.pg50t {
  padding-top: 50px !important;
}

.pg50b {
  padding-bottom: 50px !important;
}

.pg50tb {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.pg50l {
  padding-left: 50px !important;
}

.pg50r {
  padding-right: 50px !important;
}

.pg50lr {
  padding-right: 50px !important;
  padding-left: 50px !important;
}

.mg50a {
  margin: 50px !important;
}

.mg50t {
  margin-top: 50px !important;
}

.mg50tm {
  margin-top: -50px !important;
}

.mg50b {
  margin-bottom: 50px !important;
}

.mg50bm {
  margin-bottom: -50px !important;
}

.mg50tb {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.mg50l {
  margin-left: 50px !important;
}

.mg50lm {
  margin-left: -50px !important;
}

.mg50r {
  margin-right: 50px !important;
}

.mg50rm {
  margin-right: -50px !important;
}

.mg50lr {
  margin-right: 50px !important;
  margin-left: 50px !important;
}

.pg55a {
  padding: 55px !important;
}

.pg55t {
  padding-top: 55px !important;
}

.pg55b {
  padding-bottom: 55px !important;
}

.pg55tb {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.pg55l {
  padding-left: 55px !important;
}

.pg55r {
  padding-right: 55px !important;
}

.pg55lr {
  padding-right: 55px !important;
  padding-left: 55px !important;
}

.mg55a {
  margin: 55px !important;
}

.mg55t {
  margin-top: 55px !important;
}

.mg55tm {
  margin-top: -55px !important;
}

.mg55b {
  margin-bottom: 55px !important;
}

.mg55bm {
  margin-bottom: -55px !important;
}

.mg55tb {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.mg55l {
  margin-left: 55px !important;
}

.mg55lm {
  margin-left: -55px !important;
}

.mg55r {
  margin-right: 55px !important;
}

.mg55rm {
  margin-right: -55px !important;
}

.mg55lr {
  margin-right: 55px !important;
  margin-left: 55px !important;
}

.pg60a {
  padding: 60px !important;
}

.pg60t {
  padding-top: 60px !important;
}

.pg60b {
  padding-bottom: 60px !important;
}

.pg60tb {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.pg60l {
  padding-left: 60px !important;
}

.pg60r {
  padding-right: 60px !important;
}

.pg60lr {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.mg60a {
  margin: 60px !important;
}

.mg60t {
  margin-top: 60px !important;
}

.mg60tm {
  margin-top: -60px !important;
}

.mg60b {
  margin-bottom: 60px !important;
}

.mg60bm {
  margin-bottom: -60px !important;
}

.mg60tb {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.mg60l {
  margin-left: 60px !important;
}

.mg60lm {
  margin-left: -60px !important;
}

.mg60r {
  margin-right: 60px !important;
}

.mg60rm {
  margin-right: -60px !important;
}

.mg60lr {
  margin-right: 60px !important;
  margin-left: 60px !important;
}

.pg65a {
  padding: 65px !important;
}

.pg65t {
  padding-top: 65px !important;
}

.pg65b {
  padding-bottom: 65px !important;
}

.pg65tb {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.pg65l {
  padding-left: 65px !important;
}

.pg65r {
  padding-right: 65px !important;
}

.pg65lr {
  padding-right: 65px !important;
  padding-left: 65px !important;
}

.mg65a {
  margin: 65px !important;
}

.mg65t {
  margin-top: 65px !important;
}

.mg65tm {
  margin-top: -65px !important;
}

.mg65b {
  margin-bottom: 65px !important;
}

.mg65bm {
  margin-bottom: -65px !important;
}

.mg65tb {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.mg65l {
  margin-left: 65px !important;
}

.mg65lm {
  margin-left: -65px !important;
}

.mg65r {
  margin-right: 65px !important;
}

.mg65rm {
  margin-right: -65px !important;
}

.mg65lr {
  margin-right: 65px !important;
  margin-left: 65px !important;
}

.pg70a {
  padding: 70px !important;
}

.pg70t {
  padding-top: 70px !important;
}

.pg70b {
  padding-bottom: 70px !important;
}

.pg70tb {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.pg70l {
  padding-left: 70px !important;
}

.pg70r {
  padding-right: 70px !important;
}

.pg70lr {
  padding-right: 70px !important;
  padding-left: 70px !important;
}

.mg70a {
  margin: 70px !important;
}

.mg70t {
  margin-top: 70px !important;
}

.mg70tm {
  margin-top: -70px !important;
}

.mg70b {
  margin-bottom: 70px !important;
}

.mg70bm {
  margin-bottom: -70px !important;
}

.mg70tb {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.mg70l {
  margin-left: 70px !important;
}

.mg70lm {
  margin-left: -70px !important;
}

.mg70r {
  margin-right: 70px !important;
}

.mg70rm {
  margin-right: -70px !important;
}

.mg70lr {
  margin-right: 70px !important;
  margin-left: 70px !important;
}

.pg75a {
  padding: 75px !important;
}

.pg75t {
  padding-top: 75px !important;
}

.pg75b {
  padding-bottom: 75px !important;
}

.pg75tb {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.pg75l {
  padding-left: 75px !important;
}

.pg75r {
  padding-right: 75px !important;
}

.pg75lr {
  padding-right: 75px !important;
  padding-left: 75px !important;
}

.mg75a {
  margin: 75px !important;
}

.mg75t {
  margin-top: 75px !important;
}

.mg75tm {
  margin-top: -75px !important;
}

.mg75b {
  margin-bottom: 75px !important;
}

.mg75bm {
  margin-bottom: -75px !important;
}

.mg75tb {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.mg75l {
  margin-left: 75px !important;
}

.mg75lm {
  margin-left: -75px !important;
}

.mg75r {
  margin-right: 75px !important;
}

.mg75rm {
  margin-right: -75px !important;
}

.mg75lr {
  margin-right: 75px !important;
  margin-left: 75px !important;
}

.pg80a {
  padding: 80px !important;
}

.pg80t {
  padding-top: 80px !important;
}

.pg80b {
  padding-bottom: 80px !important;
}

.pg80tb {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.pg80l {
  padding-left: 80px !important;
}

.pg80r {
  padding-right: 80px !important;
}

.pg80lr {
  padding-right: 80px !important;
  padding-left: 80px !important;
}

.mg80a {
  margin: 80px !important;
}

.mg80t {
  margin-top: 80px !important;
}

.mg80tm {
  margin-top: -80px !important;
}

.mg80b {
  margin-bottom: 80px !important;
}

.mg80bm {
  margin-bottom: -80px !important;
}

.mg80tb {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.mg80l {
  margin-left: 80px !important;
}

.mg80lm {
  margin-left: -80px !important;
}

.mg80r {
  margin-right: 80px !important;
}

.mg80rm {
  margin-right: -80px !important;
}

.mg80lr {
  margin-right: 80px !important;
  margin-left: 80px !important;
}

.pg85a {
  padding: 85px !important;
}

.pg85t {
  padding-top: 85px !important;
}

.pg85b {
  padding-bottom: 85px !important;
}

.pg85tb {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.pg85l {
  padding-left: 85px !important;
}

.pg85r {
  padding-right: 85px !important;
}

.pg85lr {
  padding-right: 85px !important;
  padding-left: 85px !important;
}

.mg85a {
  margin: 85px !important;
}

.mg85t {
  margin-top: 85px !important;
}

.mg85tm {
  margin-top: -85px !important;
}

.mg85b {
  margin-bottom: 85px !important;
}

.mg85bm {
  margin-bottom: -85px !important;
}

.mg85tb {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.mg85l {
  margin-left: 85px !important;
}

.mg85lm {
  margin-left: -85px !important;
}

.mg85r {
  margin-right: 85px !important;
}

.mg85rm {
  margin-right: -85px !important;
}

.mg85lr {
  margin-right: 85px !important;
  margin-left: 85px !important;
}

.pg90a {
  padding: 90px !important;
}

.pg90t {
  padding-top: 90px !important;
}

.pg90b {
  padding-bottom: 90px !important;
}

.pg90tb {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.pg90l {
  padding-left: 90px !important;
}

.pg90r {
  padding-right: 90px !important;
}

.pg90lr {
  padding-right: 90px !important;
  padding-left: 90px !important;
}

.mg90a {
  margin: 90px !important;
}

.mg90t {
  margin-top: 90px !important;
}

.mg90tm {
  margin-top: -90px !important;
}

.mg90b {
  margin-bottom: 90px !important;
}

.mg90bm {
  margin-bottom: -90px !important;
}

.mg90tb {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.mg90l {
  margin-left: 90px !important;
}

.mg90lm {
  margin-left: -90px !important;
}

.mg90r {
  margin-right: 90px !important;
}

.mg90rm {
  margin-right: -90px !important;
}

.mg90lr {
  margin-right: 90px !important;
  margin-left: 90px !important;
}

.pg95a {
  padding: 95px !important;
}

.pg95t {
  padding-top: 95px !important;
}

.pg95b {
  padding-bottom: 95px !important;
}

.pg95tb {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.pg95l {
  padding-left: 95px !important;
}

.pg95r {
  padding-right: 95px !important;
}

.pg95lr {
  padding-right: 95px !important;
  padding-left: 95px !important;
}

.mg95a {
  margin: 95px !important;
}

.mg95t {
  margin-top: 95px !important;
}

.mg95tm {
  margin-top: -95px !important;
}

.mg95b {
  margin-bottom: 95px !important;
}

.mg95bm {
  margin-bottom: -95px !important;
}

.mg95tb {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.mg95l {
  margin-left: 95px !important;
}

.mg95lm {
  margin-left: -95px !important;
}

.mg95r {
  margin-right: 95px !important;
}

.mg95rm {
  margin-right: -95px !important;
}

.mg95lr {
  margin-right: 95px !important;
  margin-left: 95px !important;
}

.pg100a {
  padding: 100px !important;
}

.pg100t {
  padding-top: 100px !important;
}

.pg100b {
  padding-bottom: 100px !important;
}

.pg100tb {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.pg100l {
  padding-left: 100px !important;
}

.pg100r {
  padding-right: 100px !important;
}

.pg100lr {
  padding-right: 100px !important;
  padding-left: 100px !important;
}

.mg100a {
  margin: 100px !important;
}

.mg100t {
  margin-top: 100px !important;
}

.mg100tm {
  margin-top: -100px !important;
}

.mg100b {
  margin-bottom: 100px !important;
}

.mg100bm {
  margin-bottom: -100px !important;
}

.mg100tb {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.mg100l {
  margin-left: 100px !important;
}

.mg100lm {
  margin-left: -100px !important;
}

.mg100r {
  margin-right: 100px !important;
}

.mg100rm {
  margin-right: -100px !important;
}

.mg100lr {
  margin-right: 100px !important;
  margin-left: 100px !important;
}

.pull-left,
.pl,
a.category-link {
  float: left;
}

.pull-right,
.pr {
  float: right !important;
}

.ipl {
  float: left !important;
}

.ipr {
  float: right !important;
}

.dib {
  display: inline-block;
}

.db {
  display: block;
}

.zino {
  z-index: initial !important;
}

.posr, .social-or, .strike-through, .publisher-header .header-menu.checkout.active, .publisher-header .header-menu.checkout, .publisher-header .header-menu.active, .strike-down {
  position: relative;
}

.posa {
  position: absolute;
}

.posf {
  position: fixed;
}

.posn {
  position: static !important;
}

.normal {
  font-weight: normal !important;
}

.bold {
  font-weight: bold !important;
}

.normala,
.normala * {
  font-weight: normal !important;
}

.bolda,
.bolda * {
  font-weight: bold !important;
}

.op0 {
  opacity: 0;
}

.op1 {
  opacity: 0.1;
}

.op2 {
  opacity: 0.2;
}

.op3 {
  opacity: 0.3;
}

.op4 {
  opacity: 0.4;
}

.op5 {
  opacity: 0.5;
}

.op6 {
  opacity: 0.6;
}

.op7 {
  opacity: 0.7;
}

.op8 {
  opacity: 0.8;
}

.op9 {
  opacity: 0.9;
}

.width0 {
  width: 0%;
}

.width10 {
  width: 10%;
}

.width20 {
  width: 20%;
}

.width30 {
  width: 30%;
}

.width40 {
  width: 40%;
}

.width50 {
  width: 50%;
}

.width60 {
  width: 60%;
}

.width70 {
  width: 70%;
}

.width80 {
  width: 80%;
}

.width90 {
  width: 90%;
}

.width100 {
  width: 100%;
}

.width-f, .pagination .page-item .page-link, .pop-ad-segment {
  width: 100% !important;
}

.width75 {
  width: 75% !important;
}

.width66 {
  width: 67% !important;
}

.width65 {
  width: 65% !important;
}

.width60 {
  width: 60% !important;
}

.width50 {
  width: 50% !important;
}

.width45 {
  width: 45% !important;
}

.width49 {
  width: 49% !important;
}

.width33 {
  width: 33.333333% !important;
  float: left;
}

.width30 {
  width: 30% !important;
}

.width20 {
  width: 20% !important;
}

.width25 {
  width: 25% !important;
}

.width15 {
  width: 15% !important;
}

.width_auto {
  width: auto !important;
}

.width50x {
  width: 50px !important;
}

.width60x {
  width: 60px !important;
}

.width70x {
  width: 70px !important;
}

.width75x {
  width: 75px !important;
}

.width80x {
  width: 80px !important;
}

.width90x {
  width: 90px !important;
}

.width100x {
  width: 100px !important;
}

.width110x {
  width: 110px !important;
}

.width120x {
  width: 120px !important;
}

.width130x {
  width: 130px !important;
}

.width140x {
  width: 140px !important;
}

.width150x {
  width: 150px !important;
}

.width175x {
  width: 175px !important;
}

.width200x {
  width: 200px !important;
}

.width250x {
  width: 250px !important;
}

.width300x {
  width: 300px !important;
}

.width200 {
  width: 200px;
}

.width300 {
  width: 300px;
}

.width400 {
  width: 400px;
}

.width500 {
  width: 500px;
}

.width600 {
  width: 600px;
}

.width700 {
  width: 700px;
}

.width800 {
  width: 800px;
}

.width900 {
  width: 900px;
}

.width1000 {
  width: 1000px;
}

.width1100 {
  width: 1100px;
}

.width1200 {
  width: 1200px;
}

.img1w,
.img16w {
  max-width: 16px !important;
  max-height: 16px;
  overflow: hidden;
  width: 100%;
}

.img2w,
.img20w {
  max-width: 20px !important;
  max-height: 20px;
  overflow: hidden;
  width: 100%;
}

.img3w,
.img30w {
  max-width: 30px !important;
  max-height: 30px;
  overflow: hidden;
  width: 100%;
}

.img4w,
.img40w {
  max-width: 40px !important;
  max-height: 40px;
  overflow: hidden;
  width: 100%;
}

.img5w,
.img50w {
  max-width: 50px !important;
  max-height: 50px;
  overflow: hidden;
  width: 100%;
}

.img6w,
.img60w {
  max-width: 60px !important;
  max-height: 60px;
  overflow: hidden;
  width: 100%;
}

.img7w,
.img70w {
  max-width: 70px !important;
  max-height: 70px;
  overflow: hidden;
  width: 100%;
}

.img8w,
.img80w {
  max-width: 80px !important;
  max-height: 80px;
  overflow: hidden;
  width: 100%;
}

.img9w,
.img90w {
  max-width: 90px !important;
  max-height: 90px;
  overflow: hidden;
  width: 100%;
}

.img10w,
.img100w {
  max-width: 100px !important;
  max-height: 100px;
  overflow: hidden;
  width: 100%;
}

.img11w,
.img110w {
  max-width: 110px !important;
  max-height: 110px;
  overflow: hidden;
  width: 100%;
}

.img12w,
.img120w {
  max-width: 120px !important;
  max-height: 120px;
  overflow: hidden;
  width: 100%;
}

.img13w,
.img130w {
  max-width: 130px !important;
  max-height: 130px;
  overflow: hidden;
  width: 100%;
}

.img14w,
.img140w {
  max-width: 140px !important;
  max-height: 140px;
  overflow: hidden;
  width: 100%;
}

.img15w,
.img150w {
  max-width: 150px !important;
  max-height: 150px;
  overflow: hidden;
  width: 100%;
}

.hgt50x {
  height: 50px;
}

.hgt50p {
  height: 50%;
}

.hgt60x {
  height: 60px;
}

.hgt60p {
  height: 60%;
}

.hgt70x {
  height: 70px;
}

.hgt70p {
  height: 70%;
}

.hgt80x {
  height: 80px;
}

.hgt80p {
  height: 80%;
}

.hgt90x {
  height: 90px;
}

.hgt90p {
  height: 90%;
}

.hgti {
  height: inherit !important;
}

.hgta {
  height: auto !important;
}

.hgt_form_delete {
  height: 24px !important;
}

.hgt50m {
  min-height: 50px;
  height: auto;
}

.hgt100 {
  height: 100% !important;
}

.hgt100mp {
  min-height: 100px;
}

.hgt75 {
  height: 75px;
}

.hgt100x {
  height: 100px;
}

.hgt110 {
  height: 110px;
}

.hgt120 {
  height: 120px;
}

.hgt130 {
  height: 130px;
}

.hgt140 {
  height: 140px;
}

.hgt150 {
  height: 150px;
}

.hgt160 {
  height: 160px;
}

.hgt180 {
  height: 180px;
}

.hgt200 {
  height: 200px;
}

.hgt250 {
  height: 250px;
}

.hgt300 {
  height: 300px;
}

.hgt350 {
  height: 350px;
}

.hgt400 {
  height: 400px;
}

.hgt450 {
  height: 450px;
}

.hgt500 {
  height: 500px;
}

.hgt600 {
  height: 600px;
}

.hgt100x {
  height: 100px;
}

.hgt100p {
  height: 100%;
}

.hgt100m {
  min-height: 100px;
  height: auto;
}

.hgt150m {
  min-height: 150px;
  height: auto;
}

.hgt200m {
  min-height: 200px;
  height: auto;
}

.hgt250m {
  min-height: 250px;
  height: auto;
}

.div-vertical {
  width: 1%;
  height: inherit;
  display: table-cell;
  vertical-align: middle;
}

.div-bottom {
  width: 1%;
  height: inherit;
  display: table-cell;
  vertical-align: bottom;
}

.crFF,
.crff,
.custom_radioboxes input[type=radio]:checked + span,
.publisher-header .header-menu.checkout.active,
.crf {
  color: #fff !important;
}
.crFF a,
.crFF *,
.crff a,
.custom_radioboxes input[type=radio]:checked + span a,
.publisher-header .header-menu.checkout.active a,
.crff *,
.custom_radioboxes input[type=radio]:checked + span *,
.publisher-header .header-menu.checkout.active *,
.crf a,
.crf * {
  color: #fff !important;
}
.crFF a *,
.crff a *,
.custom_radioboxes input[type=radio]:checked + span a *,
.publisher-header .header-menu.checkout.active a *,
.crf a * {
  color: #fff !important;
}

.cr00,
.cr00 a {
  color: #000 !important;
}

.cr33,
.cr33 a {
  color: #333 !important;
}

.cr37,
.cr37 a {
  color: #373737 !important;
}

.cr44,
.cr44 a {
  color: #444 !important;
}

.cr47,
.cr47 a {
  color: #474747 !important;
}

.cr55,
.cr55 a {
  color: #555 !important;
}

.cr66,
.cr66 a {
  color: #666 !important;
}

.cr99,
.cr99 a {
  color: #999 !important;
}

.craa,
.craa a {
  color: #AAA !important;
}

.crbb,
.crbb a {
  color: #BBB !important;
}

.crcc,
.crcc a {
  color: #CCC !important;
}

.crdd,
.crdd a {
  color: #DDD !important;
}

.cree,
.cree a {
  color: #EEE !important;
}

.cr-yh,
.cr-yh a {
  color: #400190;
}

.cr-fb,
.cr-fb a {
  color: #3B5998;
}

.cr-tw,
.cr-tw a {
  color: #33CCFF;
}

.cr-gm,
.cr-gm a {
  color: #C63D2D;
}

.cr-lk,
.cr-lk a {
  color: #4875B4;
}

.cr-ig,
.cr-ig a,
.cr-inst,
.cr-inst a {
  color: #005787;
}

.cr-none {
  color: transparent !important;
}

.bg-yh {
  background: #400190 !important;
}
.bg-yh:hover {
  background: #350177;
}
.bg-tw {
  background: #33CCFF !important;
}
.bg-tw:hover {
  background: #1ac6ff;
}
.cr-tw {
  color: #33CCFF !important;
}
.cr-tw:hover {
  color: #1ac6ff;
}
.bg-fb {
  background: #3B5998 !important;
}
.bg-fb:hover {
  background: #344e86;
}
.bg-fb:hover i {
  color: #fff;
}

.cr-fb {
  color: #3B5998 !important;
}
.cr-fb:hover {
  color: #344e86;
}
.bg-gm {
  background: #C63D2D !important;
}
.bg-gm:hover {
  background: #b13728;
}
.cr-gm {
  color: #C63D2D !important;
}
.cr-gm:hover {
  color: #b13728;
}
.bg-ln {
  background: #4875B4 !important;
}
.bg-ln:hover {
  background: #4169a2;
}
.cr-ln {
  color: #4875B4 !important;
}
.cr-ln:hover {
  color: #4169a2;
}
.bg-ig,
.bg-ig a,
.bg-inst,
.bg-inst a {
  background: #005787 !important;
}

.cr-ig,
.cr-ig a,
.cr-inst,
.cr-inst a {
  color: #005787 !important;
}

.bg66 {
  background-color: #666;
}

.bg77 {
  background-color: #777;
}

.bg88 {
  background-color: #888;
}

.bg99 {
  background-color: #999;
}

.bgAA,
.bgaa {
  background-color: #aaa;
}

.bgBB,
.bgbb {
  background-color: #bbb;
}

.bgCC,
.bgcc,
a.category-link.active {
  background-color: #ccc;
}

.bgdd, a.category-link:hover,
.bgdd {
  background-color: #ddd;
}

.bgEE,
.bgee,
.search-all input {
  background-color: #eee;
}

.bge5,
.bgE5 {
  background-color: #e5e5e5;
}

.bgF0,
.bgf0 {
  background-color: #F0F0F0;
}

.bgF1,
.bgf1 {
  background-color: #F1F1F1;
}

.bgF2,
.bgf2 {
  background-color: #F2F2F2;
}

.bgF3,
.bgf3 {
  background-color: #F3F3F3;
}

.bgF4,
.bgf4 {
  background-color: #F4F4F4;
}

.bgF5,
.bgf5 {
  background-color: #F5F5F5;
}

.bgF6,
.bgf6 {
  background-color: #F6F6F6;
}

.bgF7,
.bgf7 {
  background-color: #F7F7F7;
}

.bgF8,
.bgf8 {
  background-color: #F8F8F8;
}

.bgF9,
.bgf9 {
  background-color: #F9F9F9;
}

.bg-i {
  background: inherit;
}

.bgff, .category-dropdown-content,
.bgff {
  background-color: #fff !important;
}

.bg00 {
  background-color: #000 !important;
}

.bgffv2 {
  background-color: rgba(250, 250, 250, 0.2);
}

.bg00v2 {
  background-color: rgba(0, 0, 0, 0.2);
}

.bgffv3 {
  background-color: rgba(250, 250, 250, 0.3);
}

.bg00v3 {
  background-color: rgba(0, 0, 0, 0.3);
}

.bgffv4 {
  background-color: rgba(250, 250, 250, 0.4);
}

.bg00v4 {
  background-color: rgba(0, 0, 0, 0.4);
}

.bgffv5 {
  background-color: rgba(250, 250, 250, 0.5);
}

.bg00v5 {
  background-color: rgba(0, 0, 0, 0.5);
}

.bgffv6 {
  background-color: rgba(250, 250, 250, 0.6);
}

.bg00v6 {
  background-color: rgba(0, 0, 0, 0.6);
}

.bgffv7 {
  background-color: rgba(250, 250, 250, 0.7);
}

.bg00v7 {
  background-color: rgba(0, 0, 0, 0.7);
}

.bgffv8 {
  background-color: rgba(250, 250, 250, 0.8);
}

.bg00v8 {
  background-color: rgba(0, 0, 0, 0.8);
}

.bgffv9 {
  background-color: rgba(250, 250, 250, 0.9);
}

.bg00v9 {
  background-color: rgba(0, 0, 0, 0.9);
}

.nobg,
.no-bgcolor {
  background: transparent !important;
  background-color: transparent !important;
}

.ovh {
  overflow: hidden !important;
}

.ovy {
  overflow-y: scroll;
  overflow-x: hidden;
}

.ova {
  overflow-y: auto;
  overflow-x: hidden;
}

.ovv {
  overflow: visible !important;
}

.bor0A {
  border: none !important;
}

.borA,
.bora,
.social-or,
.search-all,
a.category-link {
  border-style: solid;
}

.borb {
  border-bottom: solid;
}

.bort {
  border-top: solid;
}

.borl {
  border-left: solid;
}

.borr {
  border-right: solid;
}

.dasha {
  border-style: dashed;
}

.dashb {
  border-bottom: dashed;
}

.dasht {
  border-top: dashed;
}

.dashl {
  border-left: dashed;
}

.dashr {
  border-right: dashed;
}

.dota {
  border-style: dotted;
}

.dotb {
  border-bottom: dotted;
}

.dott {
  border-top: dotted;
}

.dotl {
  border-left: dotted;
}

.dotr {
  border-right: dotted;
}

.doublea {
  border-style: double;
}

.doubleb {
  border-bottom: double;
}

.doublet {
  border-top: double;
}

.doublel {
  border-left: double;
}

.doubler {
  border-right: double;
}

.bor0, .most_popular button {
  border-width: 0px !important;
}

.bor05 {
  border-width: 0.5px;
}

.bor1, .social-or, .search-all, a.category-link {
  border-width: 1px;
}

.bor2 {
  border-width: 2px;
}

.bor3 {
  border-width: 3px;
}

.bor4 {
  border-width: 4px;
}

.bor5 {
  border-width: 5px;
}

.borc55 {
  border-color: #555 !important;
}

.borc66 {
  border-color: #666 !important;
}

.borc77 {
  border-color: #777 !important;
}

.borc99 {
  border-color: #999 !important;
}

.borcaa, a.category-link.active, a.category-link:hover {
  border-color: #AAA !important;
}

.borcbb {
  border-color: #BBB !important;
}

.borccc, .social-or {
  border-color: #CCC !important;
}

.borcdd, .search-all {
  border-color: #DDD !important;
}

.borcee {
  border-color: #EEE !important;
}

.borcff {
  border-color: #FFF !important;
}

.br.bor0, .most_popular button.br {
  border-radius: 0px !important;
}

.br50p,
.br .br50p {
  border-radius: 50% !important;
}

.br50, .social-or,
.br .br50 {
  border-radius: 50px !important;
}

.br40,
.br .br40 {
  border-radius: 40px !important;
}

.br30,
.br .br30 {
  border-radius: 30px !important;
}

.br20, .search-all, .primary-form-button,
.br .br20 {
  border-radius: 20px !important;
}

.br0 {
  border-radius: 0px !important;
}

.br1 {
  border-radius: 1px !important;
}

.br2 {
  border-radius: 2px !important;
}

.br3 {
  border-radius: 3px !important;
}

.br4 {
  border-radius: 4px !important;
}

.br5 {
  border-radius: 5px !important;
}

.br6 {
  border-radius: 6px !important;
}

.br7 {
  border-radius: 7px !important;
}

.br8 {
  border-radius: 8px !important;
}

.br9 {
  border-radius: 9px !important;
}

.br10 {
  border-radius: 10px !important;
}

.cap {
  text-transform: capitalize;
}

.upp {
  text-transform: uppercase;
}

.low {
  text-transform: lowercase;
}

.textL,
.textl {
  text-align: left !important;
}

.textC,
.textc,
.pagination .page-item .page-link,
.publisher-header .header-menu {
  text-align: center;
}

.textca,
.textca * {
  text-align: center !important;
}

.textR,
.textr {
  text-align: right;
}

.boxs0 {
  box-shadow: none !important;
}

.texts0 {
  text-shadow: none !important;
}

.hide {
  display: none;
}

.show {
  display: block !important;
}

.top0 {
  top: 0px;
}

.left0 {
  left: 0px;
  right: auto !important;
}

.lefta {
  left: auto !important;
}

.right0 {
  right: 0px;
  left: auto !important;
}

.bottom0 {
  bottom: 0px;
}

/*z-index manipulation*/
.zi10m {
  z-index: -10;
}

.zi1 {
  z-index: 1;
}

.zi2 {
  z-index: 2;
}

.zi5 {
  z-index: 5;
}

.zi9 {
  z-index: 9 !important;
}

.zi10 {
  z-index: 10 !important;
}

.zi100 {
  z-index: 100;
}

.zi-m {
  z-index: 10000;
}

.zi-mx {
  z-index: 100000;
}

/*table designing*/
.tb-c1,
.tb-c2,
.tb-c3,
.tb-c4,
.tb-c5,
.tb-c6,
.tb-c7,
.tb-c8,
.tb-c9,
.tb-c10 {
  width: 100%;
  float: left;
}

.tb-c1 > div,
.width1l {
  width: 100%;
  float: left;
}

.tb-c2 > div,
.width2l {
  width: 50%;
  float: left;
}

.tb-c3 > div,
.width3l {
  width: 33.3333333333%;
  float: left;
}

.tb-c4 > div,
.width4l {
  width: 25%;
  float: left;
}

.tb-c5 > div,
.width5l {
  width: 20%;
  float: left;
}

.tb-c6 > div,
.width6l {
  width: 16.6666666667%;
  float: left;
}

.tb-c7 > div,
.width7l {
  width: 14.2857142857%;
  float: left;
}

.tb-c8 > div,
.width8l {
  width: 12.5%;
  float: left;
}

.tb-c9 > div,
.width9l {
  width: 11.1111111111%;
  float: left;
}

.tb-c10 > div,
.width10l {
  width: 10%;
  float: left;
}

.tb-a {
  width: 100%;
  height: auto;
  float: left;
  text-align: center;
}

.tb-h {
  width: 100%;
  padding: 3px;
  background: #CCC;
  float: left;
}

.tb-f {
  width: 100%;
  padding: 3px;
  background: #D7D9DC;
  float: left;
}

.tb-h div:nth-child(2n+1) {
  background-color: #BBB;
}

.tb-h div:nth-child(2n+2) {
  background-color: #DDD;
}

.tb-f div:nth-child(2n+2) {
  background-color: #C7C9CB;
}

.tb-f div:nth-child(2n+1) {
  background-color: #EBEBEB;
}

.tb-b {
  width: 100%;
  float: left;
}

.tb-r {
  width: 100%;
  padding: 3px;
  float: left;
}

.tb-b .tb-r {
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #CCC;
}

.tb-b .tb-r:nth-child(2n+1) {
  background-color: #F8F8F8;
}

.tb-b .tb-r:nth-child(2n+2) {
  background-color: #F2F2F2;
}

.tb-r:nth-child(2n+2) > div > div:nth-child(2n+2) {
  /*	background-color: #F8F8F8;*/
}

.tb-r:nth-child(2n+1) > div > div:nth-child(2n+2) {
  /*	background-color: #F2F2F2;*/
}

/*content*/
.nofont {
  font-family: sans-serif !important;
}

.font400 {
  font-size: 400%;
}

.font300 {
  font-size: 300%;
}

.content {
  position: relative;
}

.content:before,
.content:after {
  position: absolute;
  content: "";
}

.content-quotes:before {
  content: "&ldquo";
}

.content-quotes:after {
  content: "&rdquo";
}

.crp {
  cursor: pointer;
}

.crt {
  cursor: text;
}

.list-diff {
  border-top: 1px dashed #FFF;
  border-bottom: 1px dashed #BBB;
}
.list-diff:nth-child(2n+1) {
  background: #F5F5F5;
}
.list-diff:nth-child(2n+2) {
  background: #F1F1F1;
  border-top: 1px dashed #FFF;
  border-bottom: 1px dashed #BBB;
}

.list-diff1 {
  border-top: 1px dashed #FFF;
  border-bottom: 1px dashed #BBB;
}
.list-diff1:nth-child(2n+1) {
  background: #F5F5F5;
}
.list-diff1:nth-child(2n+2) {
  background: #F9F9F9;
}

.tb-style1 > div,
.tb-style1 > li,
.tb-style1 > a,
.tb-style2 > div,
.tb-style2 > li,
.tb-style2 > a {
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #CCC;
  padding: 8px;
  width: 100%;
  float: left;
}

.tb-style2-l > div,
.tb-style2-l > li,
.tb-style2-l > a {
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #e3e3e3;
  padding: 8px;
  width: 100%;
  float: left;
}

.tb-style2-d > div,
.tb-style2-d > li,
.tb-style2-d > a {
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #bbb;
  padding: 8px;
  width: 100%;
  float: left;
}

.tb-style2.custom > div, .tb-style2.custom > li, .tb-style2.custom > a {
  border-top: 1px solid #abc;
  border-bottom: 1px solid #678;
  padding: 8px;
  width: 100%;
  float: left;
}

.tb-style3 > div,
.tb-style3 > li,
.tb-style3 > a {
  border: 1px solid #ddd;
  padding: 4px;
  margin: 2px 0;
  border-radius: 2px;
  width: 100%;
  float: left;
}

.tb-style4 > div,
.tb-style4 > li,
.tb-style4 > a {
  border: 1px solid #ddd;
  padding: 4px;
  margin: 0px 0;
  border-radius: 1px;
  width: 100%;
  float: left;
}

.tb-style1 > div:nth-child(2n+1),
.tb-style1 > li:nth-child(2n+1),
.tb-style1 > a:nth-child(2n+1),
.tb-style3 > div:nth-child(2n+1),
.tb-style3 > li:nth-child(2n+1),
.tb-style3 > a:nth-child(2n+1),
.tb-style4 > div:nth-child(2n+1),
.tb-style4 > li:nth-child(2n+1),
.tb-style4 > a:nth-child(2n+1) {
  background: #F7F7F7;
}

.tb-style1 > div:nth-child(2n+2),
.tb-style1 > li:nth-child(2n+2),
.tb-style1 > a:nth-child(2n+2),
.tb-style3 > div:nth-child(2n+2),
.tb-style3 > li:nth-child(2n+2),
.tb-style3 > a:nth-child(2n+2),
.tb-style4 > div:nth-child(2n+2),
.tb-style4 > li:nth-child(2n+2),
.tb-style4 > a:nth-child(2n+2) {
  background: #FDFDFD;
}

.tb-style1 > div:last-child,
.tb-style1 > li:last-child,
.tb-style1 > a:last-child,
.tb-style2 > div:last-child,
.tb-style2 > li:last-child,
.tb-style2 > a:last-child,
.tb-style2-l > div:last-child,
.tb-style2-l > li:last-child,
.tb-style2-l > a:last-child,
.tb-style2-d > div:last-child,
.tb-style2-d > li:last-child,
.tb-style2-d > a:last-child {
  border-bottom: none;
}

.tb-style1 > div:first-child,
.tb-style1 > li:first-child,
.tb-style1 > a:first-child,
.tb-style2 > div:first-child,
.tb-style2 > li:first-child,
.tb-style2 > a:first-child,
.tb-style2-l > div:first-child,
.tb-style2-l > li:first-child,
.tb-style2-l > a:first-child,
.tb-style2-d > div:first-child,
.tb-style2-d > li:first-child,
.tb-style2-d > a:first-child {
  border-top: none;
}

.input {
  width: 100%;
  float: left;
  border-radius: 2px !important;
  padding: 5px;
  border: 1px solid #ddd;
}
.input:focus {
  border: 1px solid #ccc;
}
.input > option {
  width: 99%;
}

.word-wrap {
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  overflow: hidden;
  text-overflow: ellipsis ellipsis;
  white-space: nowrap;
}

.wsi {
  white-space: pre-line;
}

.blur, .social-or::after, .social-or::before, .strike-through::before, .strike-through::after, .publisher-header .header-menu.checkout:before, .publisher-header .header-menu.checkout:after, .publisher-header .header-menu.active:after, .publisher-header .header-menu.active:before {
  content: "";
  position: absolute;
  top: 0;
}

.show-content, .show-hover-hidden:hover .shown-hover,
.show-hover-hidden:hover .show-hidden,
.show-hidden-content:hover .shown-hover,
.show-hidden-content:hover .show-hidden {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  z-index: 10;
}

.hide-content, .show-hover-hidden .shown-hover,
.show-hover-hidden .show-hidden,
.show-hidden-content .shown-hover,
.show-hidden-content .show-hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.5s, opacity 0.5s linear;
  z-index: -9;
}

.width-f, .pagination .page-item .page-link, .pop-ad-segment {
  width: 100% !important;
}

.width50:nth-child(2n+1) {
  padding-right: 10px !important;
}
.width50:nth-child(2n+2) {
  padding-left: 10px !important;
}
.width50.pg0a {
  padding: 0 !important;
}

a,
object,
embed,
button {
  outline: 0 !important;
}

input::-moz-focus-inner {
  border: 0;
}

.lh-r {
  line-height: 0 !important;
}

.lh-n {
  line-height: 1.8em;
}

.borl0 {
  border-left: none !important;
}

.borr0 {
  border-right: none !important;
}

.bort0 {
  border-top: none !important;
}

.borb0 {
  border-bottom: none !important;
}

.truncate,
.u-textTruncate {
  max-width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: pre-wrap !important;
  word-wrap: normal !important;
}

.t-small,
.u-textTruncate {
  white-space: nowrap !important;
  word-wrap: normal !important;
}

@font-face {
  font-family: "icomoon";
  src: url("/fonts/icomoon.eot?ukic1x");
  src: url("/fonts/icomoon.eot?ukic1x#iefix") format("embedded-opentype"), url("/fonts/icomoon.ttf?ukic1x") format("truetype"), url("/fonts/icomoon.woff?ukic1x") format("woff"), url("/fonts/icomoon.svg?ukic1x#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-book:before {
  content: "\e904";
}

.icon-search:before {
  content: "\e901";
}

.icon-shopping-cart:before {
  content: "\e902";
}

.icon-user:before {
  content: "\e900";
}

.icon-Menu:before {
  content: "\e903";
}

.icon-angle-left:before {
  content: "\ea50";
}

.icon-angle-right:before {
  content: "\ea51";
}

.icon-buoy:before {
  content: "\e905";
}

.icon-home:before {
  content: "\e906";
}

.icon-Icon-Publication:before {
  content: "\e907";
}

.icon-magazine:before {
  content: "\e908";
  color: #5a6268;
}

.icon-manage:before {
  content: "\e909";
}

.icon-newspaper:before {
  content: "\e90a";
  color: #5a6268;
}

.icon-Outline:before {
  content: "\e90b";
  color: #5a6268;
}

.icon-permissions:before {
  content: "\e90c";
}

.fa-25x {
  font-size: 2.5em;
}

.try-again {
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.try-again .mainbox {
  background-color: rgba(0, 0, 0, 0.1);
  margin: 10px auto;
  height: 600px;
  width: 600px;
  position: relative;
}
.try-again .err {
  color: #ffffff;
  font-family: "Nunito Sans", sans-serif;
  font-size: 11rem;
  position: absolute;
  left: 20%;
  top: 8%;
}
.try-again .far {
  position: absolute;
  font-size: 8.5rem;
  left: 42%;
  top: 15%;
  color: #ffffff;
}
.try-again .err2 {
  color: #ffffff;
  font-family: "Nunito Sans", sans-serif;
  font-size: 11rem;
  position: absolute;
  left: 68%;
  top: 8%;
}
.try-again .msg {
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.6rem;
  position: absolute;
  left: 16%;
  top: 45%;
  width: 75%;
}
.try-again a {
  text-decoration: none;
  color: white;
}
.try-again a:hover {
  text-decoration: underline;
}

.width-75-f-sm {
  width: 100%;
}
@media (min-width: 600px) {
  .width-75-f-sm {
    width: 75%;
  }
}

.width-50-f-sm {
  width: 100%;
}
@media (min-width: 600px) {
  .width-50-f-sm {
    width: 50%;
  }
}

.width-25-f-sm {
  width: 100%;
}
@media (min-width: 600px) {
  .width-25-f-sm {
    width: 25%;
  }
}

.primary-form-button {
  border: none;
  padding: 0.5rem 1.25rem;
  background-color: #0e7ebe;
}
.primary-form-button a {
  color: white;
  font-size: 1.125rem;
}

.form-item-3-sm {
  width: 100%;
}
@media (min-width: 600px) {
  .form-item-3-sm {
    width: 30%;
  }
}

.form-item-2-sm {
  width: 100%;
}
@media (min-width: 600px) {
  .form-item-2-sm {
    width: 45%;
  }
}

.form-item-2s-sm {
  width: 100%;
}
@media (min-width: 600px) {
  .form-item-2s-sm {
    width: 30%;
  }
}

.form-item-2b-sm {
  width: 100%;
}
@media (min-width: 600px) {
  .form-item-2b-sm {
    width: 65%;
  }
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.bg-primary {
  background-color: #0e7ebe !important;
}

.bg-primary-gradient {
  background: linear-gradient(90deg, #0e7ebe, #6da8d2);
}

.bg-primary-light {
  background-color: #d6e7f1;
}

.fa-color {
  color: #141414;
}

.bd1 {
  border: solid 1px #e5e5e5;
}

.bd1t {
  border-top: solid 1px #e5e5e5;
}

.bd2 {
  border: solid 2px #e5e5e5;
}

.bd2t {
  border-top: solid 2px #e5e5e5;
}

.bd1b {
  border-bottom: solid 1px #e5e5e5;
}

.bd1a-primary {
  border: solid 1px #1070b7 !important;
}

.pgemtb {
  padding-top: 1rem !important;
  padding-bottom: 1em !important;
}

.pg2emtb {
  padding-top: 1rem !important;
  padding-bottom: 1em !important;
}

.pgemlr {
  padding-left: 1rem !important;
  padding-right: 1em !important;
}

.pg2emlr {
  padding-left: 1rem !important;
  padding-right: 1em !important;
}

.self-center {
  align-self: center;
}

.text-primary {
  color: #0e7ebe;
}

.text-h {
  color: #343A40;
}

.bg-fa {
  background-color: #FAFCFC;
}

.bgdf {
  background-color: #DFDFDF;
}

.bgb0 {
  background-color: #B0B6BA;
}

.p-banner {
  background-image: linear-gradient(90deg, #343A40 80%, transparent);
}

.ob-con {
  object-fit: contain;
}

.ob-cov {
  object-fit: cover;
}

.font1r {
  font-size: 1rem;
}

.font18r {
  font-size: 1.125rem;
}

.cg1 {
  column-gap: 1rem;
}

.wbk-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.wbk-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.csr-p {
  cursor: pointer;
}

.card-title-shadow {
  padding-top: 1rem;
  box-shadow: 0 0 4px 2px #f0f0f0;
}

.fw300 {
  font-weight: 300;
}

.gap-1 {
  gap: 1em;
}

.card-title {
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 2rem;
  height: 2rem;
  line-height: 1rem;
  margin-bottom: 1rem;
  color: #1070b7;
}

.card-subtitle {
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 1rem;
  line-height: 1rem;
  font-size: 14px;
}

.ob-head-background {
  background: linear-gradient(90deg, #0e7ebe, #6da8d2);
}

.ob-form-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 1em;
  color: white;
  background: linear-gradient(90deg, #0e7ebe, #6da8d2);
}

.ob-form-subheader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 1em;
  color: #007AFF;
  background: linear-gradient(90deg, #F7F7F7, #F7F7F7);
}

.ob-form-content-block {
  display: flex;
  flex-direction: column;
}
.ob-form-content-block div {
  width: 100%;
  padding: 0.2em;
}
.ob-form-content-block div div {
  width: 100%;
  display: flex;
  flex-direction: column;
  column-gap: 0.5rem;
}
.ob-form-content-block div div label {
  color: black;
}
.ob-form-content-block div div input {
  transition: all 200ms ease;
  border: 1px #ccc solid;
  border-radius: 20px;
  padding: 0.3em 1em;
}
.ob-form-content-block div div input:focus {
  outline: none;
  border: 1px #0e7ebe solid;
}
.ob-form-content-block div div textarea {
  transition: all 200ms ease;
  border: 1px #ccc solid;
  border-radius: 20px;
  padding: 0.3em 1em;
}
.ob-form-content-block div div textarea:focus {
  outline: none;
  border: 1px #0e7ebe solid;
}
.ob-form-content-block div div select {
  transition: all 200ms ease;
  border: 1px #ccc solid;
  border-radius: 20px;
  padding: 0.3em 1em;
}
.ob-form-content-block div div select:focus {
  outline: none;
  border: 1px #0e7ebe solid;
}
@media (min-width: 768px) {
  .ob-form-content-block {
    flex-direction: row;
    justify-content: space-between;
  }
}

.ob-d-form-c {
  flex-direction: column;
}
@media (min-width: 600px) {
  .ob-d-form-c {
    flex-direction: row;
  }
}

.ob-bord-1-c {
  border: 1px solid #ccc;
}

.ob-input {
  transition: all 200ms ease;
  border: 1px #ccc solid;
}

.ob-input:focus {
  outline: none;
  border: 1px #6da8d2 solid;
}

* {
  letter-spacing: -0.2px;
}

.logo-img {
  width: auto;
  height: 50px;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .logo-img {
    height: 40px;
  }
}

.icon-img {
  width: 20px;
  height: 20px;
}

.icon-img-2 {
  width: 17px;
  height: 17px;
}

.icon-img path,
.icon-img-2 path {
  color: #0f70b7;
  fill: #0f70b7;
}

.fa-15x {
  font-size: 1.5em;
}

.menu-list {
  list-style: none;
}
.menu-list.bord li {
  border-left: 1px solid #e5e5e5;
}

.divider-vertical {
  border-right: 1px solid #e5e5e5;
  height: 100%;
}

.strike-down::before {
  content: "";
  position: absolute;
  bottom: -10px;
  border-bottom: 3px solid #ff5252;
  width: 75px;
}

a:hover {
  text-decoration: none;
}

.publisher-header .header-menu.active {
  background: #fff;
}
.publisher-header .header-menu.active:before {
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  border-left-color: #eee;
  border-left-style: solid;
  border-left-width: 10px;
  left: 0;
}
.publisher-header .header-menu.active:after {
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  border-left-color: #fff;
  border-left-style: solid;
  border-left-width: 18px;
  right: 0;
  margin-right: -10px;
}
.publisher-header .header-menu.checkout:after {
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  border-left-color: #fff;
  border-left-style: solid;
  border-left-width: 18px;
  right: 0;
  margin-right: -23px;
}
.publisher-header .header-menu.checkout:before {
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  border-left-color: #ccc;
  border-left-style: solid;
  border-left-width: 18px;
  right: 0;
  margin-right: -25px;
}
.publisher-header .header-menu.checkout:last-child:after, .publisher-header .header-menu.checkout:last-child:before {
  border-width: 0px;
}
.publisher-header .header-menu.checkout.active {
  background: #0f70b7;
}
.publisher-header .header-menu.checkout.active:first-child:before {
  border-left-width: 12px;
  border-left-color: #eee;
}
.publisher-header .header-menu.checkout.active:before {
  border-left-width: 12px;
  border-left-color: #fff;
}
.publisher-header .header-menu.checkout.active:after {
  border-left-color: #0f70b7;
  border-left-width: 15px;
  margin-right: -15px;
}
.publisher-header .header-menu.checkout.active:last-child:before {
  border-width: 18px;
}
ul.ks-cboxtags {
  list-style: none;
  padding: 20px;
}

ul.ks-cboxtags li {
  display: inline;
}

ul.ks-cboxtags li label {
  display: inline-block;
  background-color: #3da4ef;
  border: 2px solid rgba(139, 139, 139, 0.3);
  color: #fff;
  border-radius: 2px;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
}

ul.ks-cboxtags li label {
  padding: 8px 12px;
  cursor: pointer;
}

ul.ks-cboxtags li label::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  padding: 2px 6px 2px 2px;
  content: "\f1ce";
  transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type=radio]:checked + label::before {
  content: "\f00c";
  transform: rotate(-360deg);
  transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type=radio]:checked + label {
  border: 2px solid #1bdbf8;
  background-color: #12bbd4;
  color: #fff;
  transition: all 0.2s;
}

ul.ks-cboxtags li input[type=radio] {
  display: absolute;
}

ul.ks-cboxtags li input[type=radio] {
  position: absolute;
  opacity: 0;
}

.min-width-250p {
  min-width: 250px;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}
.scrolling-wrapper .content-card {
  flex: 0 0 auto;
}

.custom-file input {
  z-index: 100;
}

.modal-dialog .modal-lg {
  max-width: 800px;
  width: 100%;
}

.d-block,
.progress-true {
  display: block !important;
}

.d-none,
.progress-false {
  display: none !important;
}

.modal .modal-header {
  padding: 20px !important;
}

.progress-parent {
  height: 15px;
  /* Can be anything */
  position: relative;
  background: #555;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  -webkit-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}

.progress-parent > .progress-bar {
  display: block;
  height: 100%;
  -webkit-border-top-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -moz-border-radius-topright: 8px;
  -moz-border-radius-bottomright: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -webkit-border-top-left-radius: 20px;
  -webkit-border-bottom-left-radius: 20px;
  -moz-border-radius-topleft: 20px;
  -moz-border-radius-bottomleft: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgb(43, 194, 83);
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, rgb(43, 194, 83)), color-stop(1, rgb(84, 240, 84)));
  background-image: -moz-linear-gradient(center bottom, rgb(43, 194, 83) 37%, rgb(84, 240, 84) 69%);
  -webkit-box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
}

.progress-parent > .progress-bar:after, .animate > span > span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0.25, rgba(255, 255, 255, 0.2)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.2)), color-stop(0.75, rgba(255, 255, 255, 0.2)), color-stop(0.75, transparent), to(transparent));
  background-image: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
  z-index: 1;
  -webkit-background-size: 50px 50px;
  -moz-background-size: 50px 50px;
  -webkit-animation: move 2s linear infinite;
  -webkit-border-top-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -moz-border-radius-topright: 8px;
  -moz-border-radius-bottomright: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -webkit-border-top-left-radius: 20px;
  -webkit-border-bottom-left-radius: 20px;
  -moz-border-radius-topleft: 20px;
  -moz-border-radius-bottomleft: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}

.animate > span:after {
  display: none;
}

@-webkit-keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
.progress-parent-orange > .progress-bar {
  background-color: #f1a165;
  background-image: -moz-linear-gradient(top, #f1a165, #f36d0a);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #f1a165), color-stop(1, #f36d0a));
  background-image: -webkit-linear-gradient(#f1a165, #f36d0a);
}

.progress-parent-red > .progress-bar {
  background-color: #f0a3a3;
  background-image: -moz-linear-gradient(top, #f0a3a3, #f42323);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #f0a3a3), color-stop(1, #f42323));
  background-image: -webkit-linear-gradient(#f0a3a3, #f42323);
}

.nostripes > span > span, .nostripes > span:after {
  -webkit-animation: none;
  background-image: none;
}

ul, ol {
  margin: 0px;
}

.category-dropdown {
  position: relative;
  transition: all 1s ease;
}
.category-dropdown:hover .category-dropdown-content {
  display: block;
}

.category-dropdown-content {
  display: none;
  position: absolute;
  left: -300px;
  min-width: 600px;
  z-index: 1;
  transition-duration: 0.5s;
}

a.category-link {
  width: 100%;
  border-top-color: #fff;
  border-left-color: #fff;
  border-right-color: #ddd;
  border-bottom-color: #ddd;
}
a.category-link .fas {
  width: 15px;
  text-align: center;
}

.color-primary-dark {
  color: #0D3349;
}

.border-color-primary-dark {
  border-color: #0D3349;
}

.widthf {
  width: 100%;
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .bgff-md {
    background: #fff;
  }
}

.t-val-m {
  vertical-align: middle !important;
}

.firebaseui-title {
  display: none;
}

.select2-container--open {
  z-index: 10000;
}

.select2-dropdown.select2-container--default.select2-container--open {
  z-index: 10000;
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .pg0l-md {
    padding-left: 0px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .pg0r-md {
    padding-right: 0px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .width-f-md {
    width: 100% !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .nobg-md {
    background: none !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .flex-wrap-md {
    flex-wrap: wrap;
  }
}

.font30p,
.font30px {
  font-size: 30px;
}

.font20p,
.font20px {
  font-size: 20px;
}

.font16p,
.font16px {
  font-size: 16px;
}

.font15p,
.font15px {
  font-size: 15px;
}

.font14p,
.font14px {
  font-size: 14px;
}

.font13p,
.font13px {
  font-size: 13px;
}

.font12p,
.font12px {
  font-size: 12px;
}

.font11p,
.font11px {
  font-size: 11px;
}

.font10p,
.font10px {
  font-size: 10px;
}

.font8p,
.font8px {
  font-size: 8px;
}

.search-all input:visited, .search-all input:focus {
  border-width: 0px !important;
}

.search-all:focus {
  outline: none;
}

@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .container {
    max-width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.item-width {
  width: 205px;
  max-width: 300px;
}
.item-width.medium {
  width: 200px;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .item-width.medium {
    width: 140px;
  }
}
@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .item-width {
    width: 140px;
  }
}

.item-height {
  height: 270px;
}
.item-height.large {
  height: 350px;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .item-height.large {
    height: 190px;
  }
}
.item-height.medium {
  height: 270px;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .item-height.medium {
    height: 190px;
  }
}
@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .item-height {
    height: 190px;
  }
}

.category-page-column {
  width: 200px;
}

.category-page-list {
  width: calc(100% - 200px);
}

.header-link-text, .header-link-mob {
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-link-mob {
  display: flex;
  align-items: center;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 599px), (min-width: 1px) and (max-width: 599px) {
  .header-link-mob {
    padding: 5px;
  }
}

.navbar-nav-right {
  display: none;
  width: 230px;
  height: calc(100% - 45px);
  position: fixed;
  right: 0;
  top: 45px;
  z-index: 999;
  background: #fff;
  border-left: #d3d3d3 solid 1px;
  overflow-y: auto;
  transition: all ease-in;
  animation-name: sideenter;
  animation-duration: 250ms;
}
.navbar-nav-right.mds-active {
  display: block;
}

@keyframes sideenter {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.nav-item a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidemenu .cat-count {
  display: none;
}
.sidemenu .category-link {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
}

.posf {
  position: fixed;
  z-index: 1000;
}

.topheader-nav-h {
  height: 70px;
}
.topheader-nav-h > .container-fluid {
  height: 100%;
}

.topheader-nav a {
  color: #272727;
}

.home-load-div-mg {
  margin-top: 70px;
}

.home-load-div {
  min-height: calc(100vh - 70px);
}
.home-load-div.fixed-h {
  height: calc(100vh - 70px);
}
.menu-list > li > a,
.menu-list > li > div > a {
  font-size: 16px;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .menu-list > li > a,
.menu-list > li > div > a {
    font-size: 13px;
  }
}

.slider-bg {
  background-image: linear-gradient(90deg, rgba(15, 112, 183, 0.9) 40%, rgba(15, 112, 183, 0.2));
}

.ts-main-1 {
  text-shadow: 0 1px 2px rgba(15, 112, 183, 0.3);
}

@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .scrollable-item {
    margin-right: -20px !important;
    margin-left: -20px !important;
    width: calc(100% + 40px) !important;
    padding-left: 20px;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .issue-img {
    max-width: 60%;
  }
}

.gr-cr1, .try-again {
  background: rgba(15, 112, 183, 0);
  background: -moz-linear-gradient(top, rgba(15, 112, 183, 0) 0%, #0f70b7 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(15, 112, 183, 0)), color-stop(100%, #0f70b7));
  background: -webkit-linear-gradient(top, rgba(15, 112, 183, 0) 0%, #0f70b7 100%);
  background: -o-linear-gradient(top, rgba(15, 112, 183, 0) 0%, #0f70b7 100%);
  background: -ms-linear-gradient(top, rgba(15, 112, 183, 0) 0%, #0f70b7 100%);
  background: linear-gradient(to bottom, rgba(15, 112, 183, 0) 0%, #0f70b7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$startcolor", endColorstr="$endcolor",GradientType=0 );
}

.gr-cr2 {
  background: rgba(39, 39, 39, 0);
  background: -moz-linear-gradient(top, rgba(39, 39, 39, 0) 0%, #272727 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(39, 39, 39, 0)), color-stop(100%, #272727));
  background: -webkit-linear-gradient(top, rgba(39, 39, 39, 0) 0%, #272727 100%);
  background: -o-linear-gradient(top, rgba(39, 39, 39, 0) 0%, #272727 100%);
  background: -ms-linear-gradient(top, rgba(39, 39, 39, 0) 0%, #272727 100%);
  background: linear-gradient(to bottom, rgba(39, 39, 39, 0) 0%, #272727 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$startcolor", endColorstr="$endcolor",GradientType=0 );
}

.gr-cr3 {
  background: rgba(0, 0, 0, 0);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, black));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$startcolor", endColorstr="$endcolor",GradientType=0 );
}

.gr-cr4 {
  background: rgba(216, 234, 91, 0);
  background: -moz-linear-gradient(top, rgba(216, 234, 91, 0) 0%, #d8ea5b 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(216, 234, 91, 0)), color-stop(100%, #d8ea5b));
  background: -webkit-linear-gradient(top, rgba(216, 234, 91, 0) 0%, #d8ea5b 100%);
  background: -o-linear-gradient(top, rgba(216, 234, 91, 0) 0%, #d8ea5b 100%);
  background: -ms-linear-gradient(top, rgba(216, 234, 91, 0) 0%, #d8ea5b 100%);
  background: linear-gradient(to bottom, rgba(216, 234, 91, 0) 0%, #d8ea5b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$startcolor", endColorstr="$endcolor",GradientType=0 );
}

.gr-cr5 {
  background: rgba(15, 112, 183, 0);
  background: -moz-linear-gradient(top, rgba(15, 112, 183, 0) 0%, #0f70b7 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(15, 112, 183, 0)), color-stop(100%, #0f70b7));
  background: -webkit-linear-gradient(top, rgba(15, 112, 183, 0) 0%, #0f70b7 100%);
  background: -o-linear-gradient(top, rgba(15, 112, 183, 0) 0%, #0f70b7 100%);
  background: -ms-linear-gradient(top, rgba(15, 112, 183, 0) 0%, #0f70b7 100%);
  background: linear-gradient(to bottom, rgba(15, 112, 183, 0) 0%, #0f70b7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$startcolor", endColorstr="$endcolor",GradientType=0 );
}

.gr-cra {
  background: rgba(251, 63, 63, 0);
  background: -moz-linear-gradient(top, rgba(251, 63, 63, 0) 0%, #fb3f3f 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(251, 63, 63, 0)), color-stop(100%, #fb3f3f));
  background: -webkit-linear-gradient(top, rgba(251, 63, 63, 0) 0%, #fb3f3f 100%);
  background: -o-linear-gradient(top, rgba(251, 63, 63, 0) 0%, #fb3f3f 100%);
  background: -ms-linear-gradient(top, rgba(251, 63, 63, 0) 0%, #fb3f3f 100%);
  background: linear-gradient(to bottom, rgba(251, 63, 63, 0) 0%, #fb3f3f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$startcolor", endColorstr="$endcolor",GradientType=0 );
}

.cap_all *,
.select2-container * {
  text-transform: capitalize !important;
}

.home-carousel-height {
  height: 300px;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .home-carousel-height {
    height: 200px;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .fa-3x {
    font-size: 1rem;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .textl-md,
.textl-md * {
    text-align: left;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .mg0b-md {
    margin-bottom: 0px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .pg20tb {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .pg20t {
    padding-top: 10px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .pg20b {
    padding-bottom: 10px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .mg20tb {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .mg20t {
    margin-top: 10px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .mg20b {
    margin-bottom: 10px !important;
  }
}

.bor0lr {
  border-left: 0px solid transparent !important;
  border-right: 0px solid transparent !important;
}

.bor0l {
  border-left: 0px solid transparent !important;
}

.bor0r {
  border-right: 0px solid transparent !important;
}

.bor0tb {
  border-top: 0px solid transparent !important;
  border-bottom: 0px solid transparent !important;
}

.bor0t {
  border-top: 0px solid transparent !important;
}

.bor0b {
  border-bottom: 0px solid transparent !important;
}

.nowrap {
  white-space: nowrap;
}

.min-w-a {
  min-width: auto !important;
}

.toast {
  background-color: #0f70b7;
  font-size: 1.2rem;
  width: auto !important;
}

.toast-message {
  white-space: nowrap;
}

.nav-link {
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 0 !important;
  width: auto;
  color: #000;
}
@media only screen and (min-device-width: 882px) and (min-width: 882px) {
  .nav-link {
    width: 20rem !important;
  }
}

.nav-link.active {
  color: #ffffff !important;
  background-color: #0D3349 !important;
}

.nav-link:hover {
  background-color: aliceblue;
}

#toast-container > .toast-info {
  background-image: url("/images/favicon_io_2/favicon-32x32.png") !important;
  background-repeat: no-repeat !important;
}

.profile-nav {
  flex-flow: nowrap;
  flex-basis: auto !important;
  overflow-x: hidden;
  max-width: 98vw;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .profile-nav {
    overflow-x: scroll;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .card-md {
    display: flex;
    height: 250px;
    flex-basis: auto;
    justify-content: space-around;
    padding: 0 12px;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .mg12t-md {
    margin-top: 12px !important;
  }
}

.pg20l-md {
  padding-left: 20px !important;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .pg20l-md {
    padding-left: 0px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .text-center-md {
    text-align: center !important;
  }
}

.flex-items-center {
  display: flex;
  align-items: center;
}

.text-nowrap {
  white-space: nowrap;
}

.badge-complete {
  background-color: #f7b657;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spinner-icon::after {
  content: " ";
  display: block;
  position: absolute;
  left: 48%;
  top: 10%;
  width: 100px;
  height: 100px;
  border-style: solid;
  border-left-color: #d8ea5b;
  border-right-color: #a1bb5c;
  border-bottom-color: #0f70b7;
  border-top-color: transparent;
  border-width: 6px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.strike-through::after {
  border-top: 1px solid #999;
  width: 10%;
  margin-top: 10px;
  right: 0;
}
.strike-through::before {
  border-top: 1px solid #999;
  width: 10%;
  margin-top: 10px;
  left: 0;
}

.show.flex, .show.pop-ad-segment {
  display: flex !important;
}

.show2 {
  display: inline-block;
}

.social-or {
  width: 50px;
  height: 50px;
}
.social-or::before {
  border-left: 1px solid #ccc;
  height: 80px;
  top: -80px;
  z-index: 0;
}
.social-or::after {
  border-left: 1px solid #ccc;
  height: 100px;
  bottom: 0;
  top: 50px;
  z-index: 0;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .social-or::after, .social-or::before {
    display: none;
  }
}

.register-link-loaded .hide {
  display: inline-block !important;
  visibility: visible;
}
.register-link-loaded .show2 {
  display: none;
}

.hgt100vh {
  height: 100vh;
}

.hgt98vh {
  height: 98vh;
}

.file_upload_input {
  -moz-opacity: 0;
  opacity: 0;
  z-index: 2;
}

.mirror {
  transition: transform 0.8s;
  transform-style: preserve-3d;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.body-m {
  margin-top: 75px;
  min-height: calc(100vh - 75px);
}
@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .body-m {
    margin-top: 50px;
    min-height: calc(100vh - 50px);
  }
}

.brn {
  border: none;
}

.flex-grow {
  flex-grow: 1;
}

.home-navigator {
  display: flex;
  align-items: baseline;
  background-color: transparent;
  border: none;
}

.view-more-i {
  display: none;
}

.home-navigator img {
  height: 24px;
  margin-right: 0.5rem;
}

.home-navigator h4 {
  margin: 0;
  font-size: 1.6rem;
  color: #01425f;
}

.dropdown-item.news {
  padding: 0.5rem 0;
}

.user-icon-text {
  font-size: 1.5rem;
  margin-left: 0.5rem !important;
}

.home-news {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-news-title-container {
  background-color: black;
  padding: 1rem;
  width: 100%;
}

.home-news-title {
  color: white;
}

.home-art-row {
  display: flex;
  flex-direction: row;
  overflow-y: visible;
  overflow-x: auto;
  gap: 1rem;
  padding: 30px 0 30px 20px;
}
.home-art-row::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 6px;
  width: 10px;
}
.home-art-row::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

.home-row-container {
  display: flex;
  flex-direction: column;
  transition: all ease 0.2s;
  cursor: pointer;
  box-shadow: 1px 3px 10px 0 rgba(0, 0, 0, 0.1);
}

.home-rounded-container {
  position: relative;
  height: 32vw;
  min-height: 200px;
  border-radius: 5px;
  overflow: hidden;
  background-color: rgb(64, 75, 64);
}

.home-more-container {
  display: flex;
  align-items: center;
}

.home-more-holder {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-right: 50px;
  padding: 1rem;
  border-radius: 10px;
  background: none;
}

.home-more-holder.invert {
  background-color: black;
  color: white;
}

.home-more-holder span {
  text-align: center;
}

.home-more-news-i {
  padding: 1em;
  border: gray solid 1px;
  border-radius: 50px;
  width: 50px;
}

.home-art-size {
  min-width: 70%;
}

.home-source-pl {
  align-items: center;
}

.home-logo-stroke {
  padding: 1px;
  border-radius: 100%;
  background-color: #fafafa;
}

.home-art-logo-container {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #fafafa;
  -moz-background-clip: content-box;
  -webkit-background-clip: content-box;
}

.home-art-logo {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}

.medium-hide {
  display: block;
  margin-top: 20px;
}

.small-hide {
  display: none;
}

.small-hidef {
  display: none;
}

.rem-bord {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #e5e5e5;
}

.rem-round-bord {
  border: 1px solid #e5e5e5;
}

.ov-bord {
  border-radius: 5px;
  overflow: hidden;
}

.whitesmoke {
  background-color: #f5f5f5;
}

.news-subheader {
  position: sticky;
  top: 50px;
  z-index: 30;
  transition: all 300ms linear;
}

.news-title {
  background-color: #0e7ebe;
  border-bottom: #fff solid 1px;
}

.news-title h2 {
  color: white;
  margin: 0;
}

.selector-container {
  position: relative;
}

button.show-previous, button.show-next {
  position: absolute;
  top: 0;
  border: none;
  color: white;
  height: 100%;
  transition: all 300ms ease;
  opacity: 1;
  visibility: visible;
}
button.show-previous img, button.show-next img {
  height: 20px;
}
button.show-previous img.visitor, button.show-next img.visitor {
  position: absolute;
  top: 36%;
  z-index: 10;
}

.selector-container button.show-previous {
  padding: 0.5rem 1rem;
  left: 0;
  background: linear-gradient(270deg, transparent, rgba(14, 126, 190, 0.8509803922) 40%, rgba(14, 126, 190, 0.9019607843) 80%);
}
.selector-container button.show-previous img {
  transform: rotate(180deg);
}
.selector-container button.show-previous img.visitor {
  right: 46%;
}

.selector-container button.show-next {
  padding: 0.5rem 1rem;
  right: 0;
  background: linear-gradient(90deg, transparent, rgba(14, 126, 190, 0.8509803922) 40%, rgba(14, 126, 190, 0.9019607843) 80%);
}
.selector-container button.show-next img.visitor {
  left: 46%;
}

.selector-container button.show-previous.hide-more, .selector-container button.show-next.hide-more {
  visibility: hidden;
  opacity: 0;
}

.more-pulse-foreground {
  background-color: #000000;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 40%;
  left: 48%;
  border-radius: 10px;
  opacity: 1;
  transform: scale(1.6);
}

.more-pulse {
  background-color: white;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 40%;
  left: 48%;
  border-radius: 10px;
  opacity: 1;
  transform: scale(1.5);
  animation: pulse 1000ms ease-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

@keyframes pulse {
  0% {
    transform: scale(1.5);
  }
  50% {
    opacity: 0.3;
  }
  80% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
}
.section-selector {
  display: flex;
  background-color: #0e7ebe;
  overflow: auto;
  white-space: nowrap;
}

.news-subheader.is-hidden {
  transform: translateY(-110%);
}

.section-selector button {
  border: none;
  background-color: #0e7ebe;
  color: white;
  border-right: 0.5px solid white;
  padding: 0.5rem 1rem;
  flex-grow: 1;
}

.section-selector button::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #FFFFFF;
  transition: width 0.3s;
}

.section-selector button:hover:after {
  width: 100%;
}

.active-link {
  font-weight: 600;
  color: #0e7ebe !important;
  background-color: white !important;
}

.section-selector button.active-link:hover:after {
  width: 0%;
}

.separator {
  width: 1px;
  height: 20px;
  background-color: #666666;
  margin: 0 0.5rem;
}

.news-section-title {
  margin: 1rem;
  margin-bottom: 0;
  font-weight: 700 !important;
}

.article-holder {
  display: inline-block;
}

.article-card {
  width: 45vw !important;
  max-width: 45vw;
  height: 186px !important;
}

.article-div {
  width: 45vw !important;
  max-width: 45vw !important;
  height: 186px !important;
}

.article-row-div {
  width: 100%;
}

.card-image-container {
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  position: relative;
}

.article-image {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.article-row-image {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.article-title-font {
  font: normal normal bold Overpass;
  letter-spacing: 0.4px;
}

.article-title-home, .article-title {
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  font-weight: 600 !important;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 1.3em;
  float: left;
}

.article-title-home {
  min-height: 2em;
}

.article-row-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  float: left;
}

.article-body p {
  font-size: 1.15rem;
  font-family: "Source Serif Pro", sans-serif;
  white-space: pre-wrap;
}

.text-black {
  color: #000000 !important;
}

.linear-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(7, 7, 7, 0.7490196078) 100%);
}

.article-card-container {
  display: grid;
  width: 100%;
  grid-template-columns: 48% 48%;
  grid-row-gap: 1em;
  grid-column-gap: 1em;
}

.l-grid {
  position: relative;
  display: block;
  overflow: hidden;
  transition: all ease 200ms;
  background-color: #4d0000;
  animation: shift-background 300s infinite;
}

.l-img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
}

.md-grid {
  grid-column: 1/3;
  transition: all ease 200ms;
  position: relative;
  display: block;
  background-color: #4d0000;
  animation: shift-background 300s infinite;
  height: 450px;
  cursor: pointer;
}

.md-container {
  min-height: 230px;
  background-color: rgb(37, 28, 88);
}

.md-only-text-p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-size: 1.125rem;
  overflow: hidden;
}

.ad-space {
  grid-column: 3/5;
}

.c-grid {
  margin: 0 0.2em;
  transition: all ease 0.3s;
  animation-name: article-loaded;
  animation-duration: 1s;
  cursor: pointer;
  border: solid #e5e5e5 1px;
}

.go-wide {
  grid-column: 1/3;
  padding: 0;
}

.text-go-wide {
  padding: 0 0.5rem;
}

.article-row-container {
  display: grid;
  width: 100%;
  row-gap: 1rem;
}

.row-container {
  display: grid;
  grid-template-columns: 30% 70%;
  border: solid #e5e5e5 0.5px;
  margin: 0 1rem;
  transition: all ease 0.2s;
  cursor: pointer;
}

.row-container.opened {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.row-image-container {
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  position: relative;
}

.home-r-i-c {
  background-color: rgb(45, 65, 42);
  animation: shift-background 300s infinite;
}

.row-category {
  display: none;
}

.md-img {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
}

.vh {
  height: 50vh;
  min-height: 400px;
}

.space-between {
  justify-content: space-between;
}

span.card-source {
  color: #666666;
  height: 6ex;
  overflow: hidden;
}

.l-title {
  font-size: 1.5rem !important;
  margin-bottom: 1rem !important;
}

.md-title {
  font-family: "Source Serif Pro" !important;
  font-weight: 600 !important;
}

.fallback::before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #12bbd4;
}

.synopsis-large {
  display: none;
}

.synopsis-small {
  display: flex;
  flex-direction: column;
  grid-column: 1/3;
  padding: 0 2rem;
  max-height: 500px;
  overflow: hidden;
  transition: all ease 0.5s;
}

.synopsis-small p {
  font-family: "Overpass", sans-serif;
  font-size: 1rem;
  text-align: start;
  line-height: 1.8rem;
}

.synopsis-small button {
  border: none;
  margin: auto;
}

.synopsis-small .more {
  background-color: white;
  margin-bottom: 1em;
}

.synopsis-small .close {
  font-weight: 300 !important;
  padding: 0.5em 1em;
  color: black;
  font-family: "Source Serif Pro" !important;
  text-shadow: none;
  text-transform: uppercase;
  border: solid 0.5px;
  border-radius: 20px;
  font-size: 1rem;
}

.synopsis-mid {
  /*changed */
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  max-height: 500px;
  overflow: hidden;
  transition: all ease 0.5s;
}

.synopsis-mid p {
  /*changed */
  font-family: "Overpass", sans-serif;
  font-size: 1rem;
  text-align: start;
  line-height: 1.8rem;
}

.synopsis-mid button {
  border: none;
  margin: auto;
}

.synopsis-mid .more {
  background-color: white;
  margin-bottom: 1em;
}

.synopsis-mid .close {
  font-weight: 300 !important;
  padding: 0.5em 1em;
  color: black;
  font-family: "Source Serif Pro" !important;
  text-shadow: none;
  text-transform: uppercase;
  border: solid 0.5px;
  border-radius: 20px;
  font-size: 1rem;
}

.strim {
  max-height: 0 !important;
}

.advert, .mel-ad {
  display: flex;
  justify-content: center;
  background-color: #ccc;
  padding: 1rem 0;
}

.mel-ad {
  width: 100%;
}

.overlay {
  position: fixed;
  top: 50px;
  left: 0;
  z-index: 100;
  height: calc(100vh - 50px);
  background-color: rgba(5, 5, 5, 0.7);
  width: 100%;
  overflow: scroll;
  transition: all ease 300ms;
}

.pop-article {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 0;
  animation-name: pop-loaded;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.26, 0.66, 0.47, 1);
}

.pop-img-container {
  width: 100%;
  padding-top: 60%;
  overflow: hidden;
  position: relative;
  background-color: rgb(10, 29, 68);
}

.pop-img {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.pop-text-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem 2.5rem;
  border-bottom: solid #9e9e9e 0.5px;
}

.pop-text-container div {
  margin-top: 0.8rem;
}

.pop-title {
  font-size: 1.6rem;
}

.pop-synopsis {
  padding: 1.5rem 2.5rem;
  max-width: 600px;
  font-size: 1.15rem;
  font-family: "Source Serif Pro", sans-serif;
  white-space: pre-wrap;
}

.pop-article button {
  padding: 0.5em 2em;
  color: black;
  text-shadow: none;
  background-color: white;
  border: solid 0.5px;
  border-radius: 18%/54%;
  font-size: 1rem;
  text-decoration: none;
}

.x-close, .x-close-home {
  position: fixed;
  top: 70px;
  right: 4%;
  border: none;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0.8rem 1rem;
  animation-name: pop-close;
  animation-duration: 400ms;
  animation-timing-function: ease-in;
  z-index: 999;
}

.x-close img, .x-close-home img {
  height: 20px;
}

.x-close-home {
  position: inherit;
  margin-bottom: 1em;
}

.pop-iframe-container, .radio-iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.pop-iframe-container iframe, .radio-iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.radio-container {
  position: fixed;
  width: 100%;
  bottom: 0;
  transition: all ease 150ms;
}

.radio-iframe-container {
  padding-bottom: 130px;
}

.r-down {
  transform: translateY(85px);
}

.pop-ad-segment {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 400px) {
  .pop-ad-segment {
    flex-direction: row;
    column-gap: 1em;
  }
}

.pop-ad-img-container {
  height: 150px;
}

.pop-ad-container {
  padding: 1rem 0;
  max-width: 60%;
}

.pop-ad-container h4 {
  text-align: center;
}

.pop-package-container {
  border: #0e7ebe solid 1px;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  align-items: center;
  justify-content: space-between;
}

.pop-package-title {
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 12px;
  color: #0e7ebe;
}

.pop-ad-link {
  display: block;
  margin-top: 10px;
  background-color: #0e7ebe;
  text-align: center;
  border-radius: 50px;
  padding: 0.5rem;
  color: white;
  transition: all ease 250ms;
}

.pop-ad-link:hover {
  color: white;
  transform: scale(1.05);
}

.pop-package-i {
  background-color: #0e7ebe;
  padding: 5px;
  border-radius: 16px;
  color: white;
}

.showingPop {
  z-index: 100;
  transform: translateY(0);
  opacity: 1;
}

.share-container {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: #e2e2e2;
  margin-top: 1rem;
}

.share-container p {
  align-self: center;
  font-size: 1.2rem;
}

.social-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5rem;
}

.social-link {
  padding: 0.5rem 1rem;
  color: #525252;
  display: flex;
  justify-content: center;
}

.social-link:hover {
  color: white;
  text-decoration: none;
}

.float-share-container {
  right: 5%;
  bottom: 12%;
  display: flex;
  z-index: 99999;
  position: fixed;
  transition: all 250ms ease-in-out 0s;
  /* visibility: hidden; */
  flex-direction: column-reverse;
}

.float-share-main-button, .float-share-button {
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: block;
  z-index: 9999;
  position: relative;
  background: center center/cover no-repeat #0e7ebe;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 5px 0px, rgba(0, 0, 0, 0.12) 0px 2px 10px 0px;
  text-align: center;
  transition: all 250ms ease-in-out 0s;
  visibility: visible;
  user-select: none;
  border-radius: 50%;
  text-decoration: none;
  outline: none !important;
  max-width: none !important;
}

.float-share-main-button {
  width: 56px;
  height: 56px;
  margin: 0px auto;
}

.float-share-button {
  width: 42px;
  height: 42px;
  margin: 0px auto 10px;
  opacity: 1;
}

.float-share-main-button:hover {
  background-color: rgb(0, 0, 0);
}

.float-share-main-button i, .float-share-button i {
  top: 50%;
  left: 50%;
  color: rgb(255, 255, 255);
  position: absolute;
  font-size: 20px;
  transform: translate(-50%, -50%);
  text-align: center;
  transition: all 250ms ease-in-out 0s;
}

.share-icons-open {
  transform: translateY(40px);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.dismiss-pop {
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: -5;
  position: fixed;
  top: 0;
  left: 0;
}

.text-input {
  transition: all ease 300ms;
}
.text-input input:focus {
  outline: none;
}
.text-input input::placeholder {
  color: #868E96;
}
.text-input textarea:focus {
  outline: none;
}
.text-input textarea::placeholder {
  color: #868E96;
}
.text-input select:focus {
  outline: none;
}

.text-input:focus-within {
  border: solid #6da8d2 1px;
}

.article-fade-enter-active {
  transition: all 400ms cubic-bezier(0.26, 0.66, 0.47, 1);
  opacity: 1;
}

.article-fade-enter {
  transform: translateY(100px);
  opacity: 0;
}

.article-fade-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

@keyframes pop-loaded {
  from {
    transform: translateY(100px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes articles-loaded {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes pop-close {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes shift-background {
  0% {
    background-color: #4d0000;
  }
  10% {
    background-color: #4d2600;
  }
  20% {
    background-color: #4d4d00;
  }
  30% {
    background-color: #264d00;
  }
  40% {
    background-color: #004d00;
  }
  50% {
    background-color: #004d26;
  }
  60% {
    background-color: #00264d;
  }
  70% {
    background-color: #00004d;
  }
  80% {
    background-color: #26004d;
  }
  90% {
    background-color: #4d004d;
  }
  100% {
    background-color: #4d0000;
  }
}
@media (min-width: 760px) {
  .news-subheader {
    top: 75px;
  }
}
.alert-text {
  color: #ce3c49;
}

@media (min-width: 768px) {
  .overlay {
    top: 75px;
    height: calc(100vh - 75px);
  }

  .pop-article {
    width: 60%;
  }

  .home-art-size {
    min-width: 300px;
  }

  .news-section-title {
    margin: 1rem auto;
    font-weight: 700 !important;
  }

  .pop-ad-img-container {
    height: 200px;
  }

  .radio-container {
    position: fixed;
    width: 400px;
    bottom: 2%;
    right: 2%;
    transition: all ease 150ms;
  }

  .radio-iframe-container {
    padding-bottom: 31.25%;
  }

  .r-down {
    bottom: 0;
  }
}
@media (min-width: 1024px) {
  .home-art-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    overflow: visible;
    padding: 1rem 0;
  }

  .home-art-size {
    min-width: 37%;
  }

  .home-row-container:hover {
    transform: scale(1.05);
  }

  .home-more-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row: 4;
    grid-column: -4/-1;
    align-items: center;
    justify-content: center;
  }

  .home-more-container hr {
    position: absolute;
    border: 0.2px solid #ccc;
    width: 100%;
  }

  .home-more-holder {
    width: auto;
    border: solid 1px;
    border-radius: 20px;
    padding: 5px 10px;
    z-index: 1;
    background-color: white;
  }

  .home-more-news-i {
    display: none;
  }

  .view-more-i {
    display: inline-block;
  }

  .home-navigator img {
    height: 20px;
  }

  .home-rounded-container {
    height: 15vw;
  }

  .medium-hide {
    display: none;
  }

  .small-hide {
    display: block;
  }

  .small-hidef {
    display: flex;
  }

  .article-card-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1rem;
  }

  .md-grid {
    grid-row: 1/3;
    aspect-ratio: 0;
    height: 100%;
  }

  .md-grid:hover {
    transform: scale(1.05);
  }

  .c-grid:hover {
    transform: scale(1.05);
  }

  .md-img {
    height: 100%;
  }

  .h-f {
    height: 100%;
    background-color: rgb(75, 70, 10);
  }

  .card-image-container {
    border-radius: 0;
  }

  .article-image {
    border-radius: 0;
  }

  .article-row-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 2rem;
    width: 100%;
  }

  .l-grid {
    grid-column: 1/3;
    grid-row: 1/2;
    cursor: pointer;
  }

  .l-grid:hover {
    transform: scale(1.05);
  }

  .row-container {
    grid-template-columns: 1fr;
    margin: 0;
  }

  .row-container:hover {
    transform: scale(1.05);
  }

  .vh {
    height: 100%;
    min-height: 300px;
  }

  .row-span {
    font-size: 0.8rem;
  }

  .row-category {
    display: block;
    font-family: "Overpass", sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    margin-top: 0.5rem;
    color: #F3201F;
  }

  .row-no-image {
    font-size: 1.3rem;
    -webkit-line-clamp: 4;
    height: 9.6ex;
  }

  .synopsis-large {
    /* Changed */
    overflow: hidden;
    grid-column: 1/5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateY(-40px);
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 10%);
    padding: 2rem 1rem;
    transition: all ease 0.4s;
    max-height: 800px;
  }

  .synopsis-large p {
    /* Changed */
    align-self: center;
    max-width: 500px;
    font-family: "Source Serif Pro", sans-serif;
    font-size: 1rem;
  }

  .advert {
    grid-column: 3/5;
    align-items: center;
  }

  .trim {
    /* Changed */
    padding: 0;
    max-height: 0;
    transform: translateY(0) !important;
  }

  .synopsis-large button {
    /* Changed */
    border: none;
  }

  .synopsis-large button.more {
    /* Changed */
    background-color: white;
  }

  .synopsis-large button.close {
    /* Changed */
    font-weight: 300 !important;
    padding: 0.5em 1em;
    color: black;
    font-family: "Source Serif Pro" !important;
    text-shadow: none;
    text-transform: uppercase;
    border: solid 0.5px;
    border-radius: 20px;
    font-size: 1rem;
  }

  .faded {
    /* changed */
    opacity: 0.4;
    transform: scale(0.95);
  }

  .faded:hover {
    /* changed */
    opacity: 1;
  }

  .higher {
    /* changed */
    transform: translateY(-300px);
  }

  .synopsis-small {
    /* changed */
    display: none;
  }

  .synopsis-mid {
    display: none;
  }

  .text-go-wide {
    padding: 0;
  }

  .go-wide {
    grid-column: auto;
  }

  .social-container {
    grid-template-columns: repeat(8, 1fr);
  }
}
@media (max-width: 1023px) {
  .home-art-title {
    margin-left: 20px;
  }
}
@media (min-width: 1440px) {
  .pop-article {
    width: 45%;
  }
}
.hide-im {
  display: none !important;
}

.custom_radioboxes {
  cursor: pointer;
}
.custom_radioboxes span {
  padding: 15px 10px;
  background: #f7f9fc;
  border-radius: 3px;
  text-align: center;
  width: 100%;
  display: block;
}
.custom_radioboxes input {
  display: none;
}

.tooltipz {
  position: relative;
  display: inline-block;
}

.tooltipz .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -100px;
}

.tooltipz .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltipz:hover .tooltiptext {
  visibility: visible;
}

.w-full > div {
  width: 100%;
}
.w-full .modal-dialog {
  max-width: 100%;
}

progress {
  display: block;
  width: 200px;
  height: 20px;
  margin-bottom: 1em;
}

.reset progress {
  -webkit-appearance: none;
  appearance: none;
}
.dash-nav {
  height: calc(100vh - 75px);
  gap: 5rem;
  overflow: hidden;
  width: 200px;
  transition: all ease 300ms;
  z-index: 10;
}
@media (min-width: 1360px) {
  .dash-nav {
    width: 260px;
  }
}

.dash-link {
  transition: all ease-in-out 100ms;
  white-space: nowrap;
  align-items: center;
}
.dash-link:hover {
  border-left: solid 3px #0e7ebe;
  transform: translateX(10px);
}

.dash-active {
  border-radius: 0 30px 30px 30px;
}

.dash-active span, .dash-link span {
  opacity: 0;
  transition: all ease-in 150ms;
  height: 0;
  overflow: hidden;
}
@media (min-width: 1360px) {
  .dash-active span, .dash-link span {
    opacity: 1;
    height: auto;
  }
}

.dash-open {
  width: 260px;
  transition: all ease 300ms;
}
.dash-open .dash-active span, .dash-open .dash-link span {
  transition: all ease-in 150ms;
  opacity: 1;
  height: auto;
}

.dash-view {
  background-color: #F1F3F5;
  margin-left: 200px;
  transition: all ease 300ms;
}
@media (min-width: 1360px) {
  .dash-view {
    margin-left: 260px;
  }
}

#drawer-toggler {
  display: block;
  transition: all ease 300ms;
}
@media (min-width: 1360px) {
  #drawer-toggler {
    display: none;
  }
}
#drawer-toggler i {
  cursor: pointer;
}

/* line 1, ../scss/core.scss */
.select2-selection--multiple {
  overflow: hidden !important;
  height: auto !important;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

/* line 1, ../scss/_single.scss */
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 38px;
  user-select: none;
  -webkit-user-select: none;
}

/* line 12, ../scss/_single.scss */
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* line 25, ../scss/_single.scss */
.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

/* line 1, ../scss/_multiple.scss */
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  user-select: none;
  -webkit-user-select: none;
}

/* line 12, ../scss/_multiple.scss */
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* line 21, ../scss/_multiple.scss */
.select2-container .select2-search--inline {
  float: left;
}

/* line 24, ../scss/_multiple.scss */
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 3px;
  margin-left: 3px;
}

/* line 31, ../scss/_multiple.scss */
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

/* line 1, ../scss/_dropdown.scss */
.select2-dropdown {
  background-color: white;
  border: 1px solid #DDD;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

/* line 18, ../scss/_dropdown.scss */
.select2-results {
  display: block;
}

/* line 22, ../scss/_dropdown.scss */
.select2-results__options {
  list-style: none;
  list-style-type: none !important;
  margin: 0;
  padding: 0;
}

/* line 28, ../scss/_dropdown.scss */
.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

/* line 34, ../scss/_dropdown.scss */
.select2-results__option[aria-selected] {
  cursor: pointer;
}

/* line 39, ../scss/_dropdown.scss */
.select2-container--open .select2-dropdown {
  left: 0;
}

/* line 43, ../scss/_dropdown.scss */
.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* line 49, ../scss/_dropdown.scss */
.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* line 55, ../scss/_dropdown.scss */
.select2-search--dropdown {
  display: block;
  padding: 7px;
}

/* line 59, ../scss/_dropdown.scss */
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

/* line 64, ../scss/_dropdown.scss */
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

/* line 69, ../scss/_dropdown.scss */
.select2-search--dropdown.select2-search--hide {
  display: none;
}

/* line 15, ../scss/core.scss */
.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

/* line 1, ../scss/theme/default/_single.scss */
.select2-container--default .select2-selection--single {
  background-color: #f0f0f0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

/* line 6, ../scss/theme/default/_single.scss */
.select2-container--default .select2-selection--single:focus {
  outline: 0;
}

/* line 10, ../scss/theme/default/_single.scss */
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 34px;
}

/* line 15, ../scss/theme/default/_single.scss */
.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

/* line 21, ../scss/theme/default/_single.scss */
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

/* line 25, ../scss/theme/default/_single.scss */
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 36px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

/* line 35, ../scss/theme/default/_single.scss */
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

/* line 56, ../scss/theme/default/_single.scss */
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

/* line 60, ../scss/theme/default/_single.scss */
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

/* line 68, ../scss/theme/default/_single.scss */
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

/* line 72, ../scss/theme/default/_single.scss */
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

/* line 81, ../scss/theme/default/_single.scss */
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

/* line 1, ../scss/theme/default/_multiple.scss */
.select2-container--default .select2-selection--multiple {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  border-radius: 2px;
  cursor: text;
  height: 22px;
}

/* line 7, ../scss/theme/default/_multiple.scss */
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  list-style-type: none !important;
  padding: 0 0 0 4px !important;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

/* line 15, ../scss/theme/default/_multiple.scss */
.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}

/* line 23, ../scss/theme/default/_multiple.scss */
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: px;
  margin-right: 2px;
}

/* line 31, ../scss/theme/default/_multiple.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  color: #ffffff;
  background-color: #4a89dc;
  border-radius: 2px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 1px;
  padding: 1px 2px 2px !important;
}

/* line 46, ../scss/theme/default/_multiple.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

/* line 55, ../scss/theme/default/_multiple.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

/* line 63, ../scss/theme/default/_multiple.scss */
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__placeholder {
  float: right;
}

/* line 67, ../scss/theme/default/_multiple.scss */
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

/* line 72, ../scss/theme/default/_multiple.scss */
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

/* line 80, ../scss/theme/default/_multiple.scss */
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #CCC;
  outline: 0;
}

/* line 87, ../scss/theme/default/_multiple.scss */
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

/* line 92, ../scss/theme/default/_multiple.scss */
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

/* line 6, ../scss/theme/default/layout.scss */
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* line 13, ../scss/theme/default/layout.scss */
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* line 20, ../scss/theme/default/layout.scss */
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #DDD;
}

/* line 22, ../scss/theme/default/layout.scss */
.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  outline: 0;
}

/* line 29, ../scss/theme/default/layout.scss */
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
}

/* line 36, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
  padding: 2px !important;
}

/* line 42, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

/* line 46, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

/* line 50, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #EEE;
}

/* line 54, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

/* line 57, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

/* line 61, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

/* line 65, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

/* line 69, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

/* line 73, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

/* line 77, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

/* line 88, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #4a89dc;
  color: white;
}

/* line 93, ../scss/theme/default/layout.scss */
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.c-table tbody tr:nth-child(even) {
  background-color: #fbfbfb;
}
.c-table tbody tr:nth-child(odd) {
  background-color: #fff;
}
.c-table tbody tr:hover {
  background-color: aliceblue;
}
.c-table tbody button div.boo div {
  opacity: 0;
  transition: all ease 150ms;
}
.c-table tbody button:hover div.boo div {
  opacity: 1;
}

[hidden] {
  display: none !important;
}

.msa-wrapper {
  width: 400px;
}
.msa-wrapper:focus-within .input-presentation {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.msa-wrapper > * {
  display: block;
  width: 100%;
}
.msa-wrapper .input-presentation {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  align-items: center;
  min-height: 40px;
  padding: 6px 40px 6px 12px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 1rem;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}
.msa-wrapper .input-presentation .placeholder {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
.msa-wrapper .input-presentation:after {
  content: "";
  border-top: 6px solid black;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  right: 14px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.msa-wrapper .input-presentation.active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.msa-wrapper .input-presentation .tag-badge {
  background-color: blueviolet;
  padding-left: 14px;
  padding-right: 28px;
  color: white;
  border-radius: 14px;
  position: relative;
}
.msa-wrapper .input-presentation .tag-badge span {
  font-size: 16px;
  line-height: 27px;
}
.msa-wrapper .input-presentation .tag-badge button {
  display: inline-block;
  padding: 0;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  position: absolute;
  right: 0px;
  padding-right: 10px;
  padding-left: 5px;
  cursor: pointer;
  line-height: 26px;
  height: 26px;
  font-weight: 600;
}
.msa-wrapper .input-presentation .tag-badge button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}
.msa-wrapper ul {
  border: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 1rem;
  margin: 0;
  padding: 0;
  border-top: none;
  list-style: none;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.msa-wrapper ul li {
  padding: 6px 12px;
  text-transform: capitalize;
  cursor: pointer;
}
.msa-wrapper ul li:hover {
  background: blueviolet;
  color: white;
}

.blur {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(8px);
  /* for firefox */
  height: 98%;
  width: 100%;
  margin-top: 1%;
}
@-moz-document url-prefix() {
  .blur {
    -webkit-filter: blur(3px);
  }
}

.category-btn {
  background: transparent;
  border: none;
  font-weight: 400 !important;
}
.category-btn:focus {
  border: none;
  color: #000;
  background: transparent;
  box-shadow: none;
}
.category-btn:hover {
  border: none;
  color: #000;
  background: transparent;
}
.category-btn:active {
  border: none !important;
  color: #000 !important;
  background: transparent !important;
}

.category-menu {
  width: 850px;
  max-width: 100%;
  padding: 0 2em;
  position: fixed;
  top: 58px;
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .category-menu {
    margin-top: -25px;
  }
}
.category-menu ul.category-list {
  margin: 0px;
  list-style: none;
  display: flex;
  box-sizing: border-box;
  height: auto;
  flex-flow: row wrap;
  overflow-y: 0;
  padding: 0;
}

li.category-horizontal {
  flex: 0 1 25%;
  break-inside: avoid;
  letter-spacing: 0px;
  padding: 0.8rem;
  cursor: pointer;
  transition: color 0.2s ease-in-out 0s;
  color: rgb(27, 27, 27);
  text-transform: none;
  text-decoration: none;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  text-align: left;
  border-bottom: 1px solid #eee;
}
li.category-horizontal:hover {
  background: #ddd;
}
li.category-horizontal a.cat-h {
  font-size: 14px;
  width: 100%;
  text-decoration: none;
  color: inherit;
  display: flex;
  -moz-box-align: center;
  align-items: center;
}
li.category-horizontal a.cat-h div.icon-holder {
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  cursor: pointer;
  pointer-events: auto;
  color: rgb(27, 27, 27);
  height: 30px;
  width: 30px;
  margin-right: 0.5em;
}

.cRePYR {
  display: unset;
}

.bhwQda {
  display: flex;
  width: 100%;
  color: rgb(27, 27, 27);
  background-color: rgb(255, 255, 255);
  background-image: none;
}

.jwyqAU {
  width: 1140px;
  max-width: 1140px;
  margin: 0px auto;
  display: flex;
  -moz-box-pack: start;
  justify-content: start;
  -moz-box-align: center;
  align-items: center;
  text-align: left;
  padding-top: 2rem;
  padding-bottom: 2rem;
  overflow-y: visible;
}

.jwyqAU#why-zinio-row1 {
  flex-direction: column;
  -moz-box-align: start;
  align-items: start;
  z-index: 9;
  min-height: 330px;
}

.jhnIQP {
  transition: color 0.2s ease-in-out 0s;
  color: rgb(27, 27, 27);
  text-transform: none;
  text-decoration: none;
  text-align: inherit;
  font-style: normal;
  font-size: 36px;
}

.jwyqAU > p, .jwyqAU > h2, .jwyqAU > div {
  margin: 1rem 2rem;
  margin-top: 1rem;
  margin-left: 2rem;
  max-width: 640px;
}

.jwyqAU#why-zinio-row1 .H1Typography-id_kjd8qk {
  margin-left: 6.5rem;
  margin-top: 3rem;
  line-height: 1.33;
}

.clWpnh {
  display: inline-block;
  padding: 0.875rem 1rem;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none !important;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  pointer-events: auto;
  transition: all 0.2s ease-in-out 0s;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  white-space: nowrap;
  flex-shrink: 0;
  min-width: 110px;
  max-width: none;
  width: auto;
  color: rgb(255, 255, 255);
  background: #0F70B7 none repeat scroll 0% 0%;
}

.jwyqAU#why-zinio-row1 .StyledButton-id_136n8tf-a {
  margin-left: 18rem;
  margin-top: 1rem;
}

.jwyqAU#why-zinio-row2 {
  z-index: 8;
  text-align: center;
  align-items: flex-start;
  padding-bottom: 0px;
}

.jwyqAU#why-zinio-row2 picture:first-child {
  margin-top: -7.5rem;
}

.jwyqAU#why-zinio-row2 picture:nth-child(3) {
  margin-top: -15rem;
}

.jwyqAU img {
  width: 100%;
}

.jwyqAU > p, .jwyqAU > h2, .jwyqAU > div {
  margin: 1rem 2rem;
  max-width: 640px;
}

.jwyqAU > p, .jwyqAU > h2, .jwyqAU > div {
  margin: 1rem 2rem;
  max-width: 640px;
}

.jwyqAU#why-zinio-row2 div {
  margin: 0px;
}

.jwyqAU div.check-list {
  display: flex;
  flex-direction: column;
  -moz-box-align: start;
  align-items: start;
  text-align: left;
}

.jwyqAU#why-zinio-row5 .check-list > div, .jwyqAU#why-zinio-row6 .check-list > div {
  align-items: flex-start;
}

.jwyqAU#why-zinio-row5 .check-list p, .jwyqAU#why-zinio-row6 .check-list p {
  margin: 0.2rem 0px 1rem;
}

.QBpGt {
  transition: color 0.2s ease-in-out 0s;
  color: inherit;
  text-transform: none;
  text-decoration: none;
  text-align: inherit;
  font-style: normal;
  font-size: 36px;
}

.hFatkq {
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  padding: 0px;
  cursor: default !important;
  pointer-events: auto;
  color: rgb(255, 255, 255);
}
.hFatkq svg {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 0.3rem;
  transition: fill 0.2s ease-in-out 0s;
  fill: rgb(255, 255, 255);
}

.dqlIpj {
  transition: color 0.2s ease-in-out 0s;
  color: inherit;
  text-transform: none;
  text-decoration: none;
  text-align: inherit;
  font-style: normal;
  font-size: 22px;
}

.jwyqAU#why-zinio-row2 div > h2 {
  margin-top: 0px;
}

.jwyqAU#why-zinio-row3 picture {
  margin-bottom: -12rem;
}

.jwyqAU#why-zinio-row3, .jwyqAU#why-zinio-row7 {
  text-align: center;
  z-index: 7;
  align-items: flex-start;
  padding-top: 0px;
}

.jwyqAU#why-zinio-row7 {
  padding-top: 40px;
  height: auto;
}

.jwyqAU#why-zinio-row3 p {
  margin-top: 0px;
}

.jwyqAU#why-zinio-row4, .jwyqAU#why-zinio-row8 {
  flex-direction: column;
  text-align: center;
  z-index: 6;
}

.jwyqAU#why-zinio-row5 {
  z-index: 5;
}

.dqlIpj {
  transition: color 0.2s ease-in-out 0s;
  color: inherit;
  text-transform: none;
  text-decoration: none;
  text-align: inherit;
  font-style: normal;
  font-size: 22px;
}

.lyaza {
  display: flex;
  width: 100%;
  color: rgb(255, 255, 255);
  background-color: rgb(3, 169, 244);
  background-image: linear-gradient(to right, #0F70B7 0%, #06426F 100%);
}

.kEqdPW {
  display: none;
}

@media only screen and (min-width: 993px) {
  .jwyqAU#why-zinio-row4 .H2Typography-id_kjd8qk {
    flex: 3 1 0%;
  }

  .jwyqAU#why-zinio-row4 {
    flex-direction: row;
    padding-top: 18rem;
  }

  .jwyqAU#why-zinio-row8 {
    flex-direction: row;
    padding-top: 13rem;
  }

  .jwyqAU#why-zinio-row4 picture {
    flex: 5 1 0%;
  }

  .jwyqAU#why-zinio-row5 {
    align-items: flex-start;
  }

  .jwyqAU#why-zinio-row5 picture {
    flex: 5 1 0%;
  }

  .jErAQb {
    display: none;
  }

  .jwyqAU#why-zinio-row7 {
    padding-top: 100px;
    font-size: 36px;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1140px) {
  .jwyqAU {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media only screen and (min-width: 577px) and (max-width: 992px) {
  .jErAQb {
    display: unset;
  }

  .jwyqAU {
    min-height: 220px;
  }
}
@media only screen and (max-width: 992px) {
  .cRePYR {
    display: none;
  }

  .jwyqAU {
    width: 100%;
  }

  .jwyqAU {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .jwyqAU#why-zinio-row1 {
    min-height: 500px;
    -moz-box-align: center;
    align-items: center;
    text-align: center;
  }

  .jwyqAU#why-zinio-row1 .title {
    padding-bottom: 2.5rem;
    padding-top: 1.5rem;
    margin: 2rem 0px 0px;
    line-height: inherit;
  }

  .jwyqAU#why-zinio-row1 .StyledButton-id_136n8tf-a {
    margin: inherit;
  }

  .jwyqAU#why-zinio-row2 div {
    align-self: center;
  }

  .jwyqAU#why-zinio-row2 picture {
    margin-top: -11rem;
  }

  .jwyqAU#why-zinio-row3 {
    -moz-box-align: center;
    align-items: center;
  }

  .jwyqAU#why-zinio-row3 picture {
    margin-bottom: unset;
    max-width: 40%;
  }

  .jwyqAU#why-zinio-row3 p {
    margin: 0px 0px 1rem;
  }

  .jwyqAU#why-zinio-row4 picture:first-of-type {
    max-width: 70%;
    align-self: flex-end;
    margin-top: -6rem;
  }

  .jwyqAU#why-zinio-row4 p {
    margin-top: 2rem;
  }

  .jwyqAU#why-zinio-row4 picture:last-of-type {
    max-width: 75%;
  }

  .jwyqAU#why-zinio-row5 {
    flex-direction: column-reverse;
  }
  .jwyqAU#why-zinio-row5 div {
    width: 100%;
  }
  .jwyqAU#why-zinio-row5 .image-wrapper {
    margin: 1rem 0px;
    max-width: unset;
    display: flex;
  }

  .jwyqAU#why-zinio-row5 .image-wrapper picture:last-of-type {
    margin-bottom: -4rem;
  }

  .jwyqAU#why-zinio-row5 .check-list > div > svg, .jwyqAU#why-zinio-row6 .check-list > div > svg {
    width: 24px;
    height: 24px;
  }

  .jwyqAU#why-zinio-row5 .check-list p, .jwyqAU#why-zinio-row6 .check-list p {
    margin: 0px 0px 1rem;
  }

  .lyaza:nth-of-type(5):not([type=WHITE]) {
    margin-bottom: -3rem;
  }
}
@media only screen and (max-width: 576px) {
  .jErAQb {
    display: none;
  }

  .kEqdPW {
    display: unset;
  }

  .jwyqAU#why-zinio-row1 {
    min-height: 380px;
  }

  .jwyqAU#why-zinio-row1 picture {
    align-self: center;
    margin-top: 3rem;
  }

  .jwyqAU#why-zinio-row2 {
    flex-direction: column-reverse;
    padding: 0px 1rem;
  }

  .jwyqAU#why-zinio-row2 > div {
    margin: 17rem 0px 2rem;
    width: 100%;
  }

  .jwyqAU#why-zinio-row3 {
    flex-direction: column-reverse;
  }

  .jwyqAU#why-zinio-row3 picture {
    padding-top: 1rem;
    margin-bottom: -14rem;
    max-width: 75%;
  }

  .bhwQda:nth-of-type(4):not([type=GREY]) {
    margin-top: 8rem;
  }

  .jwyqAU#why-zinio-row4 {
    gap: 0.5rem;
  }

  .jwyqAU#why-zinio-row4 picture:first-of-type {
    padding: 0.5rem 0px;
    width: 100%;
    align-self: center;
    margin-top: 0px;
    max-width: unset;
  }

  .jwyqAU#why-zinio-row4 picture:last-of-type {
    max-width: unset;
  }

  .jwyqAU#why-zinio-row5 {
    text-align: center;
    padding-top: 0px;
  }

  .jwyqAU#why-zinio-row5 picture {
    width: 100%;
  }

  .jwyqAU#why-zinio-row5 > div {
    width: 90%;
  }

  .lyaza:nth-of-type(2) {
    margin-top: -20rem;
  }

  .lyaza:nth-of-type(5):not([type=WHITE]) {
    padding-bottom: 4rem;
    margin-bottom: 0px;
  }
}
.article-container {
  display: flex;
  -moz-box-pack: justify;
  justify-content: start;
  gap: 2em;
}

.article-in-card {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 0px;
  min-width: 400px;
}
.article-in-card.explore {
  min-width: auto;
  margin-bottom: 0;
}
.article-in-card .article-in-card-div {
  padding: 0 0.8em 0.8em 0.8em;
  box-shadow: 0px 0px 3px #c6c6c6;
  background: #fff;
  border-radius: 4px;
}
.article-in-card article-info {
  display: block;
  margin: 0.5rem 0px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.article-in-card article-info span {
  font-weight: 400;
  transition: color 0.2s ease-in-out 0s;
  color: rgb(27, 27, 27);
  text-decoration: none;
  text-align: inherit;
  font-style: normal;
  font-size: 14px;
}
.article-in-card article-info .article-publication {
  color: #1475bc;
  border-right: solid 1px #000;
  padding-right: 0.5rem;
}
.article-in-card .article-content {
  display: flex;
  flex-direction: column;
  height: 170px;
}
.article-in-card .article-content article-title {
  flex-shrink: 0;
  margin: 0.35rem 0px 0px;
  overflow: hidden;
  max-height: 44px;
  height: 44px;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: color 0.2s ease-in-out 0s;
  color: rgb(27, 27, 27);
  text-transform: uppercase;
  text-decoration: none;
  text-align: inherit;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  font-size: 18px;
}
.article-in-card .article-content .article-excerpt-container {
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
  -moz-box-flex: 0;
  flex-grow: 0;
  flex-shrink: 1;
  height: 144px;
  overflow: hidden;
}
.article-in-card .article-content .article-excerpt-container .article-excerpt {
  margin: 0.5rem 0.5rem 0.5rem 0px;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  font-weight: 400;
  transition: color 0.2s ease-in-out 0s;
  color: rgb(103, 104, 111);
  text-transform: none;
  text-decoration: none;
  text-align: inherit;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
}
.article-in-card .article-content .article-excerpt-container .article-image-small {
  align-self: center;
  margin: 0.5rem 1px 1px 1rem;
}
.article-in-card .article-content .article-excerpt-container .article-image-small img {
  display: block;
  width: 90px;
  height: 100px;
  object-fit: cover;
  object-position: center center;
  filter: none;
  opacity: 1;
  aspect-ratio: 977/1498;
  border-radius: 4px;
}
.article-in-card .article-time {
  display: flex;
  -moz-box-pack: start;
  justify-content: flex-start;
  margin: 0.5rem 0px 0px;
  -moz-box-align: center;
  align-items: center;
  padding: 0px;
  cursor: default !important;
  pointer-events: auto;
  color: rgb(27, 27, 27);
}
.article-in-card .article-time svg {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 0.3rem;
  transition: fill 0.2s ease-in-out 0s;
  fill: rgb(27, 27, 27);
}
.article-in-card .article-time span {
  font-weight: 400;
  transition: color 0.2s ease-in-out 0s;
  color: rgb(27, 27, 27);
  text-transform: none;
  text-decoration: none;
  text-align: inherit;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
}

.explore-grid {
  display: grid;
  max-width: 1140px;
  margin: 2em auto;
  grid-template-columns: repeat(3, 1fr);
  gap: 2em;
  padding: 0 1em;
}
@media only screen and (max-width: 992px) {
  .explore-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 550px) {
  .explore-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.width-container {
  max-width: 1140px;
  width: 1140px;
  display: flex;
  margin: 0 auto;
}
@media only screen and (max-width: 1140px) {
  .width-container {
    width: 100%;
  }
}

.article-viewer-container {
  margin-top: 40px;
  display: flex;
  width: 100%;
  flex-direction: row;
}
@media only screen and (max-width: 1140px) {
  .article-viewer-container {
    flex-direction: column;
  }
}
.article-viewer-container #article-container {
  width: 100%;
}
.article-viewer-container #article-container #article-preview {
  padding: 1em 1.2em;
  display: flex;
  flex-direction: column;
}
.article-viewer-container #article-container #article-preview .article-section-title {
  color: gray;
  font-weight: lighter;
  border-bottom: 1px solid #ddd;
  margin-bottom: 1em;
  font-size: 14px;
}
.article-viewer-container #article-container #article-preview .article-header {
  font-size: 24px;
  line-height: 1.8rem;
  margin-bottom: 0.5em;
}
.article-viewer-container #article-container #article-preview .article-author {
  font-style: italic;
  color: #808080;
  font-weight: lighter;
}
.article-viewer-container #article-container #article-preview img {
  margin: 2em auto;
  max-width: 100%;
  max-height: 400px;
}
.article-viewer-container #article-container #article-preview .article-excerpt::after {
  content: "";
  position: relative;
  display: block;
  height: 6rem;
  margin-top: -6rem;
  background: rgba(0, 0, 0, 0) linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 95%) repeat scroll 0% 0%;
}
.article-viewer-container #article-container #article-preview .article-lock-container {
  text-align: center;
}
.article-viewer-container #article-container #article-preview .article-lock-container .article-lock-icon {
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  padding: 0px 1rem;
  cursor: default !important;
  pointer-events: auto;
  color: rgb(27, 27, 27);
}
.article-viewer-container #article-container #article-preview .article-lock-container .article-lock-icon svg {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 0px;
  transition: fill 0.2s ease-in-out 0s;
  fill: rgb(27, 27, 27);
}
.article-viewer-container #article-cover-container {
  max-width: 240px;
  padding-bottom: 2em;
  margin-top: 5em;
}
@media only screen and (max-width: 1140px) {
  .article-viewer-container #article-cover-container {
    margin: 2rem 0px;
    align-self: center;
  }
}
.article-viewer-container #article-cover-container .article-cover {
  display: flex;
  flex-direction: column;
  -moz-box-align: center;
  align-items: center;
  position: sticky;
  top: 20%;
}
.article-viewer-container #article-cover-container .article-cover a {
  text-decoration: none;
}
.article-viewer-container #article-cover-container .article-cover a img {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  box-shadow: rgba(27, 27, 27, 0.055) 0px 4px 8px, rgba(27, 27, 27, 0.06) 0px 3px 8px, rgba(27, 27, 27, 0.04) 0px 0px 4px;
  margin-bottom: 1rem;
}
.article-viewer-container #article-cover-container .article-cover #article-cover-button {
  margin-top: 1rem;
  display: inline-block;
  padding: 0.875rem 1rem;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  pointer-events: auto;
  transition: all 0.2s ease-in-out 0s;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  white-space: nowrap;
  flex-shrink: 0;
  min-width: 200px;
  width: auto;
  color: rgb(255, 255, 255);
  background: #1070b7 none repeat scroll 0% 0%;
}

.international-highlight {
  overflow-x: scroll;
  display: flex;
  scroll-snap-type: x mandatory;
}
.international-highlight .highlight-container {
  min-width: 100%;
  display: flex;
  gap: 1em;
  justify-content: center;
  scroll-snap-align: start;
  padding: 1rem 0;
}
.international-highlight .highlight-container i {
  color: #1070b7;
}
.international-highlight .highlight-container span {
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 1px;
  font-weight: 300;
  color: #000;
}
@media screen and (min-width: 920px) {
  .international-highlight {
    justify-content: space-between;
  }
  .international-highlight .highlight-container {
    min-width: auto;
  }
}

.bgff.bgee, .bgee.category-dropdown-content, .search-all input.bgff, .search-all input.category-dropdown-content {
  background-color: #eee !important;
}

.sm:my-8 {
  width: 66.6666666667%;
}

.sm:align-middle {
  justify-items: center;
}

.sm:w-full {
  width: 100%;
}

.sm:max-w-md {
  max-width: 800px;
}

.dashboard-stats .dashboard-stats-section-1 {
  width: 100%;
}
.dashboard-stats .dashboard-stats-section-2 {
  width: 50%;
}
.dashboard-stats .dashboard-stats-section-2:first-child {
  padding-right: 10px;
}
.dashboard-stats .dashboard-stats-section-2:last-child {
  padding-right: 10px;
}
.dashboard-stats .dashboard-stats-section-3 {
  width: 33.3333%;
}
.dashboard-stats .dashboard-stats-section-3:first-child {
  padding-right: 10px;
}
.dashboard-stats .dashboard-stats-section-3:last-child {
  padding-right: 10px;
}
.dashboard-stats .dashboard-stats-section-4 {
  width: 25%;
}
.dashboard-stats .dashboard-stats-section-4:first-child {
  padding-right: 10px;
}
.dashboard-stats .dashboard-stats-section-4:last-child {
  padding-right: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.international-payment-card-text * {
  font-size: 12px !important;
  padding-left: 10px;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .international-payment-card-text * {
    font-size: 11px !important;
  }
}

.international-package-header {
  font-size: 25px;
  text-shadow: 2px 2px rgba(43, 43, 43, 0.5490196078);
}
.international-package-header .international-pub-name {
  font-size: 25px;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .international-package-header {
    font-size: 12px;
  }
  .international-package-header span {
    font-size: 12px;
  }
  .international-package-header span.international-pub-name {
    font-size: 20px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .issue-img {
    max-width: 35% !important;
  }
}

.international-package-header-image {
  aspect-ratio: 1/1;
  height: 100px;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .international-package-header-image {
    width: 40px;
    height: 60px;
  }
}

.international-package-table .table td, .international-package-table .table th {
  border: none;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .international-package-table {
    margin-bottom: 0 !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .international-package-table .custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    margin-left: 7px;
  }
  .international-package-table .table td, .international-package-table .table th {
    padding: 0.3rem;
    font-size: 14px;
  }
  .international-package-table .table th {
    padding: 0.3rem 7px;
    font-size: 11px;
  }
  .international-package-table .upp {
    text-transform: uppercase;
    font-size: 11px;
  }
  .international-package-table .table .mg0b {
    margin-bottom: 0 !important;
    font-size: 12px;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .custom-control-label::before, .custom-control-label::after {
    top: 1px;
  }
}

.width-f-max {
  max-width: 100%;
}

@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .issue-img {
    max-width: 35% !important;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
  left: -20px;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  left: -20px;
}

.custom-radio .custom-control-label::before {
  left: -20px;
}

.owl-carousel, .pods .card, .pods .card img, .hero, .bg-primary-1 {
  height: 320px;
  object-fit: cover;
}

.bg-primary-1 {
  --bs-bg-opacity: 1;
  background-color: rgba(20, 139, 208, 0.8666666667) !important;
}

.image-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 900px;
}

.pods .card {
  background-image: url("images/blob.png");
  background-size: cover;
}

.image-row a {
  flex: 0 0 auto;
  width: 120px !important;
  /* Changed width to max-width */
  height: auto;
  box-shadow: 2px 6px 14px rgb(0, 0, 0);
  aspect-ratio: 5/7;
}
.image-row a.square {
  aspect-ratio: 1/1;
}

.image-row a:first-child,
.image-row a:last-child {
  flex: 0 0 15%;
}

.image-row a:nth-child(2),
.image-row a:nth-child(4) {
  flex: 0 0 20%;
}

.image-row a:nth-child(3) {
  flex: 0 0 23%;
}

.play {
  border-radius: 50%;
  font-size: 15px;
  padding: 7px 8px 7px 10px;
  margin-top: -12px;
}

.owl-carousel .pod-img {
  object-fit: scale-down;
  aspect-ratio: 1/1;
  align-items: self-start;
}

.scroll {
  width: 100%;
  overflow-x: scroll;
  display: flex;
}

.scroll-item img {
  width: 168px !important;
}

.hero img {
  aspect-ratio: 5/7;
}

.scroll-item {
  margin-right: 10px;
}

.rounded-full {
  border-radius: 9999px;
  position: absolute;
  right: 5px;
  height: 25px;
  width: 25px;
  text-align: center;
}

.rounded-full svg {
  margin-top: -1px;
}

.rad {
  border-radius: 0 0 0 5px;
  right: 0;
}

.radios .scroll-item img {
  height: 168px;
  object-fit: cover;
}

.rounded-full .icon {
  width: 15px !important;
  height: 15px !important;
}

.mg24b {
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .image-row a:nth-child(1),
.image-row a:nth-child(2),
.image-row a:nth-child(4),
.image-row a:nth-child(5) {
    display: none;
  }

  .image-row a:nth-child(3) {
    flex: 0 0 60%;
  }

  .image-row a {
    height: 140px;
  }

  h1 {
    font-size: 1.5rem;
  }

  .mg24b {
    margin-bottom: 12px;
  }

  .col-4,
.col-8 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .owl-carousel, .pods .card, .pods .card img, .hero, .bg-primary-1 {
    height: 260px;
  }

  .rounded-full .icon {
    width: 20px !important;
    height: 20px !important;
  }
}
.hero.item .card {
  flex-direction: row;
}

.menu-small-scroll {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.menu-small-scroll::-webkit-scrollbar {
  display: none;
}
.menu-small-scroll ul {
  min-width: 0;
}

.dropdown-menu-right {
  left: -171px;
}
.dropdown-menu-right.is-on {
  left: -148px;
}
@media screen and (max-width: 768px) {
  .dropdown-menu-right.is-on {
    left: -171px;
  }
}

.profile-dropdown a {
  padding: 0.5rem 1.5rem;
  font-size: 0.875rem !important;
  font-weight: bold;
}
.profile-dropdown a:hover {
  background-color: #3b83f6;
  color: #fff;
  border-radius: 0.5rem;
}
.profile-dropdown .divide {
  padding: 0;
  border: #e5e5e5 solid 0.5px;
}

/*! ========================================================================
 * Bootstrap Toggle: bootstrap-toggle.css v2.2.0
 * http://www.bootstraptoggle.com
 * ========================================================================
 * Copyright 2014 Min Hur, The New York Times Company
 * Licensed under MIT
 * ======================================================================== */
.checkbox label .toggle,
.checkbox-inline .toggle {
  margin-left: -20px;
  margin-right: 5px;
}

.toggle {
  position: relative;
  overflow: hidden;
}

.toggle input[type=checkbox] {
  display: none;
}

.toggle-group {
  position: absolute;
  width: 200%;
  top: 0;
  bottom: 0;
  left: 0;
  transition: left 0.35s;
  -webkit-transition: left 0.35s;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.toggle.off .toggle-group {
  left: -100%;
}

.toggle-on {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.toggle-off {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: #ddd;
}

.toggle-handle {
  position: relative;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
  width: 0;
  border-width: 0 1px;
}

.toggle.btn {
  width: 100% !important;
  min-width: 59px;
  min-height: 34px;
}

.toggle-on.btn {
  padding-right: 24px;
}

.toggle-off.btn {
  padding-left: 24px;
}

.toggle.btn-lg {
  min-width: 79px;
  min-height: 45px;
}

.toggle-on.btn-lg {
  padding-right: 31px;
}

.toggle-off.btn-lg {
  padding-left: 31px;
}

.toggle-handle.btn-lg {
  width: 40px;
}

.toggle.btn-sm {
  min-width: 50px;
  min-height: 30px;
}

.toggle-on.btn-sm {
  padding-right: 20px;
}

.toggle-off.btn-sm {
  padding-left: 20px;
}

.toggle.btn-xs {
  min-width: 35px;
  min-height: 22px;
}

.toggle-on.btn-xs {
  padding-right: 12px;
}

.toggle-off.btn-xs {
  padding-left: 12px;
}

.publications_load_all {
  width: 100%;
}
.publications_load_all > a {
  width: 20%;
  height: 130px;
  float: left;
  padding: 0px;
  overflow: hidden;
  border: 2px solid transparent;
  max-width: 87px;
}
@media only screen and (min-device-width: 1px) and (max-device-width: 881px), (min-width: 1px) and (max-width: 881px) {
  .publications_load_all > a {
    width: 33%;
    max-width: none;
  }
}
.publications_load_all > a:hover {
  background: #0f70b7;
  border: 2px solid #0f70b7;
}

.inner-book {
  width: 100%;
}

.book {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateZ(0);
}

.inner-book {
  display: flex;
  align-items: center;
  transform-style: preserve-3d;
  perspective: 2000px;
}

.inner-book {
  display: flex;
  align-items: center;
  transform-style: preserve-3d;
  perspective: 2000px;
}

.inner-book:before {
  content: "";
  width: 100%;
  position: absolute;
  height: 0;
  bottom: 14px;
  transform: rotateY(-20deg) translateZ(-20px);
  z-index: 0;
  box-shadow: 3px 5px 20px 9px rgba(0, 0, 0, 0.3);
}

.inner-book .img {
  transform: rotateY(-20deg);
  width: 100%;
  z-index: 5;
  clear: both;
  height: 100%;
  background: #ddd;
  margin-left: -8px;
}

.inner-book img {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
}

.inner-book .img:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-left: 4px solid rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.page {
  width: calc(100% - 6px);
  height: calc(100% - 2px);
  position: absolute;
  box-shadow: inset 0px -1px 2px rgba(50, 50, 50, 0.2), inset -1px 0px 1px rgba(150, 150, 150, 0.1);
  border-radius: 0px 3px 3px 0px;
  transform: rotateY(-20deg) translateZ(-5px);
  left: 0px;
  z-index: 4;
  background: white;
}

.page-2 {
  width: calc(100% - 3px);
  height: calc(100% - 4px);
  z-index: 3;
}

.page-3 {
  width: calc(100% + 0px);
  height: calc(100% - 6px);
  z-index: 2;
}

.img.final-page {
  position: absolute;
  z-index: -1;
  width: calc(100% + 10px);
  transform: rotateY(-19deg) translateZ(-10px) scale(0.984);
}

.mt-0.width-f.pl, .mt-0.pl.pop-ad-segment, .pagination .page-item .mt-0.pl.page-link, a.mt-0.width-f.category-link, a.mt-0.pop-ad-segment.category-link, .pagination .page-item a.mt-0.page-link.category-link {
  border-top: 1px solid rgba(0, 0, 0, 0.7);
}

@media (max-width: 456px) {
  .css-1s802wv, .css-162lbvj, .css-1ofj64s {
    font-size: 12px !important;
    font-weight: 500;
  }

  .btm-100 {
    width: 100%;
  }

  .logo-img {
    height: 30px;
  }

  .css-1g8ewsb-Button {
    line-height: 1;
  }

  .css-11g480x-InputBox {
    height: 2.5rem !important;
  }

  .css-1g8ewsb-Button {
    line-height: 17px;
    font-size: 13px;
  }

  .container-fluid.width-f.pl.pg20tb.home-load-div.px-0, .container-fluid.pl.pg20tb.home-load-div.px-0.pop-ad-segment, .pagination .page-item .container-fluid.pl.pg20tb.home-load-div.px-0.page-link, a.container-fluid.width-f.pg20tb.home-load-div.px-0.category-link, a.container-fluid.pg20tb.home-load-div.px-0.pop-ad-segment.category-link, .pagination .page-item a.container-fluid.pg20tb.home-load-div.px-0.page-link.category-link,
.bgff.body-m,
.body-m.category-dropdown-content {
    margin-top: 20px;
  }
}
.text-small {
  font-size: 13px;
  font-weight: 500;
}

.international-package-table * {
  align-items: center;
}

.det .custom-control-label::before,
.det .custom-control-label::after {
  top: -0.2rem !important;
}

@media screen and (min-width: 576px) {
  .c-40 {
    width: 40% !important;
  }

  .c-60 {
    width: 60% !important;
  }

  .d-m-flex {
    display: flex !important;
  }

  .issue-img {
    max-width: 91% !important;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .c-40 {
    margin: auto;
  }

  .c-60 {
    max-width: 390px !important;
    margin: auto;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .flex-sm-column {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
}
@media (min-width: 502px) and (max-width: 992px) {
  .btm-100 {
    width: 100%;
  }

  .text-small {
    font-size: 13px;
    font-weight: 500;
    text-align: left !important;
  }
}
@media (min-width: 992px) {
  .text-small {
    font-size: 13px;
    font-weight: 500;
    text-align: left;
  }
}
.titling,
.titling b {
  font-size: 18px;
}

@media (min-width: 992px) {
  .text-small {
    text-align: left !important;
  }
}
.max-w-700 {
  max-width: 700px !important;
}

.new-carousel .carousel {
  position: relative;
}
.new-carousel .carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.new-carousel .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.new-carousel .carousel-inner:after {
  display: block;
  clear: both;
  content: "";
}
.new-carousel .carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .new-carousel .carousel-item {
    transition: none;
  }
}
.new-carousel .carousel-item-next,
.new-carousel .carousel-item-prev,
.new-carousel .carousel-item.active {
  display: block;
}
.new-carousel .active.carousel-item-right,
.new-carousel .carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.new-carousel .active.carousel-item-left,
.new-carousel .carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.new-carousel .carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}
.new-carousel .carousel-fade .carousel-item-next.carousel-item-left,
.new-carousel .carousel-fade .carousel-item-prev.carousel-item-right,
.new-carousel .carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}
.new-carousel .carousel-fade .active.carousel-item-left,
.new-carousel .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media screen and (prefers-reduced-motion: reduce) {
  .new-carousel .carousel-fade .active.carousel-item-left,
.new-carousel .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}
.new-carousel .carousel-control-next,
.new-carousel .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .new-carousel .carousel-control-next,
.new-carousel .carousel-control-prev {
    transition: none;
  }
}
.new-carousel .carousel-control-next:focus,
.new-carousel .carousel-control-next:hover,
.new-carousel .carousel-control-prev:focus,
.new-carousel .carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.new-carousel .carousel-control-prev {
  left: 0;
}
.new-carousel .carousel-control-next {
  right: 0;
}
.new-carousel .carousel-control-next-icon,
.new-carousel .carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat 50%;
  background-size: 100% 100%;
}
.new-carousel .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3E%3C/svg%3E");
}
.new-carousel .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3E%3C/svg%3E");
}
.new-carousel .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.new-carousel .carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
.new-carousel .carousel-indicators button {
  opacity: 1;
  background: #d9d9d9;
  border: none;
  margin-right: 6px;
  width: 33px;
  padding: 3px;
  border-radius: 8px;
}
.new-carousel .carousel-indicators .active {
  background: #1475bc;
}
@media screen and (prefers-reduced-motion: reduce) {
  .new-carousel .carousel-indicators li {
    transition: none;
  }
}
.new-carousel .carousel-indicators .active {
  opacity: 1;
}
.new-carousel .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.simplebar-scroll-content {
  overflow-x: hidden !important;
  overflow-y: scroll;
  min-width: 100% !important;
  max-height: inherit !important;
  box-sizing: content-box !important;
}

.simplebar-content {
  overflow-y: hidden !important;
  overflow-x: scroll;
  box-sizing: border-box !important;
  min-height: 100% !important;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 11px;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 7px;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #000;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-track .simplebar-scrollbar.visible:before,
.simplebar-track:hover .simplebar-scrollbar:before {
  opacity: 0.5;
  transition: opacity 0 linear;
}

.simplebar-track.vertical {
  top: 0;
}

.simplebar-track.vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.horizontal {
  left: 0;
  width: auto;
  height: 11px;
}

.simplebar-track.horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.horizontal.simplebar-track .simplebar-scrollbar {
  right: auto;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

.sidebar {
  min-width: 255px;
  max-width: 255px;
  border-right: 1px solid #e5e9f2;
}

.sidebar,
.sidebar-content {
  transition: all 0.4s ease-in-out;
  background: #fff;
}

.sidebar-sticky .sidebar-content {
  border-right: 1px solid #e5e9f2;
}

.sidebar-sticky .sidebar-nav {
  padding-bottom: 0;
}

.sidebar-sticky .sidebar-content {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 255px;
}

.sidebar-nav {
  padding-left: 0;
  list-style: none;
}

.sidebar-link .feather,
.sidebar-link svg {
  margin-right: 0.75rem;
  color: #adb5bd;
}

.sidebar-link,
a.sidebar-link {
  display: block;
  padding: 0.75rem 1.25rem;
  color: #212529;
  background: #fff;
  transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
  position: relative;
  text-decoration: none;
}

.sidebar-link:focus {
  outline: 0;
}

.sidebar-item.active .sidebar-link:hover,
.sidebar-link:hover {
  color: #495057;
  background: #f5f5f5;
}

.sidebar-dropdown .sidebar-link {
  padding-left: 2.5rem;
  color: #495057;
  background: #fafafa;
}

.sidebar-dropdown .sidebar-item.active .sidebar-link,
.sidebar-dropdown .sidebar-link:hover {
  color: #212529;
  background: #f5f5f5;
}

.sidebar [data-toggle=collapse] {
  position: relative;
}

.sidebar [data-toggle=collapse]:before {
  content: " ";
  border: solid;
  border-width: 0 0.1rem 0.1rem 0;
  display: inline-block;
  padding: 2px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 1.2rem;
  right: 1.25rem;
  transition: all 0.2s ease-out;
}

.sidebar [aria-expanded=true]:before,
.sidebar [data-toggle=collapse]:not(.collapsed):before {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  top: 1.4rem;
}

.sidebar-brand {
  font-weight: 500;
  font-size: 1.15rem;
  padding: 1.15rem 1.25rem;
  color: #343a40;
  display: block;
}

.sidebar-brand:hover {
  text-decoration: none;
  color: #343a40;
}

.sidebar-brand:focus {
  outline: 0;
}

.sidebar-brand .feather,
.sidebar-brand svg {
  color: #1475bc;
  height: 24px;
  width: 24px;
  margin-left: -0.15rem;
  margin-right: 0.375rem;
}

.sidebar-toggle {
  cursor: pointer;
}

.sidebar-toggle,
.sidebar-toggle .feather {
  width: 26px;
  height: 26px;
}

.sidebar-toggle .feather:hover {
  color: #1475bc;
}

.sidebar.toggled {
  margin-left: -255px;
}

.sidebar.toggled .sidebar-content {
  left: -255px;
}

@media (min-width: 1px) and (max-width: 991.98px) {
  .sidebar {
    margin-left: -255px;
  }

  .sidebar .sidebar-content {
    left: -255px;
  }

  .sidebar.toggled {
    margin-left: 0;
  }

  .sidebar.toggled .sidebar-content {
    left: 0;
  }

  .sidebar-collapsed {
    margin-left: 0;
  }

  .sidebar-collapsed .sidebar-content {
    left: 0;
  }

  .sidebar-collapsed.toggled {
    margin-left: -255px;
  }

  .sidebar-collapsed.toggled .sidebar-content {
    left: -255px;
  }
}
.sidebar-header {
  background: transparent;
  color: #adb5bd;
  padding: 0.375rem 1.25rem;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.sidebar-badge {
  position: absolute;
  right: 15px;
  top: 14px;
}

.sidebar-cta {
  text-align: center;
  margin: 1rem;
  background: #fcfcfc;
  border: 1px solid #e9ecef;
  position: relative;
}

.sidebar-cta-close {
  position: absolute;
  top: 6px;
  right: 8px;
}

.sidebar-cta-content {
  padding: 1.5rem 1rem;
}

a {
  color: #1070b7;
}

.btn-primary {
  background-color: #1070b7;
  border-color: #1070b7;
}

.category-list-h {
  max-height: calc(100vh - 50px);
  overflow-y: auto;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.fixed {
  position: fixed;
}

.text-center {
  text-align: center;
}

.absolute {
  position: absolute;
}

.hidden {
  display: none;
}

.overflow-y-auto {
  overflow-y: auto;
}

.z-10 {
  z-index: 10;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.min-h-screen {
  min-height: 100vh;
}

.opacity-75 {
  opacity: 0.75;
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

@media (min-width: 640px) {
  .sm\:p-0 {
    padding: 0;
  }

  .sm\:align-middle {
    vertical-align: middle;
  }

  .sm\:h-screen {
    height: 100vh;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:max-w-7xl {
    max-width: 80rem;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:flex-column {
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    box-sizing: border-box;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:flex-column-reverse {
    -webkit-flex-direction: column-reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
    box-sizing: border-box;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:width-f {
    width: 100% !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg0lr {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg0lr {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg1lr {
    margin-right: 1px !important;
    margin-left: 1px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg1lr {
    padding-right: 1px !important;
    padding-left: 1px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg2lr {
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg2lr {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg3lr {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg3lr {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg4lr {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg4lr {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg5lr {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg5lr {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg6lr {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg6lr {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg7lr {
    margin-right: 7px !important;
    margin-left: 7px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg7lr {
    padding-right: 7px !important;
    padding-left: 7px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg8lr {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg8lr {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg9lr {
    margin-right: 9px !important;
    margin-left: 9px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg9lr {
    padding-right: 9px !important;
    padding-left: 9px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg10lr {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg10lr {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg15lr {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg15lr {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg20lr {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg20lr {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg25lr {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg25lr {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg30lr {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg30lr {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg35lr {
    margin-right: 35px !important;
    margin-left: 35px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg35lr {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg40lr {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg40lr {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg45lr {
    margin-right: 45px !important;
    margin-left: 45px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg45lr {
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg50lr {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg50lr {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg55lr {
    margin-right: 55px !important;
    margin-left: 55px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg55lr {
    padding-right: 55px !important;
    padding-left: 55px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg60lr {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg60lr {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg65lr {
    margin-right: 65px !important;
    margin-left: 65px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg65lr {
    padding-right: 65px !important;
    padding-left: 65px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg70lr {
    margin-right: 70px !important;
    margin-left: 70px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg70lr {
    padding-right: 70px !important;
    padding-left: 70px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg75lr {
    margin-right: 75px !important;
    margin-left: 75px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg75lr {
    padding-right: 75px !important;
    padding-left: 75px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg80lr {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg80lr {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg85lr {
    margin-right: 85px !important;
    margin-left: 85px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg85lr {
    padding-right: 85px !important;
    padding-left: 85px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg90lr {
    margin-right: 90px !important;
    margin-left: 90px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg90lr {
    padding-right: 90px !important;
    padding-left: 90px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg95lr {
    margin-right: 95px !important;
    margin-left: 95px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg95lr {
    padding-right: 95px !important;
    padding-left: 95px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:mg100lr {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 829px), (min-width: 1px) and (max-width: 829px) {
  .md\:pg100lr {
    padding-right: 100px !important;
    padding-left: 100px !important;
  }
}