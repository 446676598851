@mixin row-column-sm{
    flex-direction: column;

    @media (min-width : $S ){
        flex-direction: row;
    }
}

@mixin form-header($bgColorStart, $bgColorEnd, $textColor){
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em;
    color: $textColor;
    background: linear-gradient(90deg, $bgColorStart, $bgColorEnd);
}

@mixin form-content-block($breakpoint: 768px,$textColor: black,$border-color: #ccc, $focus-color: #0e7ebe){
    display: flex;
    flex-direction: column;

    div{
        width: 100%;
        padding: 0.2em;
        
        div{
            width:100%;
            display: flex;
            flex-direction: column;
            column-gap: 0.5rem;

            label{
                color: $textColor;
            }

            input{
                transition: all 200ms ease;
                border: 1px $border-color solid;
                border-radius: 20px;
                padding: 0.3em 1em;

                &:focus{
                    outline: none;
                    border: 1px $focus-color solid;
                }
            }

            textarea{
                transition: all 200ms ease;
                border: 1px $border-color solid;
                border-radius: 20px;
                padding: 0.3em 1em;

                &:focus{
                    outline: none;
                    border: 1px $focus-color solid;
                }
            }

            select{
                transition: all 200ms ease;
                border: 1px $border-color solid;
                border-radius: 20px;
                padding: 0.3em 1em;

                &:focus{
                    outline: none;
                    border: 1px $focus-color solid;
                }
            }
        }
    }

    @media (min-width: $breakpoint){
        flex-direction: row;
        justify-content: space-between;
    }
}

.width-75-f-sm{
    width: 100%;

    @media (min-width : $S ){
        width: 75%;
    }
}

.width-50-f-sm{
    width: 100%;

    @media (min-width : $S ){
        width: 50%;
    }
}

.width-25-f-sm{
    width: 100%;

    @media (min-width : $S ){
        width: 25%;
    }
}

.primary-form-button{
    border: none;
    padding: 0.5rem 1.25rem;
    background-color: #0e7ebe;
    @extend .br20;

    a{
        color: white;
        font-size: 1.125rem;
    }
}

.form-item-3-sm {
    width: 100%;

    @media (min-width : $S){
        width: 30%;
    }
}

.form-item-2-sm {
    width: 100%;

    @media (min-width : $S){
        width: 45%;
    }
}

.form-item-2s-sm {
    width: 100%;

    @media (min-width : $S){
        width: 30%;
    }
}

.form-item-2b-sm {
    width: 100%;

    @media (min-width : $S){
        width: 65%;
    }
}